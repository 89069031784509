import React, { useState } from "react";
import styled from "styled-components";

// hash function used to hash object and can be used as key
import hash from "object-hash";

// background images
import BgImg1 from "../../images/Bg1.jpg";
import BgImg2 from "../../images/Bg2.jpg";
import BgImg3 from "../../images/Bg3.jpg";
import BgImg4 from "../../images/Bg4.jpg";

import logo from "../../images/logo.png";
// custom hook that modified current width
import useCurrentWidth from "../../../../customHooks/resizeHook";

// importing other components
import MobileHeader from "./mobileHeader";
import BackgroundSection from "./backgroundSection";

const PageHeader = styled.section`
  width: 100vw;
  height: 80vh;
  display: flex;
  background-color: black;
  position: relative;
  cursor: pointer;
  // device - tablets, iPads (portrait)
  @media only screen and (min-width: 769px) and (max-width: 1280px) {
    height: 50vh;
  }
  @media screen and (max-width: 768px) {
    background-color: none;
    height: 60vh;
  }
`;

const HeaderSectionHeading = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
`;

const ShopPageHeader = () => {
  const resolution = useCurrentWidth();
  const [bgArray, setBgArray] = useState([BgImg1, BgImg2, BgImg3, BgImg4]);

  return (
    <PageHeader>
      {resolution > 768 &&
        bgArray
          .slice(0, resolution > 1280 ? 4 : 2)
          .map((element, index) => (
            <BackgroundSection
              key={hash(element)}
              index={index}
              element={element}
              setBgArray={setBgArray}
            />
          ))}
      {resolution < 768 && <MobileHeader bgArray={bgArray} />}
      <HeaderSectionHeading src={logo} alt="KK logo" />
    </PageHeader>
  );
};

export default ShopPageHeader;

import React from "react";

import { Helmet } from "react-helmet";

// json-LD import
import { jsonLD } from "../HomePage/index";
import { metaTagConstants } from "../HomePage/index";

// import social media thumbnail image for sharing
import spreadArms from "../DancePage/images/arms-up.jpg";

const InternshipProgramPage = () => {
  // dance meta tags
  const tags = {
    ...metaTagConstants,
    title: "Internship Program",
    url: "https://www.kellykinetix.com/internship",
    description:
      "Improve your health through dance classes. Work on weight management, balance and flexibility.",
    image: spreadArms,
  };

  // begin internship program page
  return (
    <div className="h-auto flex flex-col">
      {/* begin react-helmet component */}
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Dance classes and training offered by KellyKinetix which focus on belly dance in solo or group settings. KellyKinetix offers belly dancing and personal trainers in north york, Toronto, GTA and Ontario through online classes."
        />
        <meta
          name="keywords"
          content="belly dance toronto, belly dancing classes toronto, mind body connection, mind body, mind and body, body mind, meditation, meditation music, sleep music, mindfulness meditation, guided meditation, mindfulness meditation, mindfulness, dance lessons, dance classes near me, dance lessons near me, dance schools near me, belly dance classes near me, benefits of dancing, benefits of dance, cardiovascular fitness, benefits of physical activity, benefits of physical fitness, 10 benefits of physical activity, workout benefits, cardio vascular fitness, resilience, resiliency, resilience examples, mental resilience, prestige awards, corporate livewire prestige awards, prestige trophies, fitness classes, exercise classes near me, group fitness classes near me, workout classes, exercise classes, group workout classes near me, exercise groups near me, zumba exercise for beginners, exercise classes near me, group fitness classes near me, exercise classes, group workout classes near me, workout classes, zumba dance"
        />

        <title>Internship Program</title>

        {/* adding social media meta tags */}
        <meta property="og:title" content={tags.title} />
        <meta property="og:url" content={tags.url}></meta>
        <meta property="og:description" content={tags.description} />
        <meta property="og:locale" content={tags.locale} />
        <meta property="og:image" content={"%PUBLIC_URL%/" + tags.image} />

        {/* adding seo constants to each page */}
        <script type="application/json+ld">{JSON.stringify(jsonLD)}</script>
      </Helmet>
      {/* end react-helmet component */}

      <div className="h-auto flex flex-col sm:my-12">
        <span className="text-xl sm:text-3xl font-bold text-center header-text my-4">
          INTERNSHIP PROGRAM
        </span>
        <p className="px-8 sm:px-32 text-base sm:text-xl py-8 content-text">
          This program was started in June 2020 and continues to develop.
          KellyKinetix provides people with a passion for both dance and/or
          fitness to be part of a dynamic company. In the midst of the pandemic,
          everything was conducted virtually and this opportunity is pursued
          remotely. There are some positions that you have the option to
          participate in person. There are 2 terms starting each January and
          June.
        </p>
        <p className="px-8 sm:px-32 text-base sm:text-xl py-8 content-text">
          This opportunity is open to current college and university students,
          recent graduates, and professionals looking for a career change.
          International students on a student visa are also welcome to apply.
          For more information, please{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:info@kellykinetix.com"
            className="content-link"
          >
            contact us
          </a>
          .
        </p>
        <p className="px-8 sm:px-32 text-base sm:text-xl py-8 mb-12 content-text">
          Check out our{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/internalMission"
            className="content-link"
          >
            internal mission
          </a>
          .
        </p>
      </div>
    </div>
  );
  // end internship program page
};

export default InternshipProgramPage;

import React from "react";
import styled from "styled-components";

export default function BlogPostPreview(props) {
  const previewContent = () => {
    let text = props.previewContent.slice(0, 200);
    let str = text.replace("<!--StartFragment-->", "");
    return str;
  };
  // need to   schemas for automaed blog posts
  return (
    // individual blog post component
    <PostWrapper className="blogPosts">
      <img src={props.thumbnail} alt="post thumbnail"></img>
      <p className="date">{props.publishDate}</p>

      <a href={"/posts/" + props.id} rel="noreferrer" className="title-link">
        <h1>{props.postTitle}</h1>
      </a>

      <p className="preview">
        {previewContent()}...{" "}
        <a href={"/posts/" + props.id} rel="noreferrer">
          read more.
        </a>
      </p>
    </PostWrapper>
  );
}

const PostWrapper = styled.article`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  > * {
    padding: 0.25em;
  }

  img {
    border-radius: 5px 5px 5px 5px;
    box-shadow: 2px 2px 2px 2px slateblue;
    height: 50vh;
    object-fit: cover;
  }

  .date {
    text-align: right;
    font-size: 1.25rem;
  }

  h1 {
    font-size: 2rem;
    font-weight: 600;
    width: fit-content;
  }

  .preview {
    font-size: 1.35rem;
  }

  a {
    text-decoration: underline;
    color: blue;
  }

  .title-link {
    text-decoration: none;
    color: black;
  }

  h1:hover {
    text-decoration: underline;
    color: orange;
  }

  @media screen and (max-width: 600px) {
    h1 {
      font-size: 1.75rem;
    }
  }
`;

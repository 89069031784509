import React from "react";
import styled from "styled-components";
import ErrorImage from "./images/error.png";
import { useLocation } from "react-router-dom";

// pageNotFound component
import PageNotFound from "../../PageNotFound/pageNotFound";

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 70vh;
  gap: 40px;
  padding: 6rem;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 2rem;
  }
`;

const ErrorImg = styled.img`
  height: 50%;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50vw;
  @media screen and (max-width: 768px) {
    align-items: center;
  }
`;

const SorryTitle = styled.h1`
  font-size: 4rem;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0 !important;
  margin-bottom: 14px !important;
`;

const NotFoundTitle = styled.h2`
  font-size: 3rem;
  text-transform: lowercase;
  margin: 0 !important;
`;

const PayPalError = () => {
  const location = useLocation();

  return location.state !== undefined ? (
    <ErrorContainer>
      <ErrorImg alt="not found" src={ErrorImage} />
      <Text>
        <SorryTitle> sorry</SorryTitle>
        <NotFoundTitle>
          we weren't able to complete your payment. Please try again later.
        </NotFoundTitle>
      </Text>
    </ErrorContainer>
  ) : (
    <PageNotFound />
  );
};

export default PayPalError;

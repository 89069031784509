import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

// hash function used to hash object and can be used as key
import hash from "object-hash";

// background image
import Bg from "../../pages/DancePage/images/bd.jpg";

//other components for mobile navigation bar
import VisibleMenu from "./visibleMenu";
import SocialLinks from "./socialLinks";

// import links from desktop navigation bar
import { links } from "../desktopNav";

// styles for navigation links (when menu is open)
const NavLinksContainer = styled.ul`
  background-image: url(${Bg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: black;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 4vh 0;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  clip-path: circle(${(props) => props.clipPath} at 0% -15%);
  -webkit-clip-path: circle(${(props) => props.clipPath} at 0% -15%);
  transition: all 0.8s linear;
  z-index: 6;
`;

const NavLink = styled.li`
  font-size: 1rem;
  font-weight: 700;
  background: white;
  display: inline;
  padding: 0.5rem 1.6rem;
  letter-spacing: 2px;
  opacity: ${(props) => props.opacityLevel};
  &:nth-child(${(props) => props.index}) {
    transition: all 0.5s ease ${(props) => props.delay};
  }
`;

const LinkTitle = styled.p`
  color: black;
  margin: 0 !important;
  text-align: center;
`;
const SubMenu = ({ open, onClose, children }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <ul
      className={`dropdown-menuc m-0 p-0 text-center ${
        open ? "dropdown-menu-open" : ""
      }`}
      aria-label="submenu"
    >
      {children}
    </ul>
  );
};

const SubNavLink = ({ children, to, onClick }) => {
  return (
    <li className="dropdown-item">
      <Link to={to} onClick={onClick}>
        <span className="uppercase text-sm font-bold text-indigo-900 hover:text-gray-600 transition">
          {children}
        </span>
      </Link>
    </li>
  );
};

const subsubMenu = styled.ul`
  padding: 0;
`;
const subsubmenuItem = styled.li`
  font-weight: 400;
  font-size: 14px;
`;

const MobileNavbar = () => {
  // determines whether menu is open or not
  const [openMenu, setOpenMenu] = useState(false);
  const [aboutSubMenuOpen, setAboutSubMenuOpen] = useState(false);
  const [danceSubMenuOpen, setDanceSubMenuOpen] = useState(false);
  const [fitnessSubMenuOpen, setFitnessSubMenuOpen] = useState(false);
  const [internshipMenuOpen, setInternshipMenuOpen] = useState(false);
  const [serviceMenuOpen, setServiceMenuOpen] = useState(false);
  const [subMenuLinks, setSubMenuLinks] = useState([]);

  const toggleSubMenu = (sublinks, title) => {
    setSubMenuLinks([]);
    if (title === "About Us") {
      setAboutSubMenuOpen(!aboutSubMenuOpen);
      setFitnessSubMenuOpen(false);
    } else if (title === "Programs and Services") {
      setServiceMenuOpen(!serviceMenuOpen);
      setAboutSubMenuOpen(false);
    }
  };

  // used to open menu with all navigation links
  const openMenuHandler = () => {
    setOpenMenu(!openMenu);
    scrollerHandler();
  };
  const handleLinkClick = (link, event) => {
    if (link.sublinks) {
      event.preventDefault();
      toggleSubMenu(link.sublinks, link.title);
    } else {
      setOpenMenu(false);
      scrollerHandler();
    }
  };
  // used to enable/disable scrolling
  const scrollerHandler = () => {
    document.body.setAttribute(
      "style",
      `overflow-y: ${openMenu ? "visible" : "hidden"}; height: ${
        openMenu ? "auto" : "100%"
      };`
    );
  };

  return (
    <>
      <VisibleMenu openMenuProp={openMenu} openMenuHandler={openMenuHandler} />
      <NavLinksContainer clipPath={openMenu ? "900px" : "100px"}>
        {links.map((link, index) => (
          <NavLink
            key={hash(link)}
            index={index + 1}
            delay={`0.${index + 1}s`}
            opacityLevel={openMenu ? 1 : 0}
          >
            <Link
              to={link.path}
              onClick={(event) => handleLinkClick(link, event)}
            >
              <LinkTitle>
                {link.title}
                {link.sublinks && <i className="arrow-down"></i>}
              </LinkTitle>
            </Link>

            {link.sublinks && (
              <SubMenu
                open={
                  link.title === "About Us" ? aboutSubMenuOpen : serviceMenuOpen
                }
                onClose={toggleSubMenu}
              >
                {link.sublinks.map((sublink) => (
                  <li key={hash(sublink)}>
                    {sublink.sublinks ? (
                      <>
                        <div
                          className="uppercase text-sm font-bold text-indigo-900 hover:text-gray-500 transition w-full dropdown-item"
                          onClick={() => {
                            sublink.title === "Dance"
                              ? setDanceSubMenuOpen(!danceSubMenuOpen)
                              : sublink.title === "Fitness"
                              ? setFitnessSubMenuOpen(!fitnessSubMenuOpen)
                              : setInternshipMenuOpen(!internshipMenuOpen);
                          }}
                        >
                          {sublink.title}
                          <i className="arrow-down"></i>
                        </div>
                        {danceSubMenuOpen && sublink.title === "Dance" && (
                          <subsubMenu>
                            {sublink.sublinks.map((subsublink) => {
                              return (
                                <li>
                                  <Link to={subsublink.path}>
                                    <div className="uppercase text-sm font-bold text-gray-600 hover:text-gray-400 transition w-full dropdown-item">
                                      {subsublink.title}
                                    </div>
                                  </Link>
                                </li>
                              );
                            })}
                          </subsubMenu>
                        )}
                        {fitnessSubMenuOpen && sublink.title === "Fitness" && (
                          <subsubMenu>
                            {sublink.sublinks.map((subsublink) => {
                              return (
                                <li>
                                  <Link to={subsublink.path}>
                                    <div className="uppercase text-sm font-bold text-gray-600 hover:text-gray-400 transition w-full dropdown-item">
                                      {subsublink.title}
                                    </div>
                                  </Link>
                                </li>
                              );
                            })}
                          </subsubMenu>
                        )}
                        {internshipMenuOpen &&
                          sublink.title === "Internship Program" && (
                            <subsubMenu>
                              {sublink.sublinks.map((subsublink) => {
                                return (
                                  <li>
                                    <Link to={subsublink.path}>
                                      <div className="uppercase text-sm font-bold text-gray-600 hover:text-gray-400 transition w-full dropdown-item">
                                        {subsublink.title}
                                      </div>
                                    </Link>
                                  </li>
                                );
                              })}
                            </subsubMenu>
                          )}
                      </>
                    ) : (
                      <Link to={sublink.path} onClick={openMenuHandler}>
                        <div className="uppercase text-sm font-bold text-indigo-900 hover:text-gray-500 transition w-full dropdown-item">
                          {sublink.title}
                        </div>
                      </Link>
                    )}
                  </li>
                ))}
              </SubMenu>
            )}
          </NavLink>
        ))}

        <SocialLinks />
      </NavLinksContainer>
    </>
  );
};

export default MobileNavbar;

import React from "react";

// react-helmet
import { Helmet } from "react-helmet";

// Testimonial component
import { TestimonialSectionComponent } from "../../Testimonial";

// testimonial data
import { testimonials } from "../../../data";

// images
import greenery from "./images/greenery.jpg";
import deadlift from "./images/deadlift.jpg";
import machine from "./images/machine.jpg";
import grinning from "./images/grinning.jpg";
import client from "./images/client.jpg";

const WorkingOutAndWorkingInPage = () => {
  const { fitnessTestimonialData } = testimonials;
  // begin fitness page
  return (
    <div className="h-auto flex flex-col">
      {/* begin react-helmet component */}
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="About the fitness services offered by KellyKinetix which include mobility and strength training. KellyKinetix offers belly dancing and personal trainers in north york, Toronto, GTA and Ontario through online classes."
        />

        {/* meta tags for social media sharing */}
        <meta property="og:title" content="KellyKinetix Fitness" />
        <meta
          property="og:url"
          content="https://www.kellykinetix.com/fitness"
        />
        <meta
          name="keywords"
          content="gym instruction, training, working out, fitness classes, exercise classes near me, group fitness classes near me, workout classes, exercise classes, group workout classes near me, exercise groups near me, zumba exercise for beginners, exercise classes near me, group fitness classes near me, exercise classes, group workout classes near me, workout classes, zumba dance, circuit training, fitness classes near me, workout classes near me, zumba, cpr, personal trainer, personal trainer downtown toronto, fit women, goodlife workout, housefit personal training toronto, personal trainer, trainer, physical trainer, personal trainer at home, female personal trainer, personal training studio, fitness classes, personal trainers near me, fitness studio, fitness studio toronto, personal trainer, personal training studio, private trainer, personal fitness, studio north, health & fitness, personal training, fitness gym, health fitness, fitness centre, toronto fitness, gym in toronto, downtown toronto gym, in love yourself, love your self, love yourself, my self love, linktree instagram, facebook instagram, instagram and facebook, tiktok instagram, face book face book, mind body connection, mind body, mind and body, body mind, cardiovascular fitness, benefits of physical activity, benefits of physical fitness, 10 benefits of physical activity, workout benefits, cardio vascular fitness, paul chek, smart goals examples, smart goals acronym, client testimonials, mobility, mobility plus, mobility, resilience, resiliency, resilience examples, mental resilience, prestige awards, corporate livewire prestige awards, prestige trophies"
        />

        <meta
          property="og:description"
          content="Reserve a consultation to book your personal training session. Also offer group fitness classes and customized programming to get everyone to reach their goals."
        />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:image" content={"%PUBLIC_URL%/" + deadlift} />
        <meta property="og:type" content="website" />

        <title>WORKING OUT AND WORKING IN</title>
      </Helmet>
      {/* end react-helmet component */}

      {/* begin working out vs working in section */}
      <div className="h-auto flex flex-col my-4 sm:my-12">
        <span className="text-xl sm:text-3xl font-bold text-center header-text px-8">
          WORKING OUT AND WORKING IN
        </span>
        <p className="px-8 sm:px-32 text-base sm:text-xl mt-12 mb-4 content-text">
          Working in is a concept developed by{" "}
          <span className="text-base sm:text-xl">
            <a
              href="https://www.chekinstitute.com/paul-chek/"
              target="_blank"
              rel="noopener noreferrer"
              className="content-link"
            >
              Paul Chek
            </a>
          </span>
          , an internationally-renowned expert in the fields of high-performance
          exercise kinesiology and stress management. The idea behind working
          in, in contrast to working out, is that energy is being returned to
          the body instead of being expended. When many people begin an exercise
          program, they are already operating from a place of energy deficit.
          This means that people are already tired or not appropriately
          nourished and so a program that is only focused on working out will
          drain the body of its energy causing burnout and unnecessary pain.
        </p>
        <p className="px-8 sm:px-32 text-base sm:text-xl my-4 content-text">
          A fitness program which incorporates working in emphasizes exercising
          in a way that is therapeutic to reinvigorate the trainee, and
          exercises that are typically done in workouts can be done in different
          ways to work in, thus improving the outcome of them.{" "}
          <span className="text-base sm:text-xl">
            <a
              href="https://chekinstitute.com/blog/working-in-101-why-how-to-work-in/"
              target="_blank"
              rel="noopener noreferrer"
              className="content-link"
            >
              This
            </a>
          </span>{" "}
          blog entry from the CHEK institute provides more information of the
          concept of working in.
        </p>
      </div>
      {/* end working out vs working in section */}
      <p className="px-8 sm:px-32 text-base sm:text-xl pb-8 mb-12 content-text">
        Learn about{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="/personalTraining"
          className="content-link"
        >
          IN PERSON/ONLINE 1-ON-1 PERSONAL TRAINING
        </a>
        .
      </p>
    </div>
  );
  // end fitness page
};

export default WorkingOutAndWorkingInPage;

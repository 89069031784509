import React from "react";
import { Router, Route, Switch } from "react-router-dom";

import Header from "./components/Header/navigationBar";
import Footer from "./components/Footer/index";
import HomePage from "./components/pages/HomePage";
import AboutUsPage from "./components/pages/AboutUsPage/AboutUsPage";
import CompanyVisionPage from "./components/pages/AboutUsPage/CompanyVisionPage";
import CompanyMissionPage from "./components/pages/AboutUsPage/CompanyMissionPage";
import CompanyValuesPage from "./components/pages/AboutUsPage/CompanyValuesPage";
import ConsultationPage from "./components/pages/ConsultationPage";
import DancePage from "./components/pages/DancePage/DancePage";
import ProgramsAndServicesPage from "./components/pages/ProgramsAndServicesPage/ProgramsAndServices";
import BellyDancePage from "./components/pages/DancePage/BellyDancePage";
// import BollywoodDancePage from "./components/pages/DancePage/BollywoodDancePage";
import DanceExplorationPage from "./components/pages/DancePage/DanceExplorationPage";
import FitnessPage from "./components/pages/FitnessPage";
import WorkshopPage from "./components/pages/WorkshopPage";
import ClassPage from "./components/pages/ClassPage";
import FAQs from "./components/pages/FAQs/FAQs";
import AwardPage from "./components/pages/AwardPage/AwardPage";
import InternshipProgramPage from "./components/pages/InternshipProgramPage/InternshipProgramPage";
import InternalMissionPage from "./components/pages/InternshipProgramPage/InternshipInternalMissionPage";
import HealthBenefitsPage from "./components/pages/FitnessPage/HealthBenefitsPage";
import WorkingOutAndWorkingInPage from "./components/pages/FitnessPage/WorkingOutAndWorkingInPage";
import PersonalTrainingPage from "./components/pages/FitnessPage/PersonalTrainingPage";
import GroupFitnessPage from "./components/pages/FitnessPage/GroupFitnessPage";
import CustomizedProgramPage from "./components/pages/FitnessPage/CustomizedProgramPage";
import PageNotFound from "./components/PageNotFound/pageNotFound";
// blog related components
import BlogLanding from "./components/pages/Blog/BlogLanding";
import FullArticle from "./components/pages/Blog/FullArticle";

// new year landing page related components
import NewYearLandingPage from "./components/pages/NewYearLanding/NewYearLandingPage";
import NewYearThankYouPage from "../src/components/pages/NewYearLanding/NewYearThankYouPage";

// e-commerce page related components
import ShopPage from "./components/pages/E-Commerce/shop-page/ShopPage";
import ProductDetails from "./components/pages/E-Commerce/product-detailed-page/ProductDetails";
import PaypalThankYou from "./components/shopping-cart/ThankYouPage/paypalThankYou";
import OrderCancel from "./components/shopping-cart/OrderCancel/orderCancel";
import PayPalError from "./components/shopping-cart/Error/PayPalError";

import { createBrowserHistory } from "history";

function App() {
  const history = createBrowserHistory({ forceRefresh: true });

  const routes = [
    { path: "/", page: <HomePage /> },
    { path: "/aboutUs", page: <AboutUsPage /> },
    { path: "/vision", page: <CompanyVisionPage /> },
    { path: "/mission", page: <CompanyMissionPage /> },
    { path: "/values", page: <CompanyValuesPage /> },
    { path: "/programsAndServices", page: <ProgramsAndServicesPage /> },
    { path: "/fitness", page: <FitnessPage /> },
    { path: "/healthBenefits", page: <HealthBenefitsPage /> },
    { path: "/personalTraining", page: <PersonalTrainingPage /> },
    { path: "/workingOutAndWorkingIn", page: <WorkingOutAndWorkingInPage /> },
    { path: "/groupFitness", page: <GroupFitnessPage /> },
    { path: "/customizedProgram", page: <CustomizedProgramPage /> },
    { path: "/dance", page: <DancePage /> },
    { path: "/bellyDance", page: <BellyDancePage /> },
    // { path: "/bollywoodDance", page: <BollywoodDancePage /> },
    { path: "/danceExploration", page: <DanceExplorationPage /> },
    { path: "/consultation", page: <ConsultationPage /> },
    { path: "/internship", page: <InternshipProgramPage /> },
    { path: "/internalMission", page: <InternalMissionPage /> },
    { path: "/workshops", page: <WorkshopPage /> },
    { path: "/classes", page: <ClassPage /> },
    { path: "/FAQs", page: <FAQs /> },
    { path: "/awards", page: <AwardPage /> },
    { path: "/KellyKinetixBlog", page: <BlogLanding /> },
    { path: "/shop", page: <ShopPage /> },
    { path: "/shop/thankyou", page: <PaypalThankYou /> },
    { path: "/shop/orderCancel", page: <OrderCancel /> },
    { path: "/shop/error", page: <PayPalError /> },
    { path: "/shop/:productId", page: <ProductDetails /> },
    {
      path: "/WomensDayContest/ThankYou",
      page: <NewYearThankYouPage />,
    },
    { path: "/WomensDayContest", page: <NewYearLandingPage /> },
  ];

  return (
    <Router history={history}>
      <Header />
      <Switch>
        {routes.map((route) => (
          <Route
            exact
            path={route.path}
            key={`${route.path}4be05472-a8c9-4218-8284-a0814047d163`}
          >
            {route.page}
          </Route>
        ))}
        <Route
          exact
          path={`/posts/:id`}
          render={(props) => <FullArticle {...props}></FullArticle>}
        ></Route>
        <Route component={PageNotFound} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;

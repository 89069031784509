import React from "react";
import styled from "styled-components";

// hash function used to hash object and can be used as key
import hash from "object-hash";

// used if product does not have any images
import NoImage from "../images/noImage.jpg";

// arrow icons
import UpArrow from "../images/arrow_up_icon.png";
import DownArrow from "../images/arrow_down_icon.png";
import LeftArrow from "../images/arrow_left_previous_icon.png";
import RightArrow from "../images/arrow_right_next_icon.png";

const Slider = styled.div`
  width: 80%;
  flex: 2;
  display: flex;
  flex-direction: row;
  column-gap: 0;
  align-items: center;

  @media screen and (min-width: 768px) {
    width: 100%;
    flex-direction: column;
    row-gap: 10px;
  }
`;

const VerticalArrowIcon = styled.img`
  display: none;
  cursor: pointer;
  width: 1.5rem;
  transition: 0.3;

  &:hover {
    opacity: 0.5;
  }
  @media screen and (min-width: 768px) {
    display: block;
  }
`;

const HorizontalMobileArrowIcon = styled.img`
  cursor: pointer;
  width: 1.5rem;
  transition: 0.3;

  &:hover {
    opacity: 0.5;
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

const SmallImgWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 0;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
  height: 400px;

  @media screen and (min-width: 768px) {
    flex-direction: column;
    row-gap: 10px;
    height: 550px;
  }
`;

const SmallProdImg = styled.img`
  flex-shrink: 0;
  width: 100%;
  min-height: 120px;
  object-fit: cover;
  object-position: 50% 50%;
  overflow: hidden;
  opacity: 1;
  border-radius: 0.25rem;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }

  @media screen and (min-width: 768px) {
    flex-shrink: 1;
    width: 160px;
    opacity: 0.5;
  }
`;

const ImageSlider = ({ product }) => {
  const scrollUp = () => {
    document.getElementById("img-slider").scrollTop -= 260;
  };

  const scrollDown = () => {
    document.getElementById("img-slider").scrollTop += 260;
  };

  const scrollLeft = () => {
    const imgWidth = document
      .getElementsByClassName("slider-img")[0]
      .getBoundingClientRect().width;
    document.getElementById("img-slider").scrollLeft -= imgWidth;
  };

  const scrollRight = () => {
    const imgWidth = document
      .getElementsByClassName("slider-img")[0]
      .getBoundingClientRect().width;
    document.getElementById("img-slider").scrollLeft += imgWidth;
  };

  const changeFeaturedImg = (event) => {
    let activeImgs = document.getElementsByClassName("active");
    if (activeImgs.length > 0) activeImgs[0].classList.remove("active");
    event.target.classList.add("active");
    document
      .getElementById("featured")
      .setAttribute("src", `${event.target.getAttribute("src")}`);
  };

  return (
    <Slider>
      <VerticalArrowIcon
        src={UpArrow}
        onClick={scrollUp}
        className={
          product.images !== undefined && product.images.length > 4
            ? ""
            : "v-hidden"
        }
      />
      <HorizontalMobileArrowIcon
        src={LeftArrow}
        onClick={scrollLeft}
        className={
          product.images !== undefined && product.images.length > 1
            ? ""
            : "v-hidden"
        }
      />
      <SmallImgWrapper id="img-slider">
        {product.images === undefined || product.images.length < 1 ? (
          <SmallProdImg src={NoImage} />
        ) : (
          product.images.map((img, index) => {
            if (index === 0) {
              return (
                <SmallProdImg
                  src={img}
                  key={hash(img)}
                  onClick={changeFeaturedImg}
                  className="active slider-img"
                />
              );
            }
            return (
              <SmallProdImg
                src={img}
                key={hash(img)}
                onClick={changeFeaturedImg}
                className="slider-img"
              />
            );
          })
        )}
      </SmallImgWrapper>
      <HorizontalMobileArrowIcon
        src={RightArrow}
        onClick={scrollRight}
        className={
          product.images !== undefined && product.images.length > 1
            ? ""
            : "v-hidden"
        }
      />
      <VerticalArrowIcon
        src={DownArrow}
        onClick={scrollDown}
        className={
          product.images !== undefined && product.images.length > 4
            ? ""
            : "v-hidden"
        }
      />
    </Slider>
  );
};

export default ImageSlider;

// bring in imports
import React from "react";
import Markdown from "markdown-to-jsx";
import styled from "styled-components";

import SocialMediaContact from "./SocialMediaContact";
import Helmet from "react-helmet";

// import array of converted markdown blogposts
import { blogPosts } from "./posts";

export default function FullArticle(props) {
  // beginning of blog schema
  const validId = parseInt(props.match.params.id);
  const fetchedPost = {};

  blogPosts.forEach((post, i) => {
    if (validId === post.id) {
      fetchedPost.postTitle = post.title ? post.title : "No title given";
      fetchedPost.date = post.date ? post.date : "No date given";
      fetchedPost.author = post.author ? post.author : "No author given";
      fetchedPost.content = post.content ? post.content : "No content given";
      fetchedPost.image = post.image ? post.image : "/logo192.png";
    }
  });

  var blogJSONLD = {
    "@context": "https://schema.org/",
    "@type": "BlogPosting",
    articleBody: `${fetchedPost.content}`,
    sharedContent: `${fetchedPost.image}`,
    wordCount: 1000,
    author: {
      "@type": "Person",
      name: `${fetchedPost.author}`,
    },
  };

  return (
    <div className="post">
      {/* bring in react helmet for meta tags and jsonLD for SEO */}
      <Helmet>
        <meta charSet="utf-8" />
        <meta property="og:title" content={fetchedPost.title}></meta>

        <script type="application/json+ld">{JSON.stringify(blogJSONLD)}</script>
      </Helmet>

      {/* component that fully displays article design/layout */}
      <ArticleWrapper>
        <Banner src={fetchedPost.image}></Banner>
        <SocialMediaContact></SocialMediaContact>
        <h1>{fetchedPost.postTitle}</h1>
        <small>Published on {fetchedPost.date}</small>
        <hr />

        {/* component from external library (markdown-to-jsx). 
                Necessary for converting blogs published from netlifyCMS, to maintain their layout */}
        <Markdown id="content">{fetchedPost.content}</Markdown>
      </ArticleWrapper>
    </div>
  );
}

const ArticleWrapper = styled.article`
  width: 100vw;
  padding-left: 8rem;
  padding-right: 8rem;
  padding-top: 3rem;

  #mediaIcons {
    padding-top: 3rem;
    justify-content: left;
    padding-bottom: 3rem;
  }

  h1 {
    font-size: 3em;
    font-weight: 600;
  }

  hr {
    padding-top: 1rem;
  }
  p {
    white-space: pre-wrap;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
  small,
  #content {
    font-size: 1.1em;
  }

  img {
    width: 100%;
    height: 80vh;
  }

  @media screen and (max-width: 1024px) {
    padding-left: 3rem;
    padding-right: 3rem;

    img {
      height: 60vh;
    }
  }

  @media screen and (max-width: 440px) {
    padding: 1.5rem;

    img {
      height: 50vh;
    }
  }
`;

//Added an image src prop to the banner, it takes the featured image if available and falls back to the default logo if it fails.
const Banner = styled.div`
  width: 100%;
  background: url(${(props) => props.src}) no-repeat;
  background-size: cover;
  height: 70vh;

  @media screen and (max-width: 768px) {
    height: 50vh;
  }

  @media screen and (max-width: 440px) {
    height: 35vh;
  }
`;

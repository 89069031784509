// imported dependencies/components
import React from "react";
import group from "./images/group-dance.jpg";
import Helmet from "react-helmet";
import { TestimonialSectionComponent } from "../../Testimonial";
import bellyDance from "./images/bd.jpg";

// import testimonials
import { testimonials } from "../../../data";
import styled from "styled-components";
import armsUp from "./images/arms-up.jpg";
const { danceTestimonialData } = testimonials;

const BookNowBtn = styled.a`
  position: relative;
  outline: none;
  background: none;
  padding: 0.5rem 2rem;
  width: 80%;
  display: block;
  margin: 1em 6em;
  padding-left: 2em;
  border: none;
  border-radius: 12px;
  color: #324da1;
  font-size: 1.2rem;
  font-weight: 600;
  z-index: 1;
  text-align: center;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    font-size: 1.3rem;
    padding: 0.5rem 1rem;
  }
  &:hover {
    color: #f3f4f6;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #324da1;
    opacity: 0.9;
    border-radius: 12px;
    transition: transform 0.5s;
    transform-origin: bottom right;
    transform: scale(0);
  }

  &:hover::before {
    transition: transform 0.5s;
    transform-origin: top left;
    transform: scale(1);
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: transparent;
    border-radius: 12px;
    border: 2px solid #035b96;
    transition: transform 0.5s;
    transform-origin: top left;
    transform: scale(1);
  }

  &:hover::after {
    transition: transform 0.5s;
    transform-origin: bottom right;
    transform: scale(0);
  }
`;

export default function BellyDancePage() {
  return (
    <div>
      {/* begin group belly dancing class section */}
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Belly dance classes and training offered by KellyKinetix which focus on belly dance in solo or group settings. Offered online or in-person. KellyKinetix offers belly dancing and personal trainers in north york, Toronto, GTA and Ontario through online classes."
        />
        <title>Dance Exploration</title>
      </Helmet>
      {/* begin dance exploration section */}
      <div className="h-auto flex flex-col mt-0 sm:my-12 mx-3">
        <span className="text-xl sm:text-3xl font-bold text-center header-text my-12">
          INTRODUCTION TO DANCE EXPLORATION (GROUP CLASSES)
        </span>

        <div className="flex flex-col sm:flex-row sm:justify-between  sm:items-center md:items-start  mt-0 mb-12 s: gap-4 w-4/5 m-auto">
          {/* begin image section */}
          <div className="sm:w-2/5 h-64  mt-0 sm:h-auto flex items-center mb-16 sm:mb-0 pl-4 sm:w-80 xl:w-100 m-auto overflow-hidden">
            <img
              src={armsUp}
              alt="Arms up holding a dance pose after class"
              className="rounded"
            />
          </div>
          {/* end image section */}
          {/* begin side content section */}
          <div className="sm:w-3/5 h-auto flex flex-col mb-5">
            <p className="sm:pl-12 xl:mt-0 px-4 sm:pr-32 text-base sm:text-xl  content-text">
              Dancing can bring happiness and comfort into our lives. It's a
              great source of energy, relaxation, and a spirit and soul healer.
              You can break toxic cycles by creating a new habit in your life
              and bring positivity into your world by developing a new social
              network. The main idea in this type of class is: Let go of the
              fear of dancing, Learn to let the music dance you rather than
              worrying about how to dance it. Let your authentic self shine! The
              way this class is run is in a different format from other class
              offerings. <br />
              <br />
              For more information and to discuss potential class times please
              contact us at{" "}
              <a href="mailto:info@kellykinetix.com" className="content-link">
                info@kellykinetix.com
              </a>
            </p>
          </div>
          {/* end side content section */}
        </div>
        {/* end dance exploration section */}
      </div>
    </div>
  );
}

import React from "react";
import styled from "styled-components";

// hash function used to hash object and can be used as key
import hash from "object-hash";

// measurement chart available for download
import chart from "./files/measurement-chart.pdf";
import parq from "./files/par-q.pdf";
import waiver from "./files/waiver.pdf";

// utility for converting HTML strings into React components.
import ReactHtmlParser from "react-html-parser";

// components for "Steps of consultation" section
const SectionTitle = styled.h2`
  width: max-content;
  font-size: 2.5rem;
  color: #324da1;
  line-height: 70px;
  border-bottom: 3px solid #324da1;
  margin-bottom: 5vh;
  @media screen and (max-width: 768px) {
    font-size: 1.6rem;
  }
`;

const Steps = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5vh;
  padding: 10vh 10vw;
  background: #f3f4f6;
  margin-top: 5vh;
  @media only screen and (min-width: 769px) and (max-width: 1280px) {
    padding: 5vh 4vw;
  }
`;

const StepContainer = styled.div`
  width: 100%;
  min-height: 15vh;
  padding: 1.4rem;
  background: white;
  position: relative;
  border-radius: 16px;
  box-shadow: 4px 4px 8px -4px gray;
`;

const StepNumber = styled.div`
  width: 100px;
  height: 100px;
  background: #324da1;
  border-radius: 50% 50%;
  position: absolute;
  left: ${(props) => props.leftProp};
  top: -20px;
  right: ${(props) => props.rightProp};
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: 769px) and (max-width: 1280px) {
    width: 80px;
    height: 80px;
  }
  @media screen and (max-width: 768px) {
    width: 60px;
    height: 60px;
  }
`;

const StepCount = styled.p`
  font-size: 3.2rem;
  font-weight: 700;
  color: #f6f6f6;
  margin: 0;
  @media only screen and (min-width: 769px) and (max-width: 1280px) {
    font-size: 2.4rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
`;

const StepDetails = styled.p`
  color: #737373;
  font-size: 1.4rem;
  font-weight: 500;
  padding: 0 8%;
  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const BookNowBtn = styled.a`
  position: relative;
  outline: none;
  background: none;
  padding: 0.5rem 2rem;
  margin-top: 4vh;
  border: none;
  border-radius: 12px;
  color: #324da1;
  font-size: 2rem;
  font-weight: 600;
  z-index: 1;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: #f3f4f6;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #324da1;
    opacity: 0.9;
    border-radius: 12px;
    transition: transform 0.5s;
    transform-origin: bottom right;
    transform: scale(0);
  }

  &:hover::before {
    transition: transform 0.5s;
    transform-origin: top left;
    transform: scale(1);
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: transparent;
    border-radius: 12px;
    border: 2px solid #035b96;
    transition: transform 0.5s;
    transform-origin: top left;
    transform: scale(1);
  }

  &:hover::after {
    transition: transform 0.5s;
    transform-origin: bottom right;
    transform: scale(0);
  }
  @media only screen and (min-width: 769px) and (max-width: 1280px) {
    margin-top: 0;
  }
`;

export const ConsultationSteps = () => {
  const steps = [
    {
      stepNumber: "01",
      stepDetails: `You and one of our trainers will meet either over a zoom call or telephone call. That is determined once you check out times for our Calendly bookings.`,
    },
    {
      stepNumber: "02",
      stepDetails:
        "We will take a minute to say hello and introduce ourselves.",
    },
    {
      stepNumber: "03",
      stepDetails:
        "We will ask you 3 questions to get a sense of where you are at and what your needs are.",
    },
    {
      stepNumber: "04",
      stepDetails:
        "We will walk you through the different types of programming we offer with various price ranges to assess what is best for you at this time.",
    },
    {
      stepNumber: "05",
      stepDetails:
        "If a good fit has been agreed, a consultation will be booked during the discovery call.",
    },
  ];
  return (
    <Steps>
      <SectionTitle> The steps in a discovery call </SectionTitle>
      {steps.map((step, index) => (
        <StepContainer key={hash(step)}>
          <StepNumber
            leftProp={index % 2 === 0 ? "-20px" : "auto"}
            rightProp={index % 2 === 0 ? "auto" : "-20px"}
          >
            <StepCount> {step.stepNumber} </StepCount>
          </StepNumber>
          <StepDetails> {ReactHtmlParser(step.stepDetails)} </StepDetails>
        </StepContainer>
      ))}
      <StepContainer>
        <StepDetails>
          Please note that during these times, measurements should be done at
          home, ideally with assistance. This{" "}
          <a href={chart} rel="noreferrer" className="content-link" download>
            measurement chart{" "}
          </a>
          will give you a good idea about how to go about measuring yourself, as
          it provides instructions at the bottom.
        </StepDetails>
      </StepContainer>
      <BookNowBtn
        href="https://calendly.com/info-kellykinetix/30mindiscoverycall"
        target="blank"
      >
        BOOK DISCOVERY CALL NOW
      </BookNowBtn>
    </Steps>
  );
};

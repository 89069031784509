import React, { useEffect, useState } from "react";

// import styled components
import styled from "styled-components";

// images
import laneway from "./images/laneway.jpg";

// react-helmet for SEO
import { Helmet } from "react-helmet";

// import workshop functional components
// import OfferedWorkshop from "../WorkshopPage/OfferedWorkshop";

// import logo for sharing social media thumbnail
import logo from "../HomePage/full-logo.png";
import EventItem from "./EventItem";

const WorkshopPage = () => {
  const [workshopsData, setWorkshopsData] = useState([]);
  const [upcomingWorkshops, setUpcomingWorkshops] = useState([]);
  const [pastWorkshops, setPastWorkshops] = useState([]);
  // const workshops = [{
  //     "workshopTitle":"Low Impact Aerobics Class online with Michela",
  //     "workshopInstructor":"Michela",
  //     "cardImg":"https://wibashdev.blob.core.windows.net/tourmedia/tour-146-bcb505a0-6493-429d-9adc-40431506f863.png",
  //     "signUpLink":"https://wibash.com/viewExperience?id=76&name=Low-Impact-Cardio-Class",
  //     "currency":"USD",
  //     "ticket_price_min":"9.43",
  //     "ticket_price_max":"9.43",
  //     "time":"45 mins"
  // },
  //     {
  //         "workshopTitle":"HIIT Class online with Emily",
  //         "workshopInstructor":"Emily",
  //         "cardImg":"https://wibashdev.blob.core.windows.net/tourmedia/tour-146-1e564000-5df4-4c47-8632-4303cf4f2aa4.png",
  //         "signUpLink":"https://wibash.com/viewExperience?id=77&name=HIIT-Class-with-Emily",
  //         "currency":"USD",
  //         "ticket_price_min":"9.43",
  //         "ticket_price_max":"9.43",
  //         "time":"45 mins"
  //     }]
  useEffect(() => {
    const fetchData = async () => {
      await fetch(
        `https://www.eventbriteapi.com/v3/organizations/${process.env.REACT_APP_EVENTBRITE_ORGANIZATION_ID}/events/?expand=ticket_availability&page=2`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_EVENTBRITE_TOKEN}`,
          },
        }
      )
        .then((data) => data.json())
        .then((jsonData) => {
          console.log(
            jsonData.events.filter(
              (workshop) =>
                workshop.status === "live" || workshop.status === "started"
            )
          );
          setWorkshopsData(jsonData.events);
          setUpcomingWorkshops(
            jsonData.events.filter(
              (workshop) =>
                workshop.status === "live" || workshop.status === "started"
            )
          );
          setPastWorkshops(
            jsonData.events
              .filter(
                (workshop) =>
                  workshop.status === "ended" || workshop.status === "completed"
              )
              .reverse()
          );
        })
        .then(() => {})
        .catch((e) => {
          console.error(`error: ${e}`);
        });
    };
    fetchData().catch((e) => {
      console.error(e);
    });
  }, []);

  return (
    <div className="h-auto flex flex-col">
      {/* begin react-helmet component */}
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Workshops offered through KellyKinetix which include mobility, fitness and dance training. KellyKinetix offers belly dancing and personal trainers in north york, Toronto, GTA and Ontario through online classes."
        />
        <meta
          name="keywords"
          content="workshops, events, events near me, start now, christmas parties toronto, mobility, mobility plus, mobility, myofascial release, myofascial, myofascial release near me, myofascial massage, myofascial release therapy, myofascial release massage, dance fitness, belly dance, belly dancing near me, belly dance class, health and fitness"
        />

        <title>Workshops & Events</title>

        {/* social media meta tags */}
        <meta property="og:title" content="KellyKinetix Workshops" />
        <meta
          property="og:url"
          content="https://www.kellykinetix.com/workshops"
        />
        <meta
          property="og:description"
          content="Interested in hip-hop or nutrition? Get involved in KellyKinetix's specialized workshops, including fitness, dance, nutrition, and holiday bootcamps."
        />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:image" content={"%PUBLIC_URL%/" + logo} />
      </Helmet>
      {/* end react-helmet component */}

      <span className="text-xl sm:text-3xl font-bold text-center header-text my-12">
        WORKSHOPS and Events
      </span>
      <p className="px-8 sm:px-32 text-base sm:text-xl my-4 content-text">
        At KellyKinetix we offer specialized seasonal workshops and special
        events. Our clients learn new skills and knowledge while having fun. It
        is a great avenue for new people wanting to check us out and see what we
        have to offer, and for our community to cultivate lasting meaningful
        relationships. Workshops focus on a specific topic of fitness or dance.
        Everyone is encouraged to work at their own level and enjoy themselves!{" "}
      </p>
      <p className="px-8 sm:px-32 text-base sm:text-xl mt-8 mb-4 content-text">
        Workshops are virtual and special events happen at various parks in the
        GTA and virtually.
      </p>
      {/* begin workshop description section */}
      <span className="text-xl sm:text-2xl font-bold text-center header-text my-12">
        ABOUT OUR WORKSHOPS
      </span>
      <div className="flex justify-between items-center mb-12">
        {/* begin side content section */}
        <div className="w-full sm:w-1/2 h-auto flex flex-col">
          <p className="sm:pr-12 px-8 sm:pl-32 text-base sm:text-xl my-4 content-text">
            Workshops specialize in specific areas of either fitness or dance.
            Participants gain more knowledge and/or experience in a given area
            of each type of physical activity. Whatever stage in your fitness
            journey that you're at, you've come to the right place.
          </p>
          <p className="px-8 sm:pr-12 sm:pl-32 text-base sm:text-xl my-4 content-text">
            We offer fitness, belly dance, hip hop, fight strong fitness
            workshops live through zoom and outdoors in parks. We think it’s
            important for people to have a chance to see what it's like to be in
            a KellyKinetix class to determine if what we do is their thing or
            not. We also believe that everyone should maintain a certain level
            of physical activity on a regular basis. All our workshops are for
            people at any fitness level.{" "}
          </p>
          <p className="px-8 sm:pr-12 sm:pl-32 text-base sm:text-xl my-4 content-text">
            Though our main audience tends to be women between the ages of
            30-65+, everyone is welcome!
          </p>
          <p className="px-8 sm:pr-12 sm:pl-32 text-base sm:text-xl my-4 content-text">
            Join us and invite your friends and family. The more the merrier!
          </p>
          <p className="px-8 sm:pr-12 sm:pl-32 text-base sm:text-xl my-4 content-text">
            Examples of past workshops include:
          </p>
          <ul className="content-text px-8 sm:pl-32 text-base sm:text-xl list-disc">
            <li className="text-base sm:text-xl text-start py-2">
              More to the Core
            </li>
            <li className="text-base sm:text-xl text-start py-2">Mobility</li>
            <li className="text-base sm:text-xl text-start py-2">
              Fitness and Dance Sampler
            </li>
            <li className="text-base sm:text-xl text-start py-2">
              Holiday Bootcamps - Fight Strong Fundamentals (FSF)
            </li>
            <li className="text-base sm:text-xl text-start py-2">
              Introduction to Hip Hop
            </li>
          </ul>
        </div>
        {/* end side content section */}
        {/* begin image section */}
        <div className="w-1/2 h-auto hidden sm:flex items-center pr-32">
          <img
            src={laneway}
            alt="Kelly smiling in a laneway with angel wings behind her"
            className="rounded"
          />
        </div>
        {/* end image section */}
      </div>
      {/* end personal training content section */}

      {/* workshop graphics and links to workshops */}
      <WorkshopGraphicsContainer>
        <h1 style={{ paddingBottom: 0, fontFamily: "Nunito Sans, sans-serif" }}>
          CURRENT WORKSHOPS and UPCOMING EVENTS
        </h1>
        <p
          className="text-base sm:text-xl content-text mb-4 "
          style={{ color: "black", marginTop: "-20px", width: "100%" }}
        >
          Join our mailing list to{" "}
          <a
            href="https://live.vcita.com/site/kellykinetix/action/r1ssrsuz3i4o2698"
            rel="noopener noreferrer"
            target="_blank"
            style={{ color: "#081359" }}
          >
            <b>Start Your Journey Now</b>
          </a>{" "}
          and get updates before the general public about when these classes are
          starting up
        </p>
        {upcomingWorkshops.length > 0 &&
          upcomingWorkshops.map((event, index) => {
            if (index <= 5) {
              return (
                <EventItem
                  workshopTitle={event.name.text}
                  workshopInstructor="Kelly Enston"
                  cardImg={event.logo.url}
                  introText={event.description.text}
                  signUpLink={event.url}
                  workshopStartDate={event.start.local}
                  workshopEndDate={event.end.local}
                  venue_id={event.venue_id}
                  ticket_price_min={
                    event.ticket_availability.minimum_ticket_price
                      ? event.ticket_availability.minimum_ticket_price
                          .major_value
                      : 0
                  }
                  ticket_price_max={
                    event.ticket_availability.maximum_ticket_price
                      ? event.ticket_availability.maximum_ticket_price
                          .major_value
                      : 0
                  }
                  currency={
                    event.ticket_availability.minimum_ticket_price
                      ? event.ticket_availability.minimum_ticket_price.currency
                      : " "
                  }
                ></EventItem>
              );
            }
          })}
        {/*{workshops.length>0 && workshops.map(workshop=>{*/}
        {/*    return (<EventItem*/}
        {/*        workshopTitle={workshop.workshopTitle}*/}
        {/*        workshopInstructor={workshop.workshopInstructor}*/}
        {/*        cardImg={workshop.cardImg}*/}
        {/*        signUpLink={workshop.signUpLink}*/}
        {/*        workshopStartDate={workshop.workshopStartDate}*/}
        {/*        workshopEndDate={workshop.workshopEndDate}*/}
        {/*        ticket_price_min={workshop.ticket_price_min}*/}
        {/*        ticket_price_max={workshop.ticket_price_max}*/}
        {/*        currency={workshop.currency}*/}
        {/*        time={workshop.time}*/}
        {/*    ></EventItem>)*/}
        {/*})}*/}
      </WorkshopGraphicsContainer>
      <WorkshopGraphicsContainer>
        <h1 style={{ paddingBottom: 0 }}>PAST WORKSHOPS and EVENTS</h1>
        {pastWorkshops.length > 0 &&
          pastWorkshops.map((event, index) => {
            if (index >= 0 && index < 6) {
              return (
                <EventItem
                  workshopTitle={event.name.text}
                  workshopInstructor="Kelly Enston"
                  cardImg={event.logo.url}
                  introText={event.description.text}
                  signUpLink={event.url}
                  workshopStartDate={event.start.local}
                  workshopEndDate={event.end.local}
                  venue_id={event.venue_id}
                  ticket_price_min={
                    event.ticket_availability.minimum_ticket_price
                      ? event.ticket_availability.minimum_ticket_price
                          .major_value
                      : 0
                  }
                  ticket_price_max={
                    event.ticket_availability.maximum_ticket_price
                      ? event.ticket_availability.maximum_ticket_price
                          .major_value
                      : 0
                  }
                  currency={
                    event.ticket_availability.minimum_ticket_price
                      ? event.ticket_availability.minimum_ticket_price.currency
                      : " "
                  }
                ></EventItem>
              );
            }
          })}
      </WorkshopGraphicsContainer>
    </div>
  );
};
const WorkshopGraphicsContainer = styled.div`
  transform: translateX(7vw);
  display: flex;
  flex-wrap: wrap !important;
  column-gap: 5rem;
  margin-bottom: 3rem;
  /* margin-left: 1rem;
  margin-right: 1rem; */
  justify-items: center;
  font-size: 1.25rem;
  max-width: 100%;

  .workshops {
    max-width: 45%;
    flex: 1 0 41%;
    min-width: 275px;
    margin-bottom: 1rem;
    height: fit-content;
    background: ${(props) =>
      `linear-gradient(30deg, ${props.gradientOne}, ${props.gradientTwo})`};
  }
  h1 {
    flex-basis: 100%;
    font-size: 1.75rem;
    justify-self: left;
    padding-bottom: 1em;
  }

  @media screen and (max-width: 768px) {
    .workshops {
      min-width: 80vw;
    }
  }
`;

export default WorkshopPage;

import React from "react";
import logo from "./images/kk-logo.png";

const Footer = () => {
  return (
    <div className="h-24 sm:h-40 footer-background flex justify-end align-center px-4 sm:px-12 py-4 sm:mx-0">
      <img src={logo} alt="kelly kinetix logo" className="h-16 sm:h-32 mr-8" />
      <div className="flex flex-col align-end justify-center text-base sm:text-xl">
        <span className="footer-text-white">KellyKinetix 2022</span>
      </div>
    </div>
  );
};

export default Footer;

import React, { useState, useCallback } from "react";
import styled from "styled-components";
import produce from "immer";

// background images
import BgImg1 from "../../images/Bg1.jpg";
import BgImg2 from "../../images/Bg2.jpg";
import BgImg3 from "../../images/Bg3.jpg";
import BgImg4 from "../../images/Bg4.jpg";
import BgImg5 from "../../images/Bg5.jpg";
import BgImg6 from "../../images/Bg6.jpg";
import BgImg7 from "../../images/Bg7.jpg";
import BgImg8 from "../../images/Bg8.jpg";

const HeaderBackgroundSection = styled.section`
  width: 25%;
  height: 100%;
  background-image: url(${(props) => props.backgroundImage});
  transition: all linear 0.4s;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: ${(props) => props.opacityLevel};
  // device - tablets, iPads (portrait)
  @media only screen and (min-width: 769px) and (max-width: 1281px) {
    width: 50%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const BackgroundSection = ({ index, element, setBgArray }) => {
  const [opacityLevel, setOpacityLevel] = useState([0.4, 0.4, 0.4, 0.4]);
  const bgArrayInitial = [BgImg1, BgImg2, BgImg3, BgImg4];
  const bgArrayHover = [BgImg5, BgImg6, BgImg7, BgImg8];

  // used to change background photo and opacity for hovered background image
  const handleBgHover = (index, opacityValue, event) => {
    setOverlayOpacity(index, opacityValue);
    setNewBgPhoto(index, event);
  };

  // used to change opacity of background image (only hovered one)
  const setOverlayOpacity = useCallback((index, opacityValue) => {
    setOpacityLevel(
      produce((draftState) => {
        draftState[index] = opacityValue;
      })
    );
  }, []);

  // used to switch background photo (only hovered one)
  const setNewBgPhoto = useCallback((index, event) => {
    setBgArray(
      produce((draftState) => {
        draftState[index] =
          event === "out" ? bgArrayInitial[index] : bgArrayHover[index];
      })
    );
    // eslint-disable-next-line
  }, []);

  return (
    <HeaderBackgroundSection
      backgroundImage={element}
      opacityLevel={opacityLevel[index]}
      onMouseOver={() => handleBgHover(index, 1, "over")}
      onMouseOut={() => handleBgHover(index, 0.5, "out")}
    ></HeaderBackgroundSection>
  );
};

export default BackgroundSection;

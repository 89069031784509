import NoImage from "../images/noImage.jpg";

// used to generate unique identifier for each product added to the cart
import uuid from "react-uuid";

// initial state for Redux state
const initialState = {
  totalPrice: 0,
  totalProducts: 0,
  totalClientPrice: 0,
  products: [],
  promoApplied: false,
};

export default function cartReducer(state = initialState, action) {
  switch (action.type) {
    // used to add new item to shopping cart
    case "ADD_TO_SHOPPING_CART":
      const newProduct = {
        productID: action.payload.id,
        uniqueID: uuid(),
        quantity: action.payload.quantity ? action.payload.quantity : 1,
        image: action.payload.images ? action.payload.images[0] : NoImage,
        productTitle: action.payload.title,
        price: action.payload.publicPrice,
        clientPrice: action.payload.clientPrice,
        color: action.payload.color ? action.payload.color : null,
        size: action.payload.size ? action.payload.size : null,
        frontDesign: action.payload.frontDesign
          ? action.payload.frontDesign
          : null,
        backDesign: action.payload.backDesign
          ? action.payload.backDesign
          : null,
        sleeve: action.payload.sleeve ? action.payload.sleeve : null,
      };
      return {
        ...state,
        totalPrice: +(
          state.totalPrice +
          newProduct.price * newProduct.quantity
        ).toFixed(2),
        totalClientPrice: +(
          state.totalClientPrice +
          newProduct.clientPrice * newProduct.quantity
        ).toFixed(2),
        totalProducts: state.totalProducts + newProduct.quantity,
        products: [...state.products, newProduct],
      };
    // used to update shopping cart - when user changes quantity of a particular item
    case "UPDATE_SHOPPING_CART":
      // we find index of item that has to be updated
      const productIndex = state.products.findIndex(
        (element) => element.uniqueID === action.payload.uniqueID
      );
      const copyArray = [...state.products];
      const productToUpdate = copyArray[productIndex];

      // counting quantity of products in cart (without product that has to be updated)
      const previousProductsQty =
        state.totalProducts - productToUpdate.quantity;

      // counting total of the cart (without product that has to be updated)
      const cartTotalWithoutProduct =
        state.totalPrice - productToUpdate.quantity * productToUpdate.price;
      const cartClientTotalWithoutProduct =
        state.totalClientPrice -
        productToUpdate.quantity * productToUpdate.clientPrice;

      // counting quantity of products in cart (with new quantity of product that has to be updated)
      const newProductsQty = previousProductsQty + action.payload.newQtyValue;

      // counting total of the cart (with product that has to be updated)
      const newCartTotal =
        cartTotalWithoutProduct +
        action.payload.newQtyValue * productToUpdate.price;

      const newCartClientTotal =
        cartClientTotalWithoutProduct +
        action.payload.newQtyValue * productToUpdate.clientPrice;

      // changing quantity of that product in copied array
      copyArray[productIndex].quantity = action.payload.newQtyValue;

      return {
        ...state,
        products: copyArray,
        totalPrice: newCartTotal,
        totalClientPrice: newCartClientTotal,
        totalProducts: newProductsQty,
      };
    case "DELETE_FROM_SHOPPING_CART":
      // we find index of item that has to be deleted
      const productToDeleteIndex = state.products.findIndex(
        (element) => element.uniqueID === action.payload
      );
      const productToDelete = state.products[productToDeleteIndex];

      // counting total price value of that product
      const productTotalPrice =
        productToDelete.price * productToDelete.quantity;

      const productTotalClientPrice =
        productToDelete.clientPrice * productToDelete.quantity;

      // producing new array without product that has to be deleted
      const filteredProductsArray = state.products.filter(
        (element) => element.uniqueID !== action.payload
      );

      return {
        ...state,
        totalPrice: state.totalPrice - productTotalPrice,
        totalClientPrice: state.totalClientPrice - productTotalClientPrice,
        totalProducts: state.totalProducts - productToDelete.quantity,
        products: [...filteredProductsArray],
      };

    case "UPDATE_CART_WITH_CLIENT_PRICE":
      let clientTotalPrice = 0;
      state.products.forEach((product) => {
        clientTotalPrice += product.clientPrice * product.quantity;
      });
      return {
        ...state,
        totalClientPrice: clientTotalPrice,
        promoApplied: true,
      };

    case "TURN_ON_PROMO":
      return {
        ...state,
        promoApplied: true,
      };

    case "TURN_OFF_PROMO":
      return {
        ...state,
        promoApplied: false,
      };

    case "EMPTY_CART":
      return {
        ...state,
        totalPrice: 0,
        totalClientPrice: 0,
        totalProducts: 0,
        products: [],
      };
    default:
      return state;
  }
}

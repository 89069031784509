import React from "react";
import styled from "styled-components";
// select input control package
import Select, { components } from "react-select";

const ColorContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 270px;
`;

const ColorLabel = styled.label`
  flex: 1;
  font-size: 0.95em;
`;

const { Option } = components;
const IconOption = (props) => (
  <Option {...props}>
    {props.data.icon !== "" && (
      <svg height="12" width="12" style={{ display: "inline" }}>
        <circle cx="6" cy="6" r="6" fill={props.data.icon} />
      </svg>
    )}
    <span>&nbsp; {props.data.label}</span>
  </Option>
);

const ColourPicker = ({ title, product, setColor, color }) => {
  // navigation links for dropdown select input
  const servicesOptions = [];

  switch (title) {
    case "Colour":
      product.colorOptions.map((element) =>
        servicesOptions.push({
          value: element.text,
          label: element.text,
          icon: element.colorSwatch,
        })
      );
      setColor(color);
      break;
    case "Size":
      product.sizeOptions.map((element) =>
        servicesOptions.push({ value: element, label: element })
      );
      break;
    case "Front Design":
      product.frontDesignOptions.map((element) =>
        servicesOptions.push({ value: element, label: element })
      );
      break;
    case "Back Design":
      product.backDesignOptions.map((element) =>
        servicesOptions.push({ value: element, label: element })
      );
      break;
    case "Sleeve Design":
      product.sleeveOptions.map((element) =>
        servicesOptions.push({ value: element, label: element })
      );
      break;
    default:
      break;
  }

  // customized CSS for dropdown select
  const customStyle = {
    option: (base) => ({
      ...base,
      cursor: "pointer",
      paddingLeft: "8px",
      fontSize: "0.8rem",
      textTransform: "capitalize",
    }),
    control: (base) => ({
      ...base,
      display: "flex",
      width: "200px",
      cursor: "pointer",
      boxShadow: "none",
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: "1rem",
    }),
    indicatorsContainer: () => ({
      paddingRight: "15px",
    }),
  };
  return (
    <ColorContainer
      className={product.colorOptions !== undefined ? "" : "d-none"}
    >
      <ColorLabel>{title}</ColorLabel>
      <Select
        defaultValue={servicesOptions[0]}
        options={servicesOptions}
        onChange={(newOption) => setColor(newOption.value)}
        styles={customStyle}
        components={title === "Colour" ? { Option: IconOption } : {}}
      />
      <hr />
    </ColorContainer>
  );
};

export default ColourPicker;

import React from "react";

// react-helmet
import { Helmet } from "react-helmet";

// Testimonial component
import { TestimonialSectionComponent } from "../../Testimonial";

// testimonial data
import { testimonials } from "../../../data";

// images
import greenery from "./images/greenery.jpg";
import deadlift from "./images/deadlift.jpg";
import machine from "./images/machine.jpg";
import grinning from "./images/grinning.jpg";
import client from "./images/client.jpg";

const PersonalTrainingPage = () => {
  const { fitnessTestimonialData } = testimonials;
  // begin fitness page
  return (
    <div className="h-auto flex flex-col">
      {/* begin react-helmet component */}
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="About the fitness services offered by KellyKinetix which include mobility and strength training. KellyKinetix offers belly dancing and personal trainers in north york, Toronto, GTA and Ontario through online classes."
        />

        {/* meta tags for social media sharing */}
        <meta property="og:title" content="KellyKinetix Fitness" />
        <meta
          property="og:url"
          content="https://www.kellykinetix.com/fitness"
        />
        <meta
          name="keywords"
          content="gym instruction, training, working out, fitness classes, exercise classes near me, group fitness classes near me, workout classes, exercise classes, group workout classes near me, exercise groups near me, zumba exercise for beginners, exercise classes near me, group fitness classes near me, exercise classes, group workout classes near me, workout classes, zumba dance, circuit training, fitness classes near me, workout classes near me, zumba, cpr, personal trainer, personal trainer downtown toronto, fit women, goodlife workout, housefit personal training toronto, personal trainer, trainer, physical trainer, personal trainer at home, female personal trainer, personal training studio, fitness classes, personal trainers near me, fitness studio, fitness studio toronto, personal trainer, personal training studio, private trainer, personal fitness, studio north, health & fitness, personal training, fitness gym, health fitness, fitness centre, toronto fitness, gym in toronto, downtown toronto gym, in love yourself, love your self, love yourself, my self love, linktree instagram, facebook instagram, instagram and facebook, tiktok instagram, face book face book, mind body connection, mind body, mind and body, body mind, cardiovascular fitness, benefits of physical activity, benefits of physical fitness, 10 benefits of physical activity, workout benefits, cardio vascular fitness, paul chek, smart goals examples, smart goals acronym, client testimonials, mobility, mobility plus, mobility, resilience, resiliency, resilience examples, mental resilience, prestige awards, corporate livewire prestige awards, prestige trophies"
        />

        <meta
          property="og:description"
          content="Reserve a consultation to book your personal training session. Also offer group fitness classes and customized programming to get everyone to reach their goals."
        />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:image" content={"%PUBLIC_URL%/" + deadlift} />
        <meta property="og:type" content="website" />

        <title>IN PERSON/ONLINE 1-ON-1 PERSONAL TRAINING</title>
      </Helmet>
      {/* end react-helmet component */}
      {/* begin fitness journey content section */}
      {/* begin side content section */}
      <span className="text-3xl font-bold text-center my-12 header-text">
        IN PERSON/ONLINE 1-ON-1 PERSONAL TRAINING
      </span>
      <div className="flex justify-between items-center mb-12">
        <div className="w-full sm:w-1/2 h-auto flex flex-col">
          <p className="sm:pr-12 px-8 sm:pl-32 text-base sm:text-xl my-4 content-text">
            1-on-1 training is effective because your trainer can give you
            undivided attention. When your trainer can focus on you, you get the
            best results. As well, we do not use a “cookie cutter approach” to
            your training programs and workouts. Each client’s programme and
            workouts are specifically designed to suit your body, your fitness
            level, your needs and goals.
          </p>
          <p className="sm:pr-12 px-8 sm:pl-32 text-base sm:text-xl my-4 content-text">
            People hire personal trainers for a variety of reasons, including:
          </p>
          <ul className=" px-8 sm:pl-40 sm:pr-12 text-base sm:text-xl list-disc content-text">
            <li className="text-base sm:text-xl text-start py-2">
              To learn how to work out properly
            </li>
            <li className="text-base sm:text-xl text-start py-2">
              To learn how to support their body to be able to dance and move
              well for as long as possible
            </li>
            <li className="text-base sm:text-xl text-start py-2">
              To stay motivated
            </li>
            <li className="text-base sm:text-xl text-start py-2">
              To lose weight or gain muscle in the most efficient and effective
              ways
            </li>
            <li className="text-base sm:text-xl text-start py-2">
              If more advanced, to push plateaus that they would not normally be
              able to reach on their own
            </li>
            <li className="text-base sm:text-xl text-start py-2">
              To develop a healthier lifestyle
            </li>
            <li className="text-base sm:text-xl text-start py-2">
              To help them set SMART goals
            </li>
            <li className="text-base sm:text-xl text-start py-2">
              To decrease the effects of chronic disease on the body
            </li>
          </ul>
          <p className="px-8 sm:px-32 text-base sm:text-xl pb-8 mb-12 content-text">
            Learn about{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="/groupFitness"
              className="content-link"
            >
              IN PERSON/ONLINE GROUP FITNESS
            </a>
            .
          </p>
        </div>
        {/* end side content section */}
        {/* begin image section */}
        <div className="w-full sm:w-1/2 h-60 sm:h-auto flex items-center mb-12 sm:mb-0 px-8 sm:pl-32">
          <img
            src={machine}
            alt="working out with a machine"
            className="rounded"
          />
        </div>
        {/* end image section */}
        {/* end one on one personal training content section */}
      </div>
    </div>
  );
  // end fitness page
};

export default PersonalTrainingPage;

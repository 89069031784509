// image imports
import React from "react";
import logo from "./full-logo.png";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaEnvelope,
  FaPinterest,
} from "react-icons/fa";
import { BookNowBtn } from "../ConsultationPage";

// CallToAction component
import CallToAction from "../../CallToAction";

// React-helmet
import { Helmet } from "react-helmet";

// image for thumbnail meta tag
import backdrop from "../../CallToAction/cta-image.jpg";

export const jsonLD = {
  "@context": "https://schema.org/",
  "@type": "LocalBusiness",
  name: "KellyKinetix",
  author: {
    "@type": "Person",
    name: "Kelly Enston",
  },
  description:
    "Take care of your body in fun was, through group belly dancing and body resilience classes. Personal training can also be included, as well as various other forms of exercise (Jiu Jitsu, Kick Boxing), all in the Greater Toronto Area (GTA).",
  areaServed: {
    "@type": "Province",
    name: "Ontario",
  },
  priceRange: "",
  paymentAccepted: "Credit Card",
  hasOfferCatalog: {
    "@type": "OfferCatalog",
    name: "KellyKinetix Services",
    itemListElement: [
      {
        "@type": "OfferCatalog",
        name: "Classes and Training",
        itemListElement: [
          {
            "@type": "Offer",
            itemOffered: {
              "@type": "Service",
              name: "Personal Training",
            },
          },

          {
            "@type": "Offer",
            itemOffered: {
              "@type": "Service",
              name: "Belly Dance",
            },
          },

          {
            "@type": "Offer",
            itemOffered: {
              "@type": "Service",
              name: "Body Resilience and Strength Training Group Class",
            },
          },

          {
            "@type": "Offer",
            itemOffered: {
              "@type": "Service",
              name: "Dance and Nutrition Workshops",
            },
          },
        ],
      },
      {
        "@type": "OfferCatalog",
        name: "One-time Service",
        itemListElement: [
          {
            "@type": "Offer",
            itemOffered: {
              "@type": "Service",
              name: "Consultation",
            },
          },
        ],
      },
    ],
  },
};
export const metaTagConstants = {
  title: "KellyKinetix Home",
  description:
    "Stay fit and active through personal training sessions or group belly dancing and body strengthing classes. Classes are held over zoom currently until COVID-19 regulations allow in-person.",
  locale: "en_GB", // defines language type
  url: "https://www.kellykinetix.com/",
  image: backdrop,
  keywords:
    "gym instruction, training, working out, personal trainer, personal trainer downtown toronto, fit women, goodlife workout, housefit personal training toronto, personal trainer, trainer, rainer, physical trainer, personal trainer at home, female personal trainer, personal training studio, fitness classes, personal trainers near me, fitness studio, fitness studio toronto, personal trainer, personal training studio, private trainer, personal fitness, studio north, health & fitnes, personal training, fitness gym, health fitness, fitness centre, health & fitness, personal training, fitness gym, health fitness, fitness centre, toronto fitness, gym in toronto, downtown toronto gym, in love yourself, love your self, love yourself, my self love, linktree instagram, facebook instagram, instagram and facebook, tiktok instagram, face book face book, belly dance toronto, belly dancing classes toronto, mind body connection, mind body, mind and body, body mind",
};

const HomePage = () => {
  // begin about page and header
  return (
    <div
      className="h-auto flex flex-col"
      itemScope
      itemType="https://schema.org/LocalBusiness"
    >
      {/* begin react-helmet component */}
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="KellyKinetix is a company that offers fitness and belly dance classes as well as personal training in Toronto, North York, GTA and Ontario, Canada and remotely through Zoom."
        />
        <meta name="keywords" content={metaTagConstants.keywords} />
        {/* <title>About KellyKinetix</title> */}

        {/* key opengraph meta tags */}
        <meta property="og:title" content={metaTagConstants.title} />
        <meta property="og:url" content={metaTagConstants.url} />
        <meta
          property="og:description"
          content={metaTagConstants.description}
        />
        <meta property="og:locale" content={metaTagConstants.locale} />
        <meta
          property="og:image"
          content={"%PUBLIC_URL%/" + metaTagConstants.image}
        />
        <meta property="og:type" content="website" />

        {/* JSON-LD data for SEO */}
        <script type="application/json+ld">{JSON.stringify(jsonLD)}</script>
      </Helmet>
      {/* end react-helmet component */}
      {/* Header component */}

      {/* CallToAction component */}
      <CallToAction />
      {/* begin content section */}
      {/* begin April challenge - temporarily here! */}
      {/*<span className="text-base w-full h-fit sm:text-3xl block text-center pt-4 font-black w-80  xl:hidden">*/}
      {/*  Join our Limited Time Women's Day contest offer!<br/></span>*/}
      {/*<Link*/}
      {/*    to="/WomensDayContest"*/}
      {/*    rel="noopener noreferrer"*/}
      {/*    target="_blank"*/}
      {/*    style={{margin:"auto"}}*/}
      {/*    className="xl:hidden"*/}
      {/*>*/}
      {/*  <button type="button" className="btn btn-dark" style={{ margin:"2rem 0", color: "white", backgroundColor: "orangered", borderColor: "orangered"}}>Join now</button>*/}
      <span
        className="text-base sm:text-3xl block xl:hidden w-full flex justify-center sm:items-center mt-2 text-center mb-2"
        style={{ padding: " 0 4vw", color: "#081359" }}
      >
        Empowering women to regain their energy through coaching in dance,
        strength, mobility & lifestyle habit changes.
        <br />
      </span>
      <span className="text-base sm:text-3xl block font-black xl:hidden w-full flex justify-center sm:items-center mt-2 text-center ">
        Start your journey Here!
        <br />
      </span>
      <a
        href="https://live.vcita.com/site/kellykinetix/action/r1ssrsuz3i4o2698"
        rel="noopener noreferrer"
        target="_blank"
        style={{ margin: "auto" }}
        className="xl:hidden"
      >
        <button
          type="button"
          className="btn btn-dark-blue mt-2 mb-3"
          style={{ fontWeight: "700" }}
        >
          Join now
        </button>
      </a>

      {/*</Link>*/}
      <span className="text-base sm:text-3xl block font-black xl:hidden w-full flex justify-center sm:items-center mt-2 text-center ">
        Follow us on social media
        <br />
      </span>
      <div className="h-12 w-full flex items-start block justify-center sm:items-center mt-2 text-center xl:hidden">
        <span
          className="
            p-2 mr-2 
            bg-black text-white 
            hover:bg-white hover:text-black
            transition duration-300 
            ease-in-out rounded-full
          "
        >
          <a
            href="https://www.instagram.com/kellykinetix"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram />
          </a>
        </span>
        <span
          className="
            p-2 mr-2 
            bg-black text-white 
            hover:bg-white hover:text-black
            transition duration-300 
            ease-in-out rounded-full
          "
        >
          <a
            href="https://www.facebook.com/kellykinetix"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook />
          </a>
        </span>
        <span
          className="
            p-2 mr-2 
            bg-black text-white 
            hover:bg-white hover:text-black
            transition duration-300 
            ease-in-out rounded-full
          "
        >
          <a
            href="https://www.linkedin.com/company/kellykinetics"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin />
          </a>
        </span>
        <span
          className="
            p-2 mr-2 
            bg-black text-white 
            hover:bg-white hover:text-black
            transition duration-300 
            ease-in-out rounded-full
          "
        >
          <a
            href="mailto:info@kellykinetix.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaEnvelope />
          </a>
        </span>
        <span
          className="
            p-2 mr-2 
            bg-black text-white 
            hover:bg-white hover:text-black
            transition duration-300 
            ease-in-out rounded-full
          "
        >
          <a
            href="https://www.pinterest.com/KellyKinetix"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaPinterest />
          </a>
        </span>
      </div>
      {/*<div className="xl:grid grid-cols-1 gap-10 grid-rows-1 w-full mx-auto p-10">*/}
      {/*  <p className="pt-4 text-base xl:place-self-center xl:leading-relaxed xl:content-text xl:text-2xl">*/}
      {/*    Imagine a reality where you feel energetic, vibrant, resilient, look*/}
      {/*    great and are deeply connected/reconnected to your authentic divine*/}
      {/*    feminine self. You are confident, have high self esteem, and you*/}
      {/*    attract the same type of people and situations into your life. You*/}
      {/*    belong to a community of other like-minded women who support each*/}
      {/*    other and are accountable not only to those who teach and train you,*/}
      {/*    but also to your community. At KellyKinetix, you'll be able to reach*/}
      {/*    your goals and share your joy through your journey. Multiple avenues*/}
      {/*    of dance, fitness, and breathwork are combined to help your*/}
      {/*    transformation into this reality. Ready to Thrive Through Life?*/}
      {/*  </p>*/}
      {/*</div>*/}

      {/*<p className="pt-4 text-center text-xl xl:place-self-center  content-text text-3xl">*/}
      {/*  Start your journey{" "}*/}
      {/*  <a*/}
      {/*    href="https://live.vcita.com/site/kellykinetix/action/r1ssrsuz3i4o2698"*/}
      {/*    rel="noopener noreferrer"*/}
      {/*    target="_blank"*/}
      {/*    style={{ color: "orangered" }}*/}
      {/*  >*/}
      {/*    <b>*/}
      {/*      <u>Here!</u>*/}
      {/*    </b>*/}
      {/*  </a>*/}
      {/*</p>*/}

      {/* begin section header */}

      <span
        className="text-2xl font-bold text-center mx-5 sm:my-12 header-text"
        style={{ color: "black", textTransform: "none", marginTop: "5rem" }}
      >
        We are in the business of providing people with resiliency to enable
        them to adapt in a changing world.
      </span>
      {/*/!* begin mission statement content section *!/*/}
      {/*/!* begin section header *!/*/}
      {/*<span className="text-3xl font-bold text-center my-4 sm:my-12 header-text">*/}
      {/*  MISSION STATEMENT*/}
      {/*</span>*/}
      {/*/!* end section header *!/*/}
      {/*<div className="flex justify-between items-center">*/}
      {/*  /!* begin image section *!/*/}
      {/*  <div className="w-1/2 h-auto hidden sm:flex items-center pl-32">*/}
      {/*    <img*/}
      {/*      src={wallPicture}*/}
      {/*      alt="standing against a wall"*/}
      {/*      className="rounded"*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*  /!* end image section *!/*/}
      {/*  /!* begin side content section *!/*/}
      {/*  <div className="w-full sm:w-1/2 h-auto flex flex-col">*/}
      {/*    <span className="text-xl sm:text-2xl self-center sm:self-end px-8 sm:pr-32 font-bold header-text mb-6 pt-6">*/}
      {/*      VISION*/}
      {/*    </span>*/}
      {/*    <p className="px-8 sm:pl-12 sm:pr-32 text-base sm:text-xl my-4 content-text">*/}
      {/*      /!*I am in the business of providing people with resiliency to enable*!/*/}
      {/*      /!*them to adapt in a changing world. Through personal training at*!/*/}
      {/*      /!*KellyKinetix, our mission is for you to become healthier, stronger,*!/*/}
      {/*      /!*guide you in your path to healthy living whether your goal is to*!/*/}
      {/*      /!*lose weight or gain confidence! Loosen those hips, we’ll show you*!/*/}
      {/*      /!*that fitness can be fun and how you can incorporate dancing into a*!/*/}
      {/*      /!*fun fitness routine. Although men and women of all ages are*!/*/}
      {/*      /!*welcomed, my main focus is women aged 35-65.*!/*/}
      {/*      We live in a world where the societal norms that say aging is a negative experience have been squashed! People have learned the tools, technologies and lifestyle habits they need in order to create a future far different for themselves and their families than the generations before them. Resilience runs rampant and there is an abundance of health, strength, happiness and confidence present across our planet. Sharing experiences and celebrating is a regular occurrence. Life spans expand and the older we get the more resilient we become. We are fully functional, energetic and love all that life has to offer.*/}
      {/*    </p>*/}
      {/*  </div>*/}
      {/*  /!* end side content section *!/*/}
      {/*</div>*/}
      {/*/!* end mission statement content section *!/*/}

      {/*/!* begin vision content section *!/*/}
      {/*<div className="flex justify-between items-center">*/}
      {/*  /!* begin side content section *!/*/}
      {/*  <div className="w-full sm:w-1/2 h-auto flex flex-col">*/}
      {/*    <span className="text-xl sm:text-2xl px-8 sm:pl-32 self-center sm:self-start font-bold header-text mb-6 pt-6">*/}
      {/*      External Mission*/}
      {/*    </span>*/}
      {/*    <p className="px-8 sm:pr-12 sm:pl-32 text-base sm:text-xl my-4 content-text">*/}
      {/*      /!*KellyKinetix’s vision is to open facilities offering dance and*!/*/}
      {/*      /!*fitness catering to beginners and those with minimal experience who*!/*/}
      {/*      /!*want an alternate route to overall health and wellness. As we grow,*!/*/}
      {/*      /!*there will be 1-on-1 personal training, semi private personal*!/*/}
      {/*      /!*training and a regular schedule with the addition of other available*!/*/}
      {/*      /!*products, services, classes and workshops to help people thrive*!/*/}
      {/*      /!*through life as the best version of themselves!*!/*/}
      {/*      We are in the business of providing people with resiliency to enable them to adapt in a changing world. At KellyKinetix, we change lives through Dance and Fitness. Through every interaction our community is healthier, stronger, happier and far more confident. Your journey is a shared experience.  We celebrate our clients.*/}
      {/*    </p>*/}

      {/*  </div>*/}
      {/*  /!* end side content section *!/*/}
      {/*  /!* begin image section *!/*/}
      {/*  <div className="w-1/2 h-auto hidden sm:flex items-center pr-32">*/}
      {/*    <img*/}
      {/*      src={withClient}*/}
      {/*      alt="working with a client"*/}
      {/*      className="rounded"*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*  /!* end image section *!/*/}
      {/*</div>*/}
      {/*/!* end vision content section *!/*/}
      {/*<span className="text-3xl sm:text-4xl font-bold text-center mt-12 sm:mt-16 header-text">*/}
      {/*  ABOUT US*/}
      {/*</span>*/}
      {/*/!* end section header *!/*/}
      {/* begin section subheader */}
      <span className="text-2xl sm:text-3xl font-bold text-center mt-4 sm:mt-8 mb-4 sm:mb-12 header-text">
        Introduction to the Company
      </span>
      {/* end section subheader */}
      <div className="flex justify-between mb-20 lg:mt-20">
        {/* begin side content section */}
        <div className="lg:w-1/2 w-full h-auto px-8 lg:pl-32 text-base md:text-xl my-auto content-text">
          <p className="mb-14 sm:leading-8 leading-15">
            My company KellyKinetix was founded in January 2014. While working
            at a private gym, I was able to develop and gain crucial skills that
            make me the experienced trainer I am today. Eventually, I moved from
            working for others into making KellyKinetix my full-time gig. The
            company has continuously been evolving as I develop as a person. My
            phoenix tattoo and logo symbolize this point in my life where I rose
            out of the ashes and continue to rise!
          </p>
          <BookNowBtn
            href="/aboutUs"
            target="blank"
            rel="noopener noreferrer"
            className="block w-3/4 m-auto"
          >
            Learn More About Us
          </BookNowBtn>
        </div>
        {/* end side content section */}
        {/* begin image section */}
        <div className="w-full lg:w-1/2 h-auto hidden lg:flex items-center pr-32">
          <img
            src={logo}
            alt="main KellyKinetix logo"
            className="block m-auto w-4/5"
          />
        </div>
        {/* end image section */}
      </div>
      {/* end content section */}
    </div>
    // end home page
  );
};

export default HomePage;

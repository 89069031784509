// image imports
import React from "react";
import logo from "../HomePage/full-logo.png";

import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaEnvelope,
  FaPinterest,
} from "react-icons/fa";
import { BookNowBtn } from "../ConsultationPage";

// CallToAction component
import CallToAction from "../../CallToAction";

// React-helmet
import { Helmet } from "react-helmet";

// image for thumbnail meta tag
import backdrop from "../../CallToAction/cta-image.jpg";

export const jsonLD = {
  "@context": "https://schema.org/",
  "@type": "LocalBusiness",
  name: "KellyKinetix",
  author: {
    "@type": "Person",
    name: "Kelly Enston",
  },
  description:
    "Take care of your body in fun was, through group belly dancing and body resilience classes. Personal training can also be included, as well as various other forms of exercise (Jiu Jitsu, Kick Boxing), all in the Greater Toronto Area (GTA).",
  areaServed: {
    "@type": "Province",
    name: "Ontario",
  },
  priceRange: "",
  paymentAccepted: "Credit Card",
  hasOfferCatalog: {
    "@type": "OfferCatalog",
    name: "KellyKinetix Services",
    itemListElement: [
      {
        "@type": "OfferCatalog",
        name: "Classes and Training",
        itemListElement: [
          {
            "@type": "Offer",
            itemOffered: {
              "@type": "Service",
              name: "Personal Training",
            },
          },

          {
            "@type": "Offer",
            itemOffered: {
              "@type": "Service",
              name: "Belly Dance",
            },
          },

          {
            "@type": "Offer",
            itemOffered: {
              "@type": "Service",
              name: "Body Resilience and Strength Training Group Class",
            },
          },

          {
            "@type": "Offer",
            itemOffered: {
              "@type": "Service",
              name: "Dance and Nutrition Workshops",
            },
          },
        ],
      },
      {
        "@type": "OfferCatalog",
        name: "One-time Service",
        itemListElement: [
          {
            "@type": "Offer",
            itemOffered: {
              "@type": "Service",
              name: "Consultation",
            },
          },
        ],
      },
    ],
  },
};
export const metaTagConstants = {
  title: "KellyKinetix Home",
  description:
    "Stay fit and active through personal training sessions or group belly dancing and body strengthing classes. Classes are held over zoom currently until COVID-19 regulations allow in-person.",
  locale: "en_GB", // defines language type
  url: "https://www.kellykinetix.com/",
  image: backdrop,
  keywords:
    "gym instruction, training, working out, personal trainer, personal trainer downtown toronto, fit women, goodlife workout, housefit personal training toronto, personal trainer, trainer, rainer, physical trainer, personal trainer at home, female personal trainer, personal training studio, fitness classes, personal trainers near me, fitness studio, fitness studio toronto, personal trainer, personal training studio, private trainer, personal fitness, studio north, health & fitnes, personal training, fitness gym, health fitness, fitness centre, health & fitness, personal training, fitness gym, health fitness, fitness centre, toronto fitness, gym in toronto, downtown toronto gym, in love yourself, love your self, love yourself, my self love, linktree instagram, facebook instagram, instagram and facebook, tiktok instagram, face book face book, belly dance toronto, belly dancing classes toronto, mind body connection, mind body, mind and body, body mind",
};

const AboutUsPage = () => {
  // begin about page and header
  return (
    <div
      className="h-auto flex flex-col mb-20"
      itemScope
      itemType="https://schema.org/LocalBusiness"
    >
      {/* begin react-helmet component */}
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="KellyKinetix is a company that offers fitness and belly dance classes as well as personal training in Toronto, North York, GTA and Ontario, Canada and remotely through Zoom."
        />
        <meta name="keywords" content={metaTagConstants.keywords} />
        {/* <title>About KellyKinetix</title> */}

        {/* key opengraph meta tags */}
        <meta property="og:title" content={metaTagConstants.title} />
        <meta property="og:url" content={metaTagConstants.url} />
        <meta
          property="og:description"
          content={metaTagConstants.description}
        />
        <meta property="og:locale" content={metaTagConstants.locale} />
        <meta
          property="og:image"
          content={"%PUBLIC_URL%/" + metaTagConstants.image}
        />
        <meta property="og:type" content="website" />

        {/* JSON-LD data for SEO */}
        <script type="application/json+ld">{JSON.stringify(jsonLD)}</script>
      </Helmet>
      {/* end react-helmet component */}

      <span className="text-2xl sm:text-3xl font-bold text-center mt-4 sm:mt-8 mb-4 sm:mb-12 header-text">
        The Company
      </span>
      {/* end section subheader */}
      <div className="flex justify-between mb-20 lg:mt-10">
        {/* begin side content section */}
        <div className="lg:w-1/2 w-full h-auto px-8 lg:pl-32 text-base md:text-xl my-auto content-text">
          <p className="mb-14 sm:leading-8 leading-15">
            My company KellyKinetix was founded in January 2014. While working
            at a private gym, I was able to develop and gain crucial skills that
            make me the experienced trainer I am today. Eventually, I moved from
            working for others into making KellyKinetix my full-time gig. The
            company has continuously been evolving as I develop as a person. My
            phoenix tattoo and logo symbolize this point in my life where I rose
            out of the ashes and continue to rise!
          </p>
          <p className="mb-14 sm:leading-8 leading-15">
            At KellyKinetix we have two branches. KellyKinetix - Dance and
            KellyKinetix - Fitness. Though two separate entities, they work in
            tandem. I open my doors to beginners and those with minimal
            experience who find getting fit and healthy in a gym environment
            unappealing. People who want to learn dance for fun and fitness that
            have an open mind and can see how learning appropriate body
            awareness, strength, mobility, flexibility, postural alignment and
            correction can help them dance to any age they want. The main dance
            form I teach is bellydance. I offer in person 1-on-1 personal
            training and belly dance, 1-on-1 online personal training, group
            dance and fitness classes.
          </p>
        </div>
        {/* end side content section */}
        {/* begin image section */}
        <div className="w-full lg:w-1/2 h-auto hidden lg:flex items-center pr-32">
          <img
            src={logo}
            alt="main KellyKinetix logo"
            className="block m-auto w-4/5"
          />
        </div>
        {/* end image section */}
      </div>
      {/* end content section */}

      {/* begin INTERNSHIP PROGRAM section */}
      {/*<span className="text-2xl sm:text-3xl font-bold text-center mt-4 sm:mt-8 mb-4 sm:mb-12 header-text">*/}
      {/*      INTERNSHIP PROGRAM*/}
      {/*    </span>*/}
      {/*<p className="px-8 sm:pr-12 sm:pl-32 text-base sm:text-xl my-4 content-text">*/}
      {/*  This program was started in June 2020 and continues to develop. KellyKinetix provides people with a passion for both dance and/or fitness to be part of a dynamic company. In the midst of the pandemic, everything is conducted virtually and this opportunity is pursued remotely. There are 2 terms starting each January and June.*/}
      {/*</p>*/}
      {/*<p className="px-8 sm:pr-12 sm:pl-32 text-base sm:text-xl my-4 content-text">*/}
      {/*  This opportunity is open to current college and university students, recent graduates, and professionals looking for a career change. International students on a student visa are also welcome to apply. For more information, please contact us.*/}
      {/*  /!* need to include a link for email here *!/*/}
      {/*</p>*/}
      {/* end INTERNSHIP PROGRAM section */}

      {/*begin credentials section */}
      <div className="h-auto flex flex-col lg:my-12 my-8">
        <span className="text-3xl font-bold text-center header-text">
          CREDENTIALS
        </span>
        <p className="text-base sm:text-xl my-8 text-center content-text">
          Here is a list of Kelly's credentials and the list continuously grows.
        </p>
        <ul className="content-text px-12 lg:px-64">
          <li className="text-base sm:text-xl py-2">
            Canfitpro Personal Training Specialist (PTS)
          </li>
          <li className="text-base sm:text-xl py-2">CPR with AED Certified</li>
          <li className="text-base sm:text-xl py-2">
            Bachelor of Science in Nursing (BScN)
          </li>
          <li className="text-base sm:text-xl py-2">
            Registered Nurse (R.N.) Non-Practicing Class Registered with the
            College of Nurses of Ontario
          </li>
          <li className="text-base sm:text-xl py-2">
            Level 1 Flexibility Trainer
          </li>
          <li className="text-base sm:text-xl py-2">TRX Certified</li>
          <li className="text-base sm:text-xl py-2">
            Certified Advanced Biomechanics Specialist
          </li>
          <li className="text-base sm:text-xl py-2">
            Certified Core Conditioning Specialist
          </li>
          <li className="text-base sm:text-xl py-2">
            Fitness Kickboxing & MMA Conditioning Instructor (Level 1) Certified
          </li>
          <li className="text-base sm:text-xl py-2">FMA-GEM Certified</li>
          <li className="text-base sm:text-xl py-2">
            Introduction and Level 1 Thai Yoga Stretching for Fitness
            Professionals
          </li>
        </ul>
        <p className="text-base sm:text-xl my-8 text-center content-text">
          In addition to the above credentials, Kelly also has athletic
          experience in the following:
        </p>
        <ul className="content-text px-8 lg:px-64 mb-12">
          <li className="text-base sm:text-xl py-2">Weight Training</li>
          <li className="text-base sm:text-xl py-2">
            Muay Thai/Fitness Kickboxing
          </li>
          <li className="text-base sm:text-xl py-2">
            Amateur Fitness & Bikini Model Competitor
          </li>
          <li className="text-base sm:text-xl py-2">Long-Distance Running</li>
          <li className="text-base sm:text-xl py-2">
            Lifelong Student of Flamenco Dance
          </li>
          <li className="text-base sm:text-xl py-2">
            Certified Middle Eastern Dance Instructor Training - 3 levels
            completed
          </li>
          <li className="text-base sm:text-xl py-2">
            Professional Middle Eastern Dance Performer and Instructor
          </li>
        </ul>
      </div>
      {/*end credentials section */}

      <BookNowBtn
        href="/vision"
        target="blank"
        rel="noopener noreferrer"
        className="block w-3/4 lg:w-1/2 mx-auto lg:my-20"
      >
        Learn about our Company Vision
      </BookNowBtn>
      {/* Footer component */}
    </div>
    // end about page
  );
};

export default AboutUsPage;

import React from "react";

// used to navigate uer through the website
import { useHistory } from "react-router-dom";

// used as a PayPal JS SDK
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

//  hook that returns a reference to the dispatch function from the Redux store
import { useDispatch } from "react-redux";

// action used to make cart empty
import { emptyCart } from "../pages/E-Commerce/redux-setup/actions";

const PaypalCheckOutButtons = ({
  cartProducts,
  cartTotalPrice,
  enableBodyScroll,
  applied,
}) => {
  // used to redirect user to the page with product details
  const history = useHistory();

  // used to update state of React app (redux)
  const dispatch = useDispatch();

  return (
    // manages loading the JS SDK script
    <PayPalScriptProvider
      options={{
        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
        currency: process.env.REACT_APP_PAYPAL_CURRENCY,
      }}
    >
      {/* used to render UI for PayPal button */}
      <PayPalButtons
        style={{
          layout: "horizontal",
        }}
        disabled={cartProducts.length > 0 ? false : true}
        forceReRender={[cartTotalPrice]}
        createOrder={(data, actions) => {
          return fetch("/.netlify/functions/paypalCheckout", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              cartProducts: cartProducts,
              cartTotalPrice: cartTotalPrice,
              promoCode: applied,
            }),
          })
            .then((response) => {
              if (response.ok) return response.json();
              return response.json().then((json) => Promise.reject(json));
            })
            .catch(({ id }) => {
              return id;
            })
            .catch((e) => {
              console.error(e);
            });
        }}
        onApprove={(data, actions) => {
          return actions.order.capture().then((orderData) => {
            enableBodyScroll();
            dispatch(emptyCart());
            history.push({
              pathname: "/shop/thankyou",
              state: { orderData: orderData },
            });
          });
        }}
        onCancel={() => {
          enableBodyScroll();
          history.push({
            pathname: "/shop/orderCancel",
            state: { orderCancelled: true },
          });
        }}
        onError={(err) => {
          enableBodyScroll();
          console.log(err.message);
          history.push({
            pathname: "/shop/error",
            state: { errorOccurred: true },
          });
        }}
      />
    </PayPalScriptProvider>
  );
};

export default PaypalCheckOutButtons;

import React from "react";

// import icons
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaEnvelope,
  FaPinterest,
} from "react-icons/fa";

// social media icons component
export default function SocialMediaContact() {
  return (
    <div className="h-12 flex justify-center sm:items-center" id="mediaIcons">
      <span
        className="              
              sm: h-8

              lg: p-2 mr-2 
              bg-black text-white 
              hover:bg-white hover:text-black
              transition duration-300 
              ease-in-out rounded-full

              
            "
      >
        <a
          href="https://www.instagram.com/kellykinetix"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram />
        </a>
      </span>
      <span
        className="
              sm: h-8

              p-2 mr-2 
              bg-black text-white 
              hover:bg-white hover:text-black
              transition duration-300 
              ease-in-out rounded-full
            "
      >
        <a
          href="https://www.facebook.com/kellykinetix"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebook />
        </a>
      </span>
      <span
        className="
              sm: h-8

              p-2 mr-2 
              bg-black text-white 
              hover:bg-white hover:text-black
              transition duration-300 
              ease-in-out rounded-full
            "
      >
        <a
          href="https://www.linkedin.com/company/kellykinetics"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedin />
        </a>
      </span>
      <span
        className="
              sm: h-8

              p-2 mr-2 
              bg-black text-white 
              hover:bg-white hover:text-black
              transition duration-300 
              ease-in-out rounded-full
            "
      >
        <a
          href="mailto:info@kellykinetix.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaEnvelope />
        </a>
      </span>
      <span
        className="
              sm: h-8

              p-2 mr-2 
              bg-black text-white 
              hover:bg-white hover:text-black
              transition duration-300 
              ease-in-out rounded-full
            "
      >
        <a
          href="https://www.pinterest.com/KellyKinetix"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaPinterest />
        </a>
      </span>
    </div>
  );
}

import { createStore, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import cartReducer from "./reducers";
import menuReducer from "./menu-reducers";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  whiteList: ["cart"],
  blackList: ["menu"],
};

const rootReducer = combineReducers({
  menu: menuReducer,
  cart: cartReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
const appStore = createStore(persistedReducer, composeWithDevTools());
const persistor = persistStore(appStore);
export { appStore, persistor };

import React from "react";
import styled from "styled-components";

// used to display input of type "number" (increase or decrease quantity of product)
import { QuantityPicker } from "react-qty-picker";

// used to navigate uer through the website
import { useHistory } from "react-router-dom";

// used to extract data from the Redux store state
import { useSelector } from "react-redux";

//  hook that returns a reference to the dispatch function from the Redux store
import { useDispatch } from "react-redux";

// action that passed to dispatch method
import {
  deleteProduct,
  updateShoppingCart,
} from "../pages/E-Commerce/redux-setup/actions";

// action that passed to dispatch method
import { closeMenu } from "../pages/E-Commerce/redux-setup/menu-actions";

import NoImage from "../pages/E-Commerce/images/noImage.jpg";

const CardContainer = styled.div`
  width: 100%;
  border-bottom: 2px solid #f9f9f9;
  height: fit-content;
  display: flex;
  align-items: start;
  column-gap: 5px;
  padding: 15px 0;
`;

const CardPhoto = styled.div`
  width: 30%;
  height: 160px;
  background-image: url(${(props) => props.bg});
  background-size: cover;
  background-position: center;
`;

// container for product title, quantity, price, size, color and delete button
const CardDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  width: 70%;
  overflow: hidden;
  height: 90%;
  padding: 0rem 0 0.6rem 1rem;
`;

// container for title and price
const CardTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const CardTitle = styled.div`
  font-size: 1rem;
  font-weight: 600;
  width: 65%;
  color: #324da1;
  cursor: pointer;
  transition: all linear 0.2s;
  &:hover {
    text-decoration: underline;
    text-decoration-color: #324da1;
    text-underline-offset: 3px;
  }
`;

const CardPrice = styled.div`
  color: #737373;
  font-weight: 600;
  padding: 0.5rem 0;
`;

// container for size, color and quantity updater
const AdditionalDetails = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

// nested container for size and color
const SizeAndColor = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
`;

const ProductProperty = styled.div`
  color: #737373;
  font-size: 0.8rem;
`;

const Quantity = styled.div`
  width: 30%;
  height: 32px;
`;

// container for delete button
const DeleteBtn = styled.button`
  width: 50%;
  background: #324da1;
  border-radius: 6px;
  color: #f9f9f9;
  text-align: center;
  cursor: pointer;
  transition: background 0.2s ease-in;
  &:hover {
    background: #ff7272;
  }
`;

const ProductCard = ({ data }) => {
  // used to redirect user to the page with product details
  const history = useHistory();

  // prop from Redux state
  const promoCode = useSelector((state) => state.cart.promoApplied);

  // used to update state of React app (redux)
  const dispatch = useDispatch();

  const redirectToPageWithDetails = () => {
    dispatch(closeMenu());
    document.body.setAttribute("style", "overflow-y: visible; height: auto;");
    history.push(`/shop/${data.productID}`);
  };

  return (
    <CardContainer>
      <CardPhoto
        bg={data.image !== undefined ? data.image : NoImage}
      ></CardPhoto>
      <CardDetails>
        <CardTitleContainer>
          <CardTitle onClick={redirectToPageWithDetails}>
            {data.productTitle}
          </CardTitle>
          <CardPrice className={promoCode ? "text-line-through" : ""}>
            {" "}
            {(data.price * data.quantity).toFixed(2)}{" "}
          </CardPrice>
          {promoCode && (
            <CardPrice>
              {" "}
              {(data.clientPrice * data.quantity).toFixed(2)}{" "}
            </CardPrice>
          )}
        </CardTitleContainer>
        <AdditionalDetails>
          <SizeAndColor>
            <ProductProperty>
              Size: {data.size ? data.size : "not applicable"}
            </ProductProperty>
            <ProductProperty>
              Color: {data.color ? data.color.text : "not applicable"}
            </ProductProperty>
            {data.frontDesign && (
              <ProductProperty>
                Front Design: {data.frontDesign}
              </ProductProperty>
            )}
            {data.backDesign && (
              <ProductProperty>Back Design: {data.backDesign}</ProductProperty>
            )}
            {data.sleeve && (
              <ProductProperty>Sleeve Design: {data.sleeve}</ProductProperty>
            )}
          </SizeAndColor>
          <Quantity>
            <QuantityPicker
              min={1}
              value={data.quantity}
              width="`15px"
              onChange={(value) =>
                dispatch(updateShoppingCart(data.uniqueID, value))
              }
            />
          </Quantity>
        </AdditionalDetails>
        <DeleteBtn onClick={() => dispatch(deleteProduct(data.uniqueID))}>
          Delete
        </DeleteBtn>
      </CardDetails>
    </CardContainer>
  );
};

export default ProductCard;

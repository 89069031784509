import React from "react";
import styled from "styled-components";
import hash from "object-hash";

const ContainerTitle = styled.div`
  display: flex;
  column-gap: 5%;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 1rem;
`;

const ContainerDesc = styled.div`
  text-align: center;
  width: 90%;
  margin: auto;
  @media screen and (min-width: 768px) {
    width: 60%%;
  }
`;

const DescTitle = styled.p`
  cursor: pointer;
  text-transform: uppercase;
  color: grey;
  font-family: Raleway, sans-serif;
`;

const DescText = styled.p`
  min-height: 10em;
  line-height: 2.5em;
  padding-top: 1.5rem;
  display: none;
`;

// const sizeImages = styled.img`
//   width: 100%;
//   height: 510px;
//   object-fit: cover;
//   object-position: 50% 50%;
//   overflow: hidden;
//   border-radius: 0.25rem;
// `

const AdditionalData = ({ product }) => {
  // const reformatCapital = (options) => {
  //   let print = "";
  //   for (let i = 0; i < options.length; i++) {
  //     print += options[i].charAt(0).toUpperCase() + options[i].slice(1);
  //     if (i !== options.length - 1) print += ", ";
  //   }
  //   return print;
  // };
  const showThisBlock = (evt) => {
    let activeBlocks = document.getElementsByClassName("displayed-desc-block");
    let activeTitles = document.getElementsByClassName("active-desc-title");
    if (activeBlocks.length > 0)
      activeBlocks[0].classList.remove("displayed-desc-block");
    if (activeTitles.length > 0)
      activeTitles[0].classList.remove("active-desc-title");
    evt.target.classList.add("active-desc-title");
    if (evt.target.id === "desc-title")
      document
        .getElementById("desc-block")
        .classList.add("displayed-desc-block");
    else if (evt.target.id === "info-title")
      document
        .getElementById("info-block")
        .classList.add("displayed-desc-block");
    else if (evt.target.id === "review-title")
      document
        .getElementById("review-block")
        .classList.add("displayed-desc-block");
  };

  return (
    <>
      <ContainerTitle>
        <DescTitle
          className="active-desc-title"
          id="desc-title"
          onClick={showThisBlock}
        >
          Material
        </DescTitle>
        <DescTitle id="info-title" onClick={showThisBlock}>
          Size Information
        </DescTitle>
        <DescTitle id="review-title" onClick={showThisBlock}>
          Reviews(0)
        </DescTitle>
      </ContainerTitle>
      <ContainerDesc>
        <ContainerDesc>
          <DescText className="displayed-desc-block" id="desc-block">
            {product.materialInformation}
          </DescText>
          <DescText id="info-block">
            {product.sizeInformation === undefined ||
            (product.sizeInformation.text.length === 0 &&
              product.sizeInformation.image.length === 0)
              ? "No size info"
              : ""}

            {product.sizeInformation !== undefined &&
            product.sizeInformation.text.length > 0
              ? product.sizeInformation.text
              : ""}

            {product.sizeInformation !== undefined &&
            product.sizeInformation.image.length > 0
              ? product.sizeInformation.image.map((img, index) => {
                  return (
                    <img
                      src={img}
                      key={hash(img)}
                      style={{
                        margin: "auto",
                        paddingBottom: "30px",
                        width: "70%",
                        objectFit: "cover",
                        objectPosition: "50% 50%",
                        overflow: "hidden",
                        borderRadius: "0.25rem",
                      }}
                      alt="size information"
                    />
                  );
                })
              : ""}
          </DescText>
          <DescText id="review-block">No Review</DescText>
        </ContainerDesc>
      </ContainerDesc>
    </>
  );
};

export default AdditionalData;

import React from "react";
import styled from "styled-components";

// Helmet component for SEO
import { Helmet } from "react-helmet";

// sections that Conclusion page contains
import { ConsultationSteps } from "./steps";
import { FAQs } from "./faqs";

// import logo for thumbnail image when sharing online
import logo from "../HomePage/full-logo.png";

// consultation background image
import Bg from "./images/ConsultationIcon.jpg";

// components for "Before consultation" section
const BeforeConsultation = styled.div`
  width: 100vw;
  min-height: 70vh;
  display: flex;
  @media only screen and (min-width: 769px) and (max-width: 1280px) {
    flex-direction: column;
    min-height: 40vh;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    min-height: 60vh;
  }
`;
export const BookNowBtn = styled.a`
  position: relative;
  outline: none;
  background: none;
  padding: 0.5rem 2rem;
  margin-top: 10px;
  border: none;
  border-radius: 12px;
  color: #324da1;
  font-size: 1.4rem;
  font-weight: 600;
  z-index: 1;
  text-align: center;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    font-size: 1.3rem;
    padding: 0.5rem 1rem;
  }
  &:hover {
    color: #f3f4f6;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #324da1;
    opacity: 0.9;
    border-radius: 12px;
    transition: transform 0.5s;
    transform-origin: bottom right;
    transform: scale(0);
  }

  &:hover::before {
    transition: transform 0.5s;
    transform-origin: top left;
    transform: scale(1);
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: transparent;
    border-radius: 12px;
    border: 2px solid #035b96;
    transition: transform 0.5s;
    transform-origin: top left;
    transform: scale(1);
  }

  &:hover::after {
    transition: transform 0.5s;
    transform-origin: bottom right;
    transform: scale(0);
  }
`;

const BeforeLeftSide = styled.div`
  width: 50%;
  background-image: url(${Bg});
  background-repeat: no-repeat;
  background-position: 40px 70%;
  background-size: contain;
  @media only screen and (min-width: 769px) and (max-width: 1280px),
    screen and (max-width: 768px) {
    width: 100%;
    background-position: center;
    height: 30vh;
  }
`;

const BeforeRightSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 5rem;
  width: 50%;
  @media only screen and (min-width: 769px) and (max-width: 1280px),
    screen and (max-width: 768px) {
    width: 100%;
    padding: 0 2rem;
    align-items: center;
    text-align: center;
  }
`;

const BeforeTitle = styled.h2`
  width: max-content;
  font-size: 2.5rem;
  color: #324da1;
  line-height: 70px;
  border-bottom: 3px solid #324da1;
  margin-bottom: 5vh;
  @media screen and (max-width: 768px) {
    font-size: 1.6rem;
  }
`;

const BeforeSubtitle = styled.p`
  color: #737373;
  font-size: 1.4rem;
  font-weight: 500;
  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export default function ConsultationPage() {
  return (
    // begin consultation page
    <div className="h-auto flex flex-col">
      {/* begin react-helmet component */}
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="What to know regarding fitness consultations with KellyKinetix. KellyKinetix offers belly dancing and personal trainers in north york, Toronto, GTA and Ontario through online classes."
        />
        <meta
          name="keywords"
          content="discovery call, functional movement screen, start now, faq, faq meaning, faq examples, client testimonials, personal trainers near me, fitness studio, fitness studio toronto, personal trainer, personal training studio, private trainer, personal fitness, studio north, health & fitness, personal training, fitness gym, health fitness, fitness centre, toronto fitness, gym in toronto, downtown toronto gym, personal trainer, personal trainer downtown toronto, fit women, goodlife workout, housefit personal training toronto, personal trainer, trainer, physical trainer, personal trainer at home, female personal trainer, personal training studio"
        />

        <title>Consultation</title>
        {/* social media meta tags */}
        <meta property="og:title" content="KellyKinetix Consultation"></meta>
        <meta
          property="og:url"
          content="https://www.kellykinetix.com/consultation"
        ></meta>
        <meta
          property="og:description"
          content="Begin your consultation now, where we take full body measurements and perform a full body functional movement assessment where we learn about postural and strength imbalances."
        ></meta>
        <meta property="og:locale" content="en_GB"></meta>
        <meta property="og:image" content={"%PUBLIC_URL%/" + logo}></meta>
      </Helmet>
      {/* end react-helmet component */}
      {/* begin consultation process header */}
      <span className="text-xl sm:text-3xl font-bold text-center header-text my-12">
        THE DISCOVERY CALL PROCESS
      </span>
      {/* end consultation process header */}
      <BeforeConsultation>
        <BeforeLeftSide />
        <BeforeRightSide>
          <BeforeTitle> The Discovery Call</BeforeTitle>
          <BeforeSubtitle>
            Before the consultation, we'll schedule a discovery call which can
            last between 15-30 minutes. This is important because it gives us a
            chance to determine whether we are a good fit to work together, and
            allows us to learn enough about you in order to provide with the
            best possible service and outcomes from your training
          </BeforeSubtitle>
          {/*Old link that changed on Feb 20, 2023*/}
          {/*<BookNowBtn*/}
          {/*    href="https://live.vcita.com/site/kellykinetix/online-scheduling?service=abcsb04nvt4uxis9"*/}
          {/*    target="blank"*/}
          {/*>*/}
          <BookNowBtn
            href="https://calendly.com/info-kellykinetix/30mindiscoverycall"
            target="blank"
          >
            Book your discovery call now
          </BookNowBtn>
        </BeforeRightSide>
      </BeforeConsultation>
      <ConsultationSteps />
      <FAQs />
    </div>
  );
}

// files to download
import parq from "../ConsultationPage/files/par-q.pdf";
import waiver from "../ConsultationPage/files/waiver.pdf";
import chart from "../ConsultationPage/files/measurement-chart.pdf";

// each question has "category" property which should match to one in the categories array (defined below)
// "maxHeight" property used to hide/show answer (passed to styled-component and used for css-property)
// "answer" property contains HTML elements which will be read not as part of the string but as HTML code
export const Questions = [
  {
    question: "How do I register for online classes? ",
    answer: `
    <ul style="list-style: disc;">
    <li style=" color: #737373; font-family: 'Poppins',sans-serif; margin-bottom: 25px;">Unless you are registering for a special class package promotion, everyone is required to have a discovery call and consultation first before registering for classes. Book your discovery call <a href="https://calendly.com/info-kellykinetix/30mindiscoverycall" target="_blank" rel="noopener noreferrer" class="faq-link" >Here</a></li>
    <li style=" color: #737373; font-family: 'Poppins',sans-serif; margin-bottom: 25px;">Once we determine what type of regular class package you want to start with during the consultation, we will work through your payment and paperwork while in the consultation appointment (whether that is online or in-person)</li>
    <li style=" color: #737373; font-family: 'Poppins',sans-serif; margin-bottom: 25px;">We will then add you to either the full class schedule or specifically chosen classes via google cal or outlook calendar invite with access to zoom links for each class for the month</li>
    </ul>
    `,
    maxHeight: "0px",
    category: "Online Classes",
  },
  {
    question: "I have not taken a class online before, how does it work? ",
    answer:
      "Once you have registered and received your google cal/outlook invite with zoom link, be sure to add it into your calendar so you get reminders for when class starts. <br /> <br/> Use the zoom link included to enter the classroom at the scheduled class time. <br /> Once you are in you will hear a voice say, “this video is being recorded”. <br />  To gain the best experience it is recommended you keep your video on. Sometimes there are circumstances which are different and if you need to have your camera off that is ok. <br /> <br/> All classes are recorded so students have access to practice after <br />  In the beginning you can unmute yourself for quick chatting before class time (if you arrive early) <br /> Through class keep yourself on mute unless you have a question <br />  For best view, please pin the instructor <br /> Sometimes the instructor will spotlight themselves if they want to make sure everyone sees something up close in detail. <br /> <br/> After class you will receive an email through original class booking with the link to the recording on you tube. <br/>  Class recordings are kept hidden and are only available to students that pay for class. <br />  If you miss a class you still have access to recordings <br/><br/>  Sometimes pictures and video are taken during classes for promotional purposes. We always ask for consent and if anyone doesn't want to be in the picture, we either cover your face on social media or you can choose to turn your camera off during that time",
    maxHeight: "0px",
    category: "Online Classes",
  },
  {
    question: "How long does a discovery call take ?",
    answer:
      "Depending on the individual, a discovery call can last between 15-30 minutes.",
    maxHeight: "0px",
    category: "Discovery Call",
  },
  {
    question: "What should I bring ?",
    answer:
      "Be prepared with a paper and pen/pencil in case you want to take notes. If we are on zoom, being on a laptop, desk top, or ipad (or other similar device is best). Also plan to be in a quiet room with no distractions.",
    maxHeight: "0px",
    category: "Discovery Call",
  },
  {
    question: "How much does a discovery call cost?",
    answer: "Discovery calls are 100% complimentary with no obligations.",
    maxHeight: "0px",
    category: "Discovery Call",
  },
  {
    question: "Do you have regularly scheduled workshops? ",
    answer: `We have monthly workshops in a variety of areas from belly dance, hip hop, mobility & stretching, Fight Strong Fitness, General Functional Fitness and more. To stay up to date about new or returning upcoming workshops, be sure to <a class="faq-link" href="https://kellykinetix.us20.list-manage.com/subscribe?u=f4ee6227092fe6979cd63b499&id=25d15ba1d5" target="_blank" rel="noopener noreferrer" class="joinList"> Join our community mailing list </a> or check out <a class="faq-link" href='https://www.kellykinetix.com/workshops' target='_blank' rel="noopener noreferrer">Here</a><br />`,
    maxHeight: "0px",
    category: "Workshops",
  },
  {
    question: "What is your current class schedule?",
    answer: `Please check our class schedule <a class="faq-link" href="https://www.kellykinetix.com/classes" target="_blank" rel="noopener noreferrer">Here</a> `,
    maxHeight: "0px",
    category: "Online Classes",
  },
  {
    question: "What is the best way for me to contact you?",
    answer: `You can contact us by sending an email to <a class="faq-link" target="_blank" rel="noopener noreferrer" href="mailto:info@kellykinetix.com">Info@kellykinetix.com</a> <br /> OR send a message<a class="faq-link"  target="_blank" href="https://live.vcita.com/site/kellykinetix/leave-details?title=Contact%20Request&message="> Here </a>`,
    maxHeight: "0px",
    category: "General",
  },
  {
    question: "Do you have in person classes and how can I register for them? ",
    answer: `The in-person classes we currently have running are belly dance classes at a studio close to Merton and Mt. Pleasant. See below for our usual registration process. <br/><br/>
      -	Contact us  <a class="faq-link"  target="_blank" rel="noopener noreferrer" href="https://live.vcita.com/site/kellykinetix/action/r1ssrsuz3i4o2698">Here </a> to let us know what class option(s) you want  <br/>
      -	Pay for your package or individual classes <br/>
      -	An invoice will be sent to you <br/> -	Be sure to schedule into your calendar <br/>
      -	Show up to class <br/><br/> Most group classes are currently online. <a class="faq-link" href="https://kellykinetix.us20.list-manage.com/subscribe?u=f4ee6227092fe6979cd63b499&id=25d15ba1d5" target="_blank" rel="noopener noreferrer" class="joinList"> Join the mailing list </a>  to learn about any additional in person classes that may start as well as excellent outdoor and online events, classes and much MORE!  <br/> <br/> <div style="text-align:center;"> </div>  <br/> 
      <b class="titles"> For Online and In-Person Personal Training </b> <br /> 
      <ul style="list-style-type: '- ' ">
        <li style=" color: #737373; font-family: 'Poppins',sans-serif;">To schedule personal training, you need to contact us to set up a time for a discovery call <a class="faq-link"  target="_blank" rel="noopener noreferrer" href="https://live.vcita.com/site/kellykinetix/action/r1ssrsuz3i4o2698">Here </a>  </li>
        <li style=" color: #737373; font-family: 'Poppins',sans-serif;">During your discovery call, we can set up a time for the full consultation if we both decide we are a great fit to work together</li>
        <li style=" color: #737373; font-family: 'Poppins',sans-serif;">During the consultation we will agree upon what the best package is to start with, days and times for training sessions, and all paperwork (Agreement, <a class="faq-link" href=${waiver} rel="noopener noreferrer"  download> Waiver</a>, <a class="faq-link" href=${parq} rel="noopener noreferrer"  download> PAR-Q</a>) as well as the payment will be done while we are together (whether online or in person)</li>
      </ul>
      
      <ul style="list-style: disc;">
        <li style=" color: #737373; font-family: 'Poppins',sans-serif; margin-left: 20px;">We will ensure all sessions are scheduled</li>
        <li style=" color: #737373; font-family: 'Poppins',sans-serif; margin-left: 20px;">If you will be an in-person client, your trainer will ensure to get your address</li>
        <li style=" color: #737373; font-family: 'Poppins',sans-serif; margin-left: 20px;">For online clients, you will receive a zoom link in a google calendar invite. Be sure to put these sessions into your calendar and to show up on time </li>
      </ul>
      `,
    maxHeight: "0px",
    category: "In-person classes",
  },
];

// image imports
import React from "react";
import logo from "../HomePage/full-logo.png";
import withClient from "./with-client.jpg";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaEnvelope,
  FaPinterest,
} from "react-icons/fa";
import { BookNowBtn } from "../ConsultationPage";

// CallToAction component
import CallToAction from "../../CallToAction";

// React-helmet
import { Helmet } from "react-helmet";

// image for thumbnail meta tag
import backdrop from "../../CallToAction/cta-image.jpg";

export const jsonLD = {
  "@context": "https://schema.org/",
  "@type": "LocalBusiness",
  name: "KellyKinetix",
  author: {
    "@type": "Person",
    name: "Kelly Enston",
  },
  description:
    "Take care of your body in fun was, through group belly dancing and body resilience classes. Personal training can also be included, as well as various other forms of exercise (Jiu Jitsu, Kick Boxing), all in the Greater Toronto Area (GTA).",
  areaServed: {
    "@type": "Province",
    name: "Ontario",
  },
  priceRange: "",
  paymentAccepted: "Credit Card",
  hasOfferCatalog: {
    "@type": "OfferCatalog",
    name: "KellyKinetix Services",
    itemListElement: [
      {
        "@type": "OfferCatalog",
        name: "Classes and Training",
        itemListElement: [
          {
            "@type": "Offer",
            itemOffered: {
              "@type": "Service",
              name: "Personal Training",
            },
          },

          {
            "@type": "Offer",
            itemOffered: {
              "@type": "Service",
              name: "Belly Dance",
            },
          },

          {
            "@type": "Offer",
            itemOffered: {
              "@type": "Service",
              name: "Body Resilience and Strength Training Group Class",
            },
          },

          {
            "@type": "Offer",
            itemOffered: {
              "@type": "Service",
              name: "Dance and Nutrition Workshops",
            },
          },
        ],
      },
      {
        "@type": "OfferCatalog",
        name: "One-time Service",
        itemListElement: [
          {
            "@type": "Offer",
            itemOffered: {
              "@type": "Service",
              name: "Consultation",
            },
          },
        ],
      },
    ],
  },
};
export const metaTagConstants = {
  title: "KellyKinetix Home",
  description:
    "Stay fit and active through personal training sessions or group belly dancing and body strengthing classes. Classes are held over zoom currently until COVID-19 regulations allow in-person.",
  locale: "en_GB", // defines language type
  url: "https://www.kellykinetix.com/",
  image: backdrop,
  keywords:
    "gym instruction, training, working out, personal trainer, personal trainer downtown toronto, fit women, goodlife workout, housefit personal training toronto, personal trainer, trainer, rainer, physical trainer, personal trainer at home, female personal trainer, personal training studio, fitness classes, personal trainers near me, fitness studio, fitness studio toronto, personal trainer, personal training studio, private trainer, personal fitness, studio north, health & fitnes, personal training, fitness gym, health fitness, fitness centre, health & fitness, personal training, fitness gym, health fitness, fitness centre, toronto fitness, gym in toronto, downtown toronto gym, in love yourself, love your self, love yourself, my self love, linktree instagram, facebook instagram, instagram and facebook, tiktok instagram, face book face book, belly dance toronto, belly dancing classes toronto, mind body connection, mind body, mind and body, body mind",
};

const CompanyValuesPage = () => {
  // begin about page and header
  return (
    <div
      className="flex flex-col mb-20"
      itemScope
      itemType="https://schema.org/LocalBusiness"
    >
      {/* begin react-helmet component */}
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="KellyKinetix is a company that offers fitness and belly dance classes as well as personal training in Toronto, North York, GTA and Ontario, Canada and remotely through Zoom."
        />
        <meta name="keywords" content={metaTagConstants.keywords} />
        {/* <title>About KellyKinetix</title> */}

        {/* key opengraph meta tags */}
        <meta property="og:title" content={metaTagConstants.title} />
        <meta property="og:url" content={metaTagConstants.url} />
        <meta
          property="og:description"
          content={metaTagConstants.description}
        />
        <meta property="og:locale" content={metaTagConstants.locale} />
        <meta
          property="og:image"
          content={"%PUBLIC_URL%/" + metaTagConstants.image}
        />
        <meta property="og:type" content="website" />

        {/* JSON-LD data for SEO */}
        <script type="application/json+ld">{JSON.stringify(jsonLD)}</script>
      </Helmet>
      {/* end react-helmet component */}

      {/*/!* begin core values section *!/*/}
      <span className="text-xl lg:text-3xl text-center font-bold header-text mb-6 pt-10">
        Core Values - we follow and conduct ourselves with the following
      </span>
      {/* begin side content section */}
      <div className="flex flex-col lg:flex-row justify-center items-center lg:items-start lg:mt-10">
        <div className="w-4/5 lg:w-1/2 lg:my-12 my-8 mx-auto">
          <ol className="content-text lg:pl-40 list-decimal">
            <li className="text-base sm:text-xl py-2">Integrity</li>
            <li className="text-base sm:text-xl py-2">Accountability</li>
            <li className="text-base sm:text-xl py-2">Honest Communication</li>
            <li className="text-base sm:text-xl py-2">
              Developing Meaningful Relationships
            </li>
            <li className="text-base sm:text-xl py-2">Passion</li>
            <li className="text-base sm:text-xl py-2">Fun</li>
          </ol>
        </div>
        {/* end side content section */}
        {/* begin image section */}
        <div className="w-full lg:W-1/2 h-auto block sm:flex items-center">
          <img
            src={withClient}
            alt="working with a client"
            className="rounded w-full lg:w-4/5 block m-auto px-4"
          />
        </div>
      </div>
      {/*/!* end core values section *!/*/}

      {/* Footer component */}
    </div>
    // end about page
  );
};

export default CompanyValuesPage;

import React from "react";
import styled from "styled-components";

// utility for converting HTML strings into React components.
import ReactHtmlParser from "react-html-parser";

// hash function used to hash object and can be used as key
import hash from "object-hash";

// icons for FAQ section
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faCreditCard,
  faTshirt,
} from "@fortawesome/free-solid-svg-icons";

// components for FAQs section
const SectionTitle = styled.h2`
  width: max-content;
  font-size: 2.5rem;
  color: #324da1;
  line-height: 70px;
  border-bottom: 3px solid #324da1;
  margin-bottom: 5vh;
  @media screen and (max-width: 768px) {
    font-size: 1.6rem;
  }
`;

const FAQSection = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5vh;
  padding: 0vh 10vw;
  margin-bottom: 4vh;
  @media only screen and (min-width: 769px) and (max-width: 1280px) {
    padding: 0 4vw;
  }
  @media screen and (max-width: 768px) {
    margin-top: 0vh;
  }
`;

const FAQContainer = styled.div`
  width: 70vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (min-width: 769px) and (max-width: 1280px) {
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 40px;
    column-gap: 10%;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 4vh;
  }
`;

const FAQ = styled.div`
  width: 20vw;
  height: 380px;
  border-radius: 25px;
  background: #f6f6f6;
  overflow: hidden;
  box-shadow: 4px 4px 8px -4px gray;
  transition: 0.4s ease-out;
  cursor: pointer;
  &:hover {
    transform: translate(-10px, -20px);
  }
  @media only screen and (min-width: 769px) and (max-width: 1280px) {
    width: 45%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 4vh;
    &:hover {
      transform: none;
    }
  }
`;

const FAQHeader = styled.div`
  height: 20%;
  background: #324da1;
  color: #f6f6f6;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FAQText = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  height: 80%;
  @media screen and (max-width: 768px) {
    height: 100%;
  }
`;

const FAQTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30%;
  @media only screen and (min-width: 769px) and (max-width: 1280px) {
    font-size: 1.2rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
    height: 25%;
  }
`;

const FAQSubtitle = styled.div`
  color: #737373;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
`;

export const FAQs = () => {
  const faqs = [
    {
      icon: faClock,
      title: "How long does a discovery call take ?",
      subtitle:
        "Depending on the individual, a discovery call can last between 15-30 minutes.",
    },
    {
      icon: faTshirt,
      title: "What should I bring ?",
      subtitle:
        "Be prepared with a paper and pen/pencil in case you want to take notes. If we are on zoom, being on a laptop, desk top, or Ipad (or other similar device is best). Also plan to be in a quiet room with no distractions.",
    },
    {
      icon: faCreditCard,
      title: "How much does a discovery call cost?",
      subtitle:
        "Discovery calls are <br/>100% complimentary<br/> with no obligations.",
    },
  ];

  return (
    <FAQSection>
      <SectionTitle> Frequently asked questions </SectionTitle>
      <FAQContainer>
        {faqs.map((faq) => (
          <FAQ key={hash(faq)}>
            <FAQHeader>
              <FontAwesomeIcon icon={faq.icon} />
            </FAQHeader>
            <FAQText>
              <FAQTitle> {faq.title} </FAQTitle>
              <FAQSubtitle> {ReactHtmlParser(faq.subtitle)}</FAQSubtitle>
            </FAQText>
          </FAQ>
        ))}
      </FAQContainer>
    </FAQSection>
  );
};

import React from "react";

// react-helmet
import { Helmet } from "react-helmet";

// Testimonial component
import { TestimonialSectionComponent } from "../../Testimonial";

// testimonial data
import { testimonials } from "../../../data";

// images
import greenery from "./images/greenery.jpg";
import deadlift from "./images/deadlift.jpg";
import machine from "./images/machine.jpg";
import grinning from "./images/grinning.jpg";
import client from "./images/client.jpg";

const GroupFitnessPage = () => {
  const { fitnessTestimonialData } = testimonials;
  // begin fitness page
  return (
    <div className="h-auto flex flex-col">
      {/* begin react-helmet component */}
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="About the fitness services offered by KellyKinetix which include mobility and strength training. KellyKinetix offers belly dancing and personal trainers in north york, Toronto, GTA and Ontario through online classes."
        />

        {/* meta tags for social media sharing */}
        <meta property="og:title" content="KellyKinetix Fitness" />
        <meta
          property="og:url"
          content="https://www.kellykinetix.com/fitness"
        />
        <meta
          name="keywords"
          content="gym instruction, training, working out, fitness classes, exercise classes near me, group fitness classes near me, workout classes, exercise classes, group workout classes near me, exercise groups near me, zumba exercise for beginners, exercise classes near me, group fitness classes near me, exercise classes, group workout classes near me, workout classes, zumba dance, circuit training, fitness classes near me, workout classes near me, zumba, cpr, personal trainer, personal trainer downtown toronto, fit women, goodlife workout, housefit personal training toronto, personal trainer, trainer, physical trainer, personal trainer at home, female personal trainer, personal training studio, fitness classes, personal trainers near me, fitness studio, fitness studio toronto, personal trainer, personal training studio, private trainer, personal fitness, studio north, health & fitness, personal training, fitness gym, health fitness, fitness centre, toronto fitness, gym in toronto, downtown toronto gym, in love yourself, love your self, love yourself, my self love, linktree instagram, facebook instagram, instagram and facebook, tiktok instagram, face book face book, mind body connection, mind body, mind and body, body mind, cardiovascular fitness, benefits of physical activity, benefits of physical fitness, 10 benefits of physical activity, workout benefits, cardio vascular fitness, paul chek, smart goals examples, smart goals acronym, client testimonials, mobility, mobility plus, mobility, resilience, resiliency, resilience examples, mental resilience, prestige awards, corporate livewire prestige awards, prestige trophies"
        />

        <meta
          property="og:description"
          content="Reserve a consultation to book your personal training session. Also offer group fitness classes and customized programming to get everyone to reach their goals."
        />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:image" content={"%PUBLIC_URL%/" + deadlift} />
        <meta property="og:type" content="website" />

        <title>IN PERSON/ONLINE GROUP FITNESS</title>
      </Helmet>
      {/* end react-helmet component */}
      {/* begin fitness journey content section */}
      <span className="text-3xl font-bold text-center my-12 header-text">
        IN PERSON/ONLINE GROUP FITNESS
      </span>
      <div className="flex flex-col sm:flex-row sm:justify-between items-center mb-12">
        {/* begin image section */}
        <div className="w-full sm:w-1/2 h-60 sm:h-auto flex items-center mb-12 sm:mb-0 px-8 sm:pl-32">
          <img
            src={grinning}
            alt="Grinning in front of a rack of weights lying down"
            className="rounded"
          />
        </div>
        {/* end image section */}
        {/* begin side content section */}
        <div className="w-full sm:w-1/2 h-auto flex flex-col">
          <p className="sm:pl-12 px-8 sm:pr-32 text-base sm:text-xl my-4 content-text">
            Another option to consider in your fitness journey is attending
            group fitness classes with a personal trainer. There are some good
            reasons to consider group training, including:
          </p>
          <ul className="content-text px-8 sm:pl-12 text-base sm:text-xl list-disc">
            <li className="text-base sm:text-xl text-start py-2">
              To get the benefits of personal training at a more reasonable
              price
            </li>
            <li className="text-base sm:text-xl text-start py-2">
              For some, group classes are more motivating than working 1-on-1
            </li>
            <li className="text-base sm:text-xl text-start py-2">
              Staying accountable with the support of the instructor and
              classmates
            </li>
            <li className="text-base sm:text-xl text-start py-2">
              It's a very social and rewarding experience
            </li>
          </ul>
        </div>
        {/* end side content section */}
      </div>
      <p className="px-8 sm:px-32 text-base sm:text-xl pb-8 mb-12 content-text">
        Learn about{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="/customizedProgram"
          className="content-link"
        >
          Customized Programming and SMART Goals
        </a>
        .
      </p>
      {/* end group training section */}
    </div>
  );
  // end fitness page
};

export default GroupFitnessPage;

import React, { useState } from "react";
import styled from "styled-components";

// used to extract data from the Redux store state
import { useSelector } from "react-redux";

//  hook that returns a reference to the dispatch function from the Redux store
import { useDispatch } from "react-redux";

import { closeMenu } from "../pages/E-Commerce/redux-setup/menu-actions";
import {
  updateCartWithClientPrice,
  turnOffPromo,
  turnOnPromo,
} from "../pages/E-Commerce/redux-setup/actions";

// icon for arrow at header section, apply promo code button
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { AiFillTags } from "react-icons/ai";

// component used to show product card
import ProductCard from "./cart-product";

// component used to load PayPal button
import PaypalCheckOutButtons from "./paypalCheckOutButtons";

// hash function used to hash object and can be used as key
import hash from "object-hash";

const CartContainer = styled.section`
  width: 30vw;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  background: white;
  box-shadow: -4px 0px 8px -2px gray;
  z-index: 10;
  transition: transform 0.3s linear;
  transform: translateX(${(props) => props.translateLevel});
  @media only screen and (min-width: 769px) and (max-width: 1280px) {
    width: 60vw;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
  }
`;

const CartHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10%;
  width: 100%;
  height: 15%;
  background: #324da1;
  color: #ff4500;
  font-size: 1.4rem;
`;

const CartHeaderTitle = styled.h3`
  text-transform: capitalize;
  color: #f9f9f9;
  font-size: 1.4rem;
  font-weight: 700;
  padding-left: 30%;
`;

const CartProducts = styled.div`
  height: 62%;
  overflow-y: scroll;
  padding: 0 1rem 20% 1rem;
`;

const EmptyText = styled.p`
  margin-top: 2rem;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  color: #737373;
`;

const CartFooter = styled.div`
  position: absolute;
  bottom: 0;
  height: 240px;
  width: 100%;
  padding: 0 1rem 1rem 1rem;
  border-top: 2px solid #737373;
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PromoCode = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  margin-top: 15px;
  margin-bottom: 10px;
`;

const PromoCodeInput = styled.input`
  outline: none;
  border: 1px solid #737373;
  border-radius: 4px;
  padding: 0rem 1rem;
  height: 100%;
`;

const PromoCodeBtn = styled.button`
  background: ${(props) => props.bgColor};
  border-radius: 6px;
  color: #f9f9f9;
  padding: 0.4rem 1.2rem;
  transition: background 0.2s ease-in;
`;

const Subtotal = styled.div`
  display: flex;
  justify-content: space-between;
  width: 85%;
  font-size: 1rem;
  font-weight: 500;
  color: #737373;
`;
const Total = styled.div`
  display: flex;
  justify-content: space-between;
  width: 85%;
  font-size: 1rem;
  font-weight: 600;
  color: #737373;
`;

const SubtotalTitle = styled.p`
  text-transform: capitalize;
`;
const CouponTitle = styled.p`
  text-transform: capitalize;
  display: flex;
  align-items: center;
`;
const Coupon = styled.p`
  text-transform: capitalize;
  font-size: 0.8rem;
  margin-bottom: 0;
`;

const SubtotalPrice = styled.p``;

const CheckoutBtn = styled.button`
  width: 90%;
  padding: 0.5rem;
`;

const ProceedToCheckoutBtn = styled.div`
  width: 100%;
  background: #324da1;
  color: #f9f9f9;
  padding: 0.4rem 0;
  transition: background 0.2s ease-in;
  border-radius: 6px;
  &:hover {
    background: #4bb543;
  }
`;

const ShoppingCart = () => {
  const dispatch = useDispatch();
  const [promoCode, setPromoCode] = useState("");
  const [nonValidPromo, setNonValidPromo] = useState(false);
  const [renderPaypal, setRenderPaypal] = useState(false);
  // props from Redux state
  const cartProducts = useSelector((state) => state.cart.products);
  const cartTotalPrice = useSelector((state) => state.cart.totalPrice);
  const cartClientTotalPrice = useSelector(
    (state) => state.cart.totalClientPrice
  );
  const menuState = useSelector((state) => state.menu.menuState);
  const promoApplied = useSelector((state) => state.cart.promoApplied);

  const HST = 0.13;
  const SHIPPING = 5;
  const enableBodyScroll = () => {
    setPromoCode("");
    setNonValidPromo(false);
    setRenderPaypal(false);
    dispatch(closeMenu());
    dispatch(turnOffPromo());
    document.body.setAttribute("style", "overflow-y: visible; height: auto;");
  };

  const applyPromoCode = () => {
    if (promoCode === process.env.REACT_APP_PROMO_CODE) {
      dispatch(updateCartWithClientPrice());
      setNonValidPromo(false);
      dispatch(turnOnPromo());
    } else setNonValidPromo(true);
  };

  return (
    <CartContainer translateLevel={menuState ? "0%" : "110%"}>
      <CartHeader>
        <FontAwesomeIcon
          icon={faChevronCircleRight}
          onClick={enableBodyScroll}
          style={{ cursor: "pointer" }}
        />
        <CartHeaderTitle> your cart </CartHeaderTitle>
      </CartHeader>
      {cartProducts.length === 0 && <EmptyText>Cart is Empty</EmptyText>}
      <CartProducts id="#cartProducts">
        {cartProducts.map((product) => (
          <ProductCard key={hash(product.productTitle)} data={product} />
        ))}
      </CartProducts>
      {cartProducts.length > 0 && (
        <CartFooter>
          <PromoCode>
            {!promoApplied && (
              <PromoCodeInput
                type="text"
                placeholder="Promo Code"
                name="promoCode"
                value={promoCode}
                disabled={promoApplied}
                onChange={(event) => setPromoCode(event.target.value)}
              />
            )}
            {!promoApplied && (
              <PromoCodeBtn
                onClick={applyPromoCode}
                bgColor={promoApplied ? "black" : "#324da1"}
              >
                Apply
              </PromoCodeBtn>
            )}
          </PromoCode>
          <p> {nonValidPromo && "Promo Code is not valid"}</p>
          {promoApplied && (
            <Subtotal>
              <CouponTitle>
                Discount <AiFillTags />
                <Coupon>{process.env.REACT_APP_PROMO_CODE}</Coupon>
              </CouponTitle>
              <SubtotalPrice>
                {" "}
                -${(cartTotalPrice - cartClientTotalPrice)?.toFixed(2)}{" "}
              </SubtotalPrice>
            </Subtotal>
          )}
          {promoApplied && (
            <Subtotal>
              <SubtotalTitle>Subtotal</SubtotalTitle>
              <SubtotalPrice>
                {" "}
                ${cartClientTotalPrice?.toFixed(2)}{" "}
              </SubtotalPrice>
            </Subtotal>
          )}
          {!promoApplied && (
            <Subtotal>
              <SubtotalTitle>Subtotal</SubtotalTitle>
              <SubtotalPrice> ${cartTotalPrice?.toFixed(2)} </SubtotalPrice>
            </Subtotal>
          )}
          <Subtotal>
            <SubtotalTitle>Shipping</SubtotalTitle>
            <SubtotalPrice>${SHIPPING.toFixed(2)}</SubtotalPrice>
          </Subtotal>
          <Subtotal>
            <SubtotalTitle>Taxes</SubtotalTitle>
            <SubtotalPrice>
              {" "}
              $
              {promoApplied
                ? (HST * (cartClientTotalPrice + SHIPPING))?.toFixed(2)
                : (HST * (cartTotalPrice + SHIPPING))?.toFixed(2)}{" "}
            </SubtotalPrice>
          </Subtotal>
          <Total>
            <SubtotalTitle>Total</SubtotalTitle>
            <SubtotalPrice>
              {" "}
              $
              {promoApplied
                ? ((1 + HST) * (cartClientTotalPrice + SHIPPING))?.toFixed(2)
                : ((1 + HST) * (cartTotalPrice + SHIPPING))?.toFixed(2)}{" "}
            </SubtotalPrice>
          </Total>
          <CheckoutBtn>
            {renderPaypal ? (
              <PaypalCheckOutButtons
                cartTotalPrice={
                  promoApplied ? cartClientTotalPrice : cartTotalPrice
                }
                cartProducts={cartProducts}
                enableBodyScroll={enableBodyScroll}
                applied={promoApplied}
              />
            ) : (
              <ProceedToCheckoutBtn onClick={() => setRenderPaypal(true)}>
                Proceed to checkout
              </ProceedToCheckoutBtn>
            )}
          </CheckoutBtn>
        </CartFooter>
      )}
    </CartContainer>
  );
};

export default ShoppingCart;

const fetchImages = (merch2Data) => {
  const getImages = (folderContext) => {
    function importAll(context) {
      let images = {};
      context
        .keys()
        .map((item, index) => (images[item.replace("./", "")] = context(item)));
      return images;
    }

    const images = importAll(folderContext);

    return Object.values(images);
  };

  /*The arguments passed to require.context must be literals. 
          ref: https://webpack.js.org/guides/dependency-management/ */

  merch2Data[1 - 1].images = getImages(
    require.context(
      "../../../../data/ECPPhotos/Kelly Kinetix Womens Sweat Resistant T-Shirt/",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  merch2Data[2 - 1].images = getImages(
    require.context(
      "../../../../data/ECPPhotos/Kelly Kinetixs Mens Sweat Resistant T-Shirt_/",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  merch2Data[3 - 1].images = getImages(
    require.context(
      "../../../../data/ECPPhotos/Kelly Kinetix Sweat Resistant Tank Top_/",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  merch2Data[4 - 1].images = getImages(
    require.context(
      "../../../../data/ECPPhotos/Kelly Kinetix Womens Loungewear Tank/",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  merch2Data[5 - 1].images = getImages(
    require.context(
      "../../../../data/ECPPhotos/KellyKinetix Shorts/",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  merch2Data[6 - 1].images = getImages(
    require.context(
      "../../../../data/ECPPhotos/Kellykietix Metal Waterbottle/",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  merch2Data[7 - 1].images = getImages(
    require.context(
      "../../../../data/ECPPhotos/Kelly Kinetix Womens Lounge Joggers_/",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  merch2Data[8 - 1].images = getImages(
    require.context(
      "../../../../data/ECPPhotos/Kelly Kinetix Sweat Resistant Joggers/",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  merch2Data[9 - 1].images = getImages(
    require.context(
      "../../../../data/ECPPhotos/Kelly Kinetix Lounge Zip Up sweater_/",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  merch2Data[10 - 1].images = getImages(
    require.context(
      "../../../../data/ECPPhotos/KellyKinetix Face Mask/",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  merch2Data[11 - 1].images = getImages(
    require.context(
      "../../../../data/ECPPhotos/KellyKinetix Stop Watch_/",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
};
export default fetchImages;

import React from "react";
import styled from "styled-components";

// hash function used to hash object and can be used as key
import hash from "object-hash";

// icons for social media links
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faPinterest,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

const SocialLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 0 1rem;
  width: 80%;
  margin-top: 60px;
`;

const SocialLink = styled.div`
  width: 50px;
  height: 50px;
  background: #324da1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LinkIcon = styled.p`
  color: #f9f9f9;
  font-size: 1.4rem;
  margin: 0 !important;
  padding-top: 5px;
`;

const SocialLinks = () => {
  const socialLinks = [
    {
      path: "https://www.instagram.com/kellykinetix",
      icon: faInstagram,
    },
    {
      path: "https://www.facebook.com/kellykinetix",
      icon: faFacebook,
    },
    {
      path: "https://www.linkedin.com/company/kellykinetics",
      icon: faLinkedin,
    },
    {
      path: "https://www.pinterest.com/KellyKinetix",
      icon: faPinterest,
    },
  ];

  return (
    <SocialLinksContainer>
      {socialLinks.map((socialLink) => (
        <SocialLink key={hash(socialLink)} bgColor={socialLink.bgColor}>
          <a href={socialLink.path}>
            <LinkIcon>
              <FontAwesomeIcon icon={socialLink.icon} />
            </LinkIcon>
          </a>
        </SocialLink>
      ))}
    </SocialLinksContainer>
  );
};

export default SocialLinks;

import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Logo from "../images/kk-logo.png";

// hamburger menu icon - blue/white color
import bars from "../images/align-left.svg";
import whiteBars from "../images/align-left white.svg";

// icon for shopping cart section
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";

//  hook that returns a reference to the dispatch function from the Redux store
import { useDispatch } from "react-redux";

import { openMenu } from "../../pages/E-Commerce/redux-setup/menu-actions";

const NavContainer = styled.nav`
  width: 100vw;
  height: 100px;
  background: #f9f9f9;
  padding: 0 5vw 20px 5vw;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

/* styles for navigation bar when it is closed */
// hamburger menu icon
const Bars = styled.img`
  height: 40%;
  z-index: 7;
`;

const CompanyTitle = styled.span`
  width: 54%;
  margin-top: 4px;
`;

const ShoppingCartIcon = styled.div`
  font-size: 0.9rem;
  width: 32px;
  height: 32px;
  padding-right: 2px;
  background: #324da1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f9f9f9;
  cursor: pointer;
  transition: background ease-out 0.2s;
  &:hover {
    background: #ff4500;
  }
`;

const VisibleMenu = ({ openMenuHandler, openMenuProp }) => {
  const dispatch = useDispatch();
  const showCart = () => {
    dispatch(openMenu());
    document.body.setAttribute("style", "overflow-y: hidden; height: 100%;");
  };

  return (
    <NavContainer>
      <Bars
        alt="Bars image"
        src={openMenuProp ? whiteBars : bars}
        onClick={() => openMenuHandler()}
      />
      <CompanyTitle>
        <Link to="/">
          <img alt="logo" src={Logo} />
        </Link>
      </CompanyTitle>
      <ShoppingCartIcon onClick={showCart}>
        <FontAwesomeIcon icon={faShoppingCart} />
      </ShoppingCartIcon>
    </NavContainer>
  );
};

export default VisibleMenu;

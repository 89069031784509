import React from "react";

// react-helmet
import { Helmet } from "react-helmet";

// Testimonial component
import { TestimonialSectionComponent } from "../../Testimonial";

// testimonial data
import { testimonials } from "../../../data";

// images
import greenery from "./images/greenery.jpg";
import deadlift from "./images/deadlift.jpg";
import machine from "./images/machine.jpg";
import grinning from "./images/grinning.jpg";
import client from "./images/client.jpg";

const FitnessPage = () => {
  const { fitnessTestimonialData } = testimonials;
  // begin fitness page
  return (
    <div className="h-auto flex flex-col">
      {/* begin react-helmet component */}
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="About the fitness services offered by KellyKinetix which include mobility and strength training. KellyKinetix offers belly dancing and personal trainers in north york, Toronto, GTA and Ontario through online classes."
        />

        {/* meta tags for social media sharing */}
        <meta property="og:title" content="KellyKinetix Fitness" />
        <meta
          property="og:url"
          content="https://www.kellykinetix.com/fitness"
        />
        <meta
          name="keywords"
          content="gym instruction, training, working out, fitness classes, exercise classes near me, group fitness classes near me, workout classes, exercise classes, group workout classes near me, exercise groups near me, zumba exercise for beginners, exercise classes near me, group fitness classes near me, exercise classes, group workout classes near me, workout classes, zumba dance, circuit training, fitness classes near me, workout classes near me, zumba, cpr, personal trainer, personal trainer downtown toronto, fit women, goodlife workout, housefit personal training toronto, personal trainer, trainer, physical trainer, personal trainer at home, female personal trainer, personal training studio, fitness classes, personal trainers near me, fitness studio, fitness studio toronto, personal trainer, personal training studio, private trainer, personal fitness, studio north, health & fitness, personal training, fitness gym, health fitness, fitness centre, toronto fitness, gym in toronto, downtown toronto gym, in love yourself, love your self, love yourself, my self love, linktree instagram, facebook instagram, instagram and facebook, tiktok instagram, face book face book, mind body connection, mind body, mind and body, body mind, cardiovascular fitness, benefits of physical activity, benefits of physical fitness, 10 benefits of physical activity, workout benefits, cardio vascular fitness, paul chek, smart goals examples, smart goals acronym, client testimonials, mobility, mobility plus, mobility, resilience, resiliency, resilience examples, mental resilience, prestige awards, corporate livewire prestige awards, prestige trophies"
        />

        <meta
          property="og:description"
          content="Reserve a consultation to book your personal training session. Also offer group fitness classes and customized programming to get everyone to reach their goals."
        />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:image" content={"%PUBLIC_URL%/" + deadlift} />
        <meta property="og:type" content="website" />

        <title>Fitness</title>
      </Helmet>
      {/* end react-helmet component */}
      {/* begin fitness journey content section */}
      <span className="text-3xl font-bold text-center my-12 header-text">
        KELLY'S FITNESS JOURNEY
      </span>

      <div className="flex flex-col sm:flex-row sm:justify-between items-center mb-12">
        {/* begin image section */}
        <div className="w-full sm:w-1/2 h-60 sm:h-auto flex items-center mb-12 sm:mb-0 px-8 sm:pl-32">
          <img
            src={greenery}
            alt="stretching in front of some greenery"
            className="rounded"
          />
        </div>
        {/* end image section */}
        {/* begin side content section */}
        <div className="w-full sm:w-1/2 h-auto flex flex-col">
          <span className="text-xl sm:text-2xl self-center sm:self-end px-8 sm:pr-32 font-bold header-text mb-6 pt-6">
            THE BEGINNING
          </span>
          <p className="sm:pl-12 px-8 sm:pr-32 text-base sm:text-xl my-4 content-text">
            I’ve been active in dance since the age of 5, and have studied many
            dance forms. I have also, off-and-on since childhood, been involved
            in different sports ranging from track and field, to dragon boat
            racing, ultimate frisbee and later on, combat sports like Muay Thai
            and boxing.
          </p>
          <p className="sm:pl-12 px-8 sm:pr-32 text-base sm:text-xl my-4 content-text">
            While pursuing my B.A., I recognized the importance of staying
            active but did not understand the importance of strength training.
            This mindset followed me through my career and at 29, I decided to
            go back to school to become a registered nurse. I felt a pull to do
            something more challenging and rewarding than being a medical
            secretary and since I've always loved science and helping people, I
            felt being an RN would be a great natural progression in my career.
            However, after having done clinical placements in different
            environments, I realized I did not want to work in hospitals and I
            wanted nothing to do with night shifts. This was the first time I
            realized the impact a career of this nature would have on my health.
          </p>
        </div>
        {/* end side content section */}
      </div>
      {/* end fitness journey content section */}
      {/* begin personal training content section */}
      <div className="flex justify-between items-center mb-12">
        {/* begin side content section */}
        <div className="w-full sm:w-1/2 h-auto flex flex-col">
          <span className="text-xl sm:text-2xl self-center sm:self-start px-8 sm:pl-32 font-bold header-text mb-6 pt-6">
            PERSONAL TRAINING/HEALTH
          </span>
          <p className="sm:pr-12 px-8 sm:pl-32 text-base sm:text-xl my-4 content-text">
            Upon finishing my Bachelor of Science in Nursing, I gained 20lbs and
            finally decided to get a personal trainer. At the same time, I also
            started to train others as a personal trainer! This was the best
            thing I ever did! Within 2-3 months I phased out all my nursing
            hours and focused solely on training clients. Why? Because I
            realized that personal training is a better avenue to health
            promotion and disease prevention.
          </p>
          <p className="px-8 sm:pr-12 sm:pl-32 text-base sm:text-xl my-4 content-text">
            As a personal trainer, I help people with their health in a
            motivating and positive atmosphere. The skills and knowledge I have
            learned to become a Registered Nurse coupled with the skills and
            knowledge learned as a Personal Trainer work hand in hand. With
            personal training I see peoples improvements and life changing
            results on a regular basis. It is rewarding to know that I have
            helped someone learn how they can keep themselves as healthy and
            strong both physically and mentally for as long as possible.
          </p>
          <p className="px-8 sm:pr-12 sm:pl-32 text-base sm:text-xl my-4 content-text">
            In my early thirties it didn’t matter how much dancing and running I
            did, I wasn’t losing weight, because I wasn’t building muscle. I
            later learned how to correct posture, correct muscle imbalances, and
            create better movement, mobility and flexibility. Because of my
            diverse experience and skills, I have a new direction for my
            business.
          </p>
          <p className="px-8 sm:pr-12 sm:pl-32 text-base sm:text-xl my-4 content-text">
            Learn about the{" "}
            <a
              href="/healthBenefits"
              target="_blank"
              rel="noopener noreferrer"
              className="content-link"
            >
              Health Benefits of Fitness
            </a>
          </p>
        </div>
        {/* end side content section */}
        {/* begin image section */}
        <div className="w-1/2 h-auto hidden sm:flex items-center pr-32">
          <img src={deadlift} alt="performing a deadlift" className="rounded" />
        </div>
        {/* end image section */}
      </div>
      {/* end personal training content section */}
      {/* begin testimonial section */}
      <TestimonialSectionComponent
        title="FITNESS CLIENT TESTIMONIALS"
        testimonialData={fitnessTestimonialData}
        backgroundImage={deadlift}
      />
      {/* end testimonial section */}
    </div>
  );
  // end fitness page
};

export default FitnessPage;

export const addNewProduct = (newProduct) => {
  return {
    type: "ADD_TO_SHOPPING_CART",
    payload: newProduct,
  };
};

export const updateShoppingCart = (uniqueID, newQtyValue) => {
  return {
    type: "UPDATE_SHOPPING_CART",
    payload: { uniqueID: uniqueID, newQtyValue: newQtyValue },
  };
};

export const deleteProduct = (productToDelete) => {
  return {
    type: "DELETE_FROM_SHOPPING_CART",
    payload: productToDelete,
  };
};

export const turnOnPromo = () => {
  return {
    type: "TURN_ON_PROMO",
    payload: true,
  };
};

export const turnOffPromo = () => {
  return {
    type: "TURN_OFF_PROMO",
    payload: false,
  };
};

export const updateCartWithClientPrice = () => {
  return {
    type: "UPDATE_CART_WITH_CLIENT_PRICE",
    payload: false,
  };
};

export const emptyCart = () => {
  return {
    type: "EMPTY_CART",
    payload: false,
  };
};

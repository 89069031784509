import React, { useReducer, useEffect } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

// hash function used to hash object and can be used as key
import hash from "object-hash";

// importing other components
import ProductCard from "./ProductCard";

//products data (array of objects)
import { merch2Data as merchData } from "../../../../data/merch2";

// used for "Sort" dropdown
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

// used for the search bar
import { TextField } from "@mui/material";

import fetchImages from "../product-detailed-page/FetchImages";

const ProductsSection = styled.div`
  width: 100vw;
  margin-top: 20px;
  padding: 0 8vw;
  @media screen and (max-width: 768px) {
    padding: 0 4vw;
  }
`;

const ContainerTop = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SortContainer = styled.div`
  width: 12vw;
  height: 45%;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    width: 30vw;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  color: #e6e6e6;
  gap: 10px;
  width: 30vw;
  padding-bottom: 4px;
  border-bottom: 2px solid #e6e6e6;
  @media screen and (max-width: 768px) {
    width: 50vw;
  }
`;

const ProductsContainer = styled.div`
  margin: 60px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 3%;
  row-gap: 40px;
  @media screen and (max-width: 768px) {
    column-gap: 6%;
    margin: 20px 0;
  }
`;

const ShopPageMain = () => {
  useEffect(() => {
    // const merchListData= await fetch(`http://localhost:5050/merches`)
    //     .then(res => res.json())
    //     .then(merchList => {
    //       setProducts(merchList.data);
    //       console.log(merchList.data);
    //     })
    //     .catch(err => {
    //       console.log(err);
    //     });
  }, []);

  useEffect(() => {
    fetchImages(merchData);
    dispatch({ type: "default" });
  }, []);

  const reducer = (state, action) => {
    switch (action.type) {
      case "sort":
        return {
          ...state,
          sortee: action.event.value,
          merchData: searcher(
            state.searchee,
            sorter(action.event.value, merchData)
          ),
        };
      case "search":
        return {
          ...state,
          searchee: action.event.target.value,
          merchData: sorter(
            state.sortee,
            searcher(action.event.target.value, merchData)
          ),
        };
      default:
        return {
          ...state,
        };
    }
  };

  // used to sort array
  // 3 options: by name, expensive first, cheap first
  const sorter = (value, merchData) => {
    switch (value) {
      case "Price: Low to High":
        merchData.sort((x, y) => (x.publicPrice > y.publicPrice ? 1 : -1));
        break;
      case "Price: High to Low":
        merchData.sort((x, y) => (x.publicPrice < y.publicPrice ? 1 : -1));
        break;
      case "Name":
        merchData.sort((x, y) => x.title.localeCompare(y.title));
        break;
      default: {
      }
    }

    return merchData;
  };

  // used to filter array with merch data (only items that include searching value are left)
  const searcher = (value, merchData) => {
    return merchData.filter(
      (merch) =>
        merch.title.toLowerCase().indexOf(value.trim().toLowerCase()) >= 0
    );
  };

  const [state, dispatch] = useReducer(reducer, {
    merchData: merchData,
    sortee: "Default",
    searchee: "",
  });

  return (
    <ProductsSection>
      <ContainerTop>
        <SortContainer>
          <Dropdown
            className="dropDown"
            options={["Price: Low to High", "Price: High to Low", "Name"]}
            onChange={(e) => {
              dispatch({ type: "sort", event: e });
            }}
            value={"Default"}
          />
        </SortContainer>
        <SearchContainer>
          <FontAwesomeIcon icon={faSearch} />
          {/*Search*/}
          <TextField
            fullWidth
            onChange={(e) => {
              dispatch({ type: "search", event: e });
            }}
            id="outlined-basic"
            label="Search"
            variant="outlined"
          />
        </SearchContainer>
      </ContainerTop>
      {/* iterating through the array of products and displaying card for each product*/}
      <ProductsContainer>
        {state.merchData.map((product) => (
          <ProductCard data={product} key={hash(product)} />
        ))}
      </ProductsContainer>
    </ProductsSection>
  );
};

export default ShopPageMain;

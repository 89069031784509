import React from "react";

import { Helmet } from "react-helmet";

// json-LD import
import { jsonLD } from "../HomePage/index";
import { metaTagConstants } from "../HomePage/index";

// import social media thumbnail image for sharing
import spreadArms from "../DancePage/images/arms-up.jpg";

const InternalMissionPage = () => {
  // dance meta tags
  const tags = {
    ...metaTagConstants,
    title: "Internal Mission",
    url: "https://www.kellykinetix.com/internalMission",
    description:
      "Improve your health through dance classes. Work on weight management, balance and flexibility.",
    image: spreadArms,
  };

  // begin internship program page
  return (
    <div className="h-auto flex flex-col">
      {/* begin react-helmet component */}
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Dance classes and training offered by KellyKinetix which focus on belly dance in solo or group settings. KellyKinetix offers belly dancing and personal trainers in north york, Toronto, GTA and Ontario through online classes."
        />
        <meta
          name="keywords"
          content="belly dance toronto, belly dancing classes toronto, mind body connection, mind body, mind and body, body mind, meditation, meditation music, sleep music, mindfulness meditation, guided meditation, mindfulness meditation, mindfulness, dance lessons, dance classes near me, dance lessons near me, dance schools near me, belly dance classes near me, benefits of dancing, benefits of dance, cardiovascular fitness, benefits of physical activity, benefits of physical fitness, 10 benefits of physical activity, workout benefits, cardio vascular fitness, resilience, resiliency, resilience examples, mental resilience, prestige awards, corporate livewire prestige awards, prestige trophies, fitness classes, exercise classes near me, group fitness classes near me, workout classes, exercise classes, group workout classes near me, exercise groups near me, zumba exercise for beginners, exercise classes near me, group fitness classes near me, exercise classes, group workout classes near me, workout classes, zumba dance"
        />

        <title>Internal Mission</title>

        {/* adding social media meta tags */}
        <meta property="og:title" content={tags.title} />
        <meta property="og:url" content={tags.url}></meta>
        <meta property="og:description" content={tags.description} />
        <meta property="og:locale" content={tags.locale} />
        <meta property="og:image" content={"%PUBLIC_URL%/" + tags.image} />

        {/* adding seo constants to each page */}
        <script type="application/json+ld">{JSON.stringify(jsonLD)}</script>
      </Helmet>
      {/* end react-helmet component */}

      <div className="h-auto flex flex-col sm:my-12">
        <span className="text-xl sm:text-3xl font-bold text-center header-text my-4">
          INTERNAL MISSION
        </span>
        <p className="px-8 sm:px-32 text-base sm:text-xl py-8 content-text">
          An opportunity to be involved with a dynamic company and environment
          where there is a shift - dance and fitness are combined in healthy
          lifestyle programming to improve overall quality of life. Long term
          resilience in body, mind and soul are the goal so that people can be
          stronger and thrive through life. This is not a “top down” company.
          Building this organization as a team from the ground up will provide
          plenty of opportunity for further growth.
        </p>
        <p className="px-8 sm:px-32 text-base sm:text-xl content-text mb-12 pb-12">
          KellyKinetix Team Members Receive:
          <ul className="content-text px-16 sm:px-40 list-disc">
            <li className="text-base content-text sm:text-xl text-start py-2">
              Consistent feedback on all assignments or tasks ensuring you are
              on the right track
            </li>
            <li className="text-base content-text sm:text-xl text-start py-2">
              When required, department specific meetings
            </li>
            <li className="text-base content-text sm:text-xl text-start py-2">
              Where required, 1-on-1 meetings with the Founder to ensure results
              happen
            </li>
            <li className="text-base content-text sm:text-xl text-start py-2">
              Training in KellyKinetix structures, systems, processes, and
              programming
            </li>
            <li className="text-base content-text sm:text-xl text-start py-2">
              Opportunities to grow (both in person and online)
            </li>
            <li className="text-base content-text sm:text-xl text-start py-2">
              Stage one is the internship program, and you may choose to stay on
              to the next level (Apprentice) after your internship.
            </li>
            <li className="text-base content-text sm:text-xl text-start py-2">
              Opportunities to further knowledge and skills through in house
              training as well as outside guests brought into our house and
              getting certified where relevant
            </li>
            <li className="text-base content-text sm:text-xl text-start py-2">
              Access to Activated Breath to change your life and how you live it
              so you can create a new reality for yourself
            </li>
            <li className="text-base content-text sm:text-xl text-start py-2">
              Positive, Supportive environment where we are all a close knit
              family and provide a welcoming, supportive and friendly family
              experience to our clients
            </li>
            <li className="text-base content-text sm:text-xl text-start py-2">
              Discounts on Merchandise
            </li>
            <li className="text-base content-text sm:text-xl text-start py-2">
              Free access to all in person and online events we hold for clients
            </li>
            <li className="text-base content-text sm:text-xl text-start py-2">
              Access to in house social events (in person and online)
            </li>
            <li className="text-base content-text sm:text-xl text-start py-2">
              Free access to online group classes and personal training sessions
              with clients
            </li>
          </ul>
        </p>
      </div>
    </div>
  );
  // end internship program page
};

export default InternalMissionPage;

// bring in required and installed developer imports
import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// import components
import BlogPostPreview from "./BlogPostPreview";
import SocialMediaContact from "./SocialMediaContact";

// imports posts data array => need to check if this updates live
import { blogPosts } from "./posts";

// import image for about kelly
import laneway from "../WorkshopPage/images/laneway.jpg";

// import logo for sharing social media thumbnail
import logo from "../HomePage/full-logo.png";
import { Link } from "react-router-dom";

export default function BlogLanding() {
  // need links to be blue
  // standardized call to action => way to contact kelly ==> (e.g. (i'm free (call now button), im busy (book a time), i'm swamped ()))
  return (
    <div>
      {/* beginning of react-helmet head
       */}
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="What to know regarding fitness consultations with KellyKinetix. KellyKinetix offers belly dancing and personal trainers in north york, Toronto, GTA and Ontario through online classes."
        />
        <meta
          name="keywords"
          content="blog, blog to, google blogger, blog post, lifestyle blog, blogs to read, best blogs, fitness blog, health and fitness blogs, personal trainer, personal trainer downtown toronto, fit women, goodlife workout, housefit personal training toronto, personal trainer, trainer, physical trainer, personal trainer at home, female personal trainer, personal training studio, fitness classes"
        />
        <title>KellyKinetix | Blog</title>

        {/* social media meta tags */}
        <meta property="og:title" content="KellyKinetix Consultation" />
        <meta
          property="og:url"
          content="https://www.kellykinetix.com/kellyKinetixBlog"
        />
        <meta
          property="og:description"
          content="Welcome to KellyKinetix's Blog! Here, you can stay up to date with weekly blog posts on healthy tips and nutritional info."
        />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:image" content={"%PUBLIC_URL%/" + logo} />
      </Helmet>

      {/* blog container with posts and about me section using flexbox*/}
      <BlogContainer>
        {/* blog posts using 70% of width till 600px width */}
        <BlogPreviewsContainer>
          {blogPosts.map((post) => (
            <BlogPostPreview
              key={post.id}
              id={post.id}
              thumbnail={post.thumbnail}
              postTitle={post.title}
              publishDate={post.date}
              previewContent={post.content}
              fullPostLink={post.title}
            ></BlogPostPreview>
          ))}
        </BlogPreviewsContainer>
        {/* about me section */}
        <div className="aboutKelly-blog">
          <hr></hr>
          <h2 className="aboutUs">About Kelly Kinetix</h2>
          <hr></hr>
          <img src={laneway} alt="testImage"></img>
          <p>
            {" "}
            Increase your energy, decrease your stress and have more confidence
            with each breath. With a support and accountability network set in
            place, you'll be sure to reach your goals and share your joy with
            those who saw your journey and hardwork. Make your body feel like it
            should through fun exercises such as{" "}
            <i>
              <b>
                <a href="../bellyDance" target="_blank" rel="noreferrer">
                  Belly Dancing
                </a>{" "}
              </b>
            </i>{" "}
            and{" "}
            <i>
              <b>
                <a href="../fitness" target="_blank" rel="noreferrer">
                  Body Resilience
                </a>
              </b>
            </i>{" "}
            classes.
          </p>
          <hr></hr>
          <h2>Categories</h2>
          <hr></hr>
          <div className="category-wrap">
            <div>
              <Link to="../fitness">
                <button>Fitness</button>
              </Link>

              <Link to="../dance">
                <button>Dance</button>
              </Link>
            </div>

            <div>
              <Link to="../workshops">
                <button>Workshops</button>
              </Link>

              <Link to="../consultation">
                <button>Consultation</button>
              </Link>

              <Link to="../FAQs">
                <button>FAQs</button>
              </Link>

              {/* <button>Nutrition</button> */}
            </div>
          </div>

          <br></br>
          <hr></hr>
          <h2>Follow Us</h2>
          <hr></hr>
          {/* social media icons */}
          <div className="socialmedia-wrap">
            <SocialMediaContact></SocialMediaContact>
          </div>
        </div>
      </BlogContainer>
    </div>
  );
}

const BlogContainer = styled.section`
  width: 100vw;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;

  justify-self: center;
  & > *:nth-child(1) {
    flex: 1 1 70%;
    background-color: white;
  }

  & > *:nth-child(2) {
    flex: 1 1 30%;
    background: linear-gradient(45deg, #ef6c00, white);
    display: flex;
    flex-direction: column;
    justify-items: center;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  hr {
    width: 90%;
    align-self: center;
    background-color: slateblue;

    height: 0.5vh;
    border: none;
  }

  .aboutKelly-blog {
    align-items: center;
    justify-items: center;
    padding-top: 1em;

    > * {
      padding: 0.25em;
      padding-left: 1em;
      padding-right: 1em;
    }

    h2 {
      font-size: 1.5rem;
      padding-top: 0;
      font-weight: 500;
    }

    p {
      font-size: 1.25rem;
      padding-bottom: 1em;
      a {
        color: slateblue;
      }

      a:hover {
        text-decoration: underline;
      }
    }

    img {
      padding: 1em;
    }

    .category-wrap {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      & > * {
        flex: 1 1;
      }

      button {
        font-size: 1.3rem;
      }
      div {
        display: flex;
        flex-direction: column;
        align-items: center;

        button {
          width: fit-content;
        }

        button:hover {
          text-decoration: underline;
        }
      }
    }

    .socialmedia-wrap {
      width: 100%;
      text-align: center;
      align-self: center;
      /* padding-top: 1em; */
    }

    @media screen and (max-width: 600px) {
      display: none;
    }
  }
`;

const BlogPreviewsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 3em;
  column-gap: 3rem;
  row-gap: 3rem;

  .blogPosts:first-of-type {
    flex: 1 1 100%;
  }

  .blogPosts,
  .blogPosts:last-of-type {
    flex: 0 0 47%;
  }

  @media screen and (max-width: 600px) {
    padding: 1em;
  }

  @media screen and (max-width: 768px) {
    .blogPosts,
    .blogPosts:last-of-type {
      flex-basis: 100%;
    }
  }
`;

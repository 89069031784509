import React, { useState } from "react";
import styled from "styled-components";
// used in case there is no image provided
import NoImage from "../images/noImage.jpg";
import { useHistory } from "react-router-dom";

//  hook that returns a reference to the dispatch function from the Redux store
// import { useDispatch } from "react-redux";

// used to extract data from the Redux store state
// import { useSelector } from "react-redux";

// action that passed to dispatch method
// import { addNewProduct } from "../redux-setup/actions";

// icon for "already in cart" section
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCheck } from "@fortawesome/free-solid-svg-icons";

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  gap: 10px;
  height: 65vh;
  width: 23%;
  border-radius: 12px;
  overflow: hidden;
  background-color: #f9f9f9;
  box-shadow: -10px 20px 20px rgba(0, 0, 0, 0.2), 0 0 50px rgba(0, 0, 0, 0.2);
  @media only screen and (min-width: 769px) and (max-width: 1280px) {
    width: 45%;
    height: 40vh;
  }
  @media screen and (max-width: 768px) {
    width: 150px;
    height: 280px;
  }
`;

const ImgContainer = styled.div`
  width: 100%;
  height: 68%;
  background-color: black;
  position: relative;
  @media screen and (max-width: 768px) {
    height: 55%;
  }
`;

const ImgItself = styled.div`
  width: 100%;
  height: 100%;
  opacity: ${(props) => props.opacityLevel};
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center;
  @media screen and (max-width: 768px) {
    opacity: 1;
  }
`;

// used to show price for clients (when product hovered)
// const Promo = styled.div`
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   width: 80%;
//   padding: 1rem;
//   border-radius: 12px;
//   font-weight: 600;
//   text-align: center;
//   transform: translate(-50%, -50%);
//   background-color: #f9f9f9;
//   opacity: 0.7;
//   text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.7);
//   z-index: 1;
//   visibility: ${(props) => props.visibility};
//   @media screen and (max-width: 768px) {
//     display: none;
//   }
// `;

// product name
const ProductDetails = styled.div`
  width: 100%;
  min-height: 15%;
  color: #324da1;
  padding: 0 1rem;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  transition: color ease-out 0.2s;
  &:hover {
    color: black;
  }
  @media screen and (max-width: 768px) {
    font-size: 0.7rem;
    padding: 0 0.8rem;
  }
`;

// container div for price and "add to cart" button
const AdditionalDetails = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ProductPrice = styled.div`
  position: relative;
  padding: 3px 0;
  width: 40%;
  font-weight: 500;
  color: #676464;
  text-align: center;
  border: 2px solid black;
  transition: transform ease-in-out 0.3s;
  transform: translateX(${(props) => props.translateX});
  @media screen and (max-width: 768px) {
    width: 50%;
    font-size: 0.8rem;
    transform: translateX(0px);
  }
`;

// const AddToCartBtn = styled.button`
//   position: absolute;
//   left: 0;
//   top: -2px;
//   width: 100%;
//   background: black;
//   color: #f9f9f9;
//   padding: inherit;
//   border: inherit;
//   transition: inherit;
//   cursor: pointer;
//   opacity: ${(props) => props.opacityLevel};
//   transform: translateX(${(props) => props.translateX});
//   &:hover:enabled {
//     background: ${(props) => props.hoverColour};
//     border-color: ${(props) => props.hoverColour};
//     color: #f9f9f9;
//   }
//   @media screen and (max-width: 768px) {
//     display: none;
//   }
// `;
//
// const AlreadyInCart = styled.div`
//   color: #4bb543;
// `;

const ProductCard = ({ data }) => {
  // const cartProducts = useSelector((state) => state.cart.products);
  // const [inCart, setInCart] = useState(false);

  // used to redirect user to the page wipth product details
  const history = useHistory();

  // used to update state of React app (redux)
  // const dispatch = useDispatch();

  // used to check whether product card was hovered by user
  const [hoverState, setHoverState] = useState(false);

  // used to redirect user to detailed page about product or add it to the shopping cart
  // const handleClick = () => {
  //   if (data.size || data.color) history.push(`/shop/${data.id}`);
  //   else {
  //     if (inCart === false) {
  //       dispatch(addNewProduct({
  //         data
  //       }));
  //       setInCart(true);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   const productIndex = cartProducts.findIndex(
  //     (element) => element.productID === data.id
  //   );
  //   // setInCart(productIndex === -1 ? false : true);
  //   // eslint-disable-next-line
  // }, []);

  return (
    <CardContainer
      onMouseOver={() => setHoverState(true)}
      onMouseOut={() => setHoverState(false)}
    >
      <ImgContainer>
        {/*<Promo visibility={hoverState ? "visible" : "hidden"}>*/}
        {/*  ${data.clientPrice} for our clients*/}
        {/*</Promo>*/}
        <ImgItself
          opacityLevel={hoverState ? "1" : "0.9"}
          backgroundImage={data.images ? data.images[0] : NoImage}
        ></ImgItself>
      </ImgContainer>
      <ProductDetails onClick={() => history.push(`/shop/${data.id}`)}>
        {data.title}
      </ProductDetails>
      <AdditionalDetails>
        <ProductPrice>
          {/*translateX={hoverState ? "-55px" : "0px"}*/}${data.publicPrice}
          {/* used to update the state - add new product, update price and total 
          quantity of the products in a cart */}
          {/*<AddToCartBtn*/}
          {/*  opacityLevel={hoverState ? "1" : "0"}*/}
          {/*  translateX={hoverState ? "110px" : "0px"}*/}
          {/*  onClick={handleClick}*/}
          {/*  hoverColour={inCart ? "black" : "#324da1"}*/}
          {/*>*/}
          {/*  {data.color || data.size ? (*/}
          {/*    "select"*/}
          {/*  ) : inCart ? (*/}
          {/*    <AlreadyInCart>*/}
          {/*      <FontAwesomeIcon icon={faCheck} />*/}
          {/*    </AlreadyInCart>*/}
          {/*  ) : (*/}
          {/*    "add to cart"*/}
          {/*  )}*/}
          {/*</AddToCartBtn>*/}
        </ProductPrice>
      </AdditionalDetails>
    </CardContainer>
  );
};

export default ProductCard;

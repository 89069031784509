import React, { useEffect } from "react";
import styled from "styled-components";
import Slider from "./swiper";
//import Single Testimonial component that is used to display single testimonial
import { SingleTestimonial } from "./SingleTestimonial";

// hash function used to hash object and can be used as key
import hash from "object-hash";

const TestimonialSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f3f4f6;
  padding: 2rem 0;
  margin-bottom: 5vh;
  @media screen and (max-width: 768px) {
    background: linear-gradient(rgba(0, 0, 0, 0.57), rgba(0, 0, 0, 0.63)),
      url(${(props) => props.backgroundImage});
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    min-height: 100vh;
  }
`;

const Testimonials = styled.div`
  display: flex;
  width: 100vw;
  margin-top: 40px;
`;

const TestimonialsTitle = styled.h3`
  color: #324da1;
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: capitalize;
  @media screen and (max-width: 768px) {
    color: #f3f4f6;
    text-align: center;
    margin: 0 15vw 5vh 15vw;
    padding-bottom: 2vh;
    border-bottom: 2px #f3f4f6 solid;
  }
`;

// component for Testimonials section (used on Fitness, Dance, Belly Dance pages)
export const TestimonialSectionComponent = (props) => {
  //title of Testimonials section (belly or fitness)
  // for Belly Dance and Fitness pages different background images are used
  //  for Belly Dance and Fitness pages different data is passed
  const { testimonialData, backgroundImage, title } = props;
  const width = window.innerWidth;

  // hook runs every second and shows entire message (by setting line-clamp to 20) for active testimonial slide
  // for non-active slides we set line-clamp to 5
  useEffect(() => {
    if (width > 768) {
      const interval = setInterval(() => {
        const activeTestimonial = [
          ...document.getElementsByClassName("swiper-slide-active"),
        ];
        const notActiveTestimonials = [
          ...document.getElementsByClassName("swiper-slide"),
        ];
        notActiveTestimonials.map(
          (el) => (el.children[0].children[1].style.webkitLineClamp = 5)
        );
        activeTestimonial[0].children[0].children[1].style.webkitLineClamp = 20;
      }, 1000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line
  }, []);

  //  on desktop and mobile versions, section will look different
  // on mobile version, swiper is disabled
  return (
    <TestimonialSection backgroundImage={backgroundImage}>
      <TestimonialsTitle> {title} </TestimonialsTitle>
      {width > 768 ? (
        <Testimonials>
          <Slider>
            {testimonialData.map((testimonial, index) => (
              <div key={hash(testimonial)}>
                <SingleTestimonial
                  index={index}
                  message={testimonial.message}
                  client={testimonial.client}
                />
              </div>
            ))}
          </Slider>
        </Testimonials>
      ) : (
        <>
          {testimonialData.map((testimonial, index) => (
            <SingleTestimonial
              key={hash(testimonial)}
              index={index}
              message={testimonial.message}
              client={testimonial.client}
            />
          ))}
        </>
      )}
    </TestimonialSection>
  );
};

import React from "react";
import styled from "styled-components";
import NotFoundIcon from "./images/notFound.png";

const PageNotFoundContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 70vh;
  gap: 40px;
  padding: 6rem;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 2rem;
  }
`;

const NotFoundImg = styled.img`
  height: 50%;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media screen and (max-width: 768px) {
    align-items: center;
  }
`;

const SorryTitle = styled.h1`
  font-size: 4rem;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0 !important;
  margin-bottom: 14px !important;
`;

const NotFoundTitle = styled.h2`
  font-size: 3rem;
  text-transform: lowercase;
  margin: 0 !important;
`;

const PageNotFound = () => {
  return (
    <PageNotFoundContainer>
      <NotFoundImg alt="not found" src={NotFoundIcon} />
      <Text>
        <SorryTitle> sorry</SorryTitle>
        <NotFoundTitle> We couldn't find that page</NotFoundTitle>
      </Text>
    </PageNotFoundContainer>
  );
};

export default PageNotFound;

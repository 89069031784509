import size9 from "../../src/data/ECPPhotos/Kelly Kinetix Lounge Zip Up sweater_/size.png";
import size8 from "../../src/data/ECPPhotos/Kelly Kinetix Sweat Resistant Joggers/size.png";
import size7 from "../../src/data/ECPPhotos/Kelly Kinetix Womens Lounge Joggers_/size.png";
import size5 from "../../src/data/ECPPhotos/KellyKinetix Shorts/size.png";
import size4 from "../../src/data/ECPPhotos/Kelly Kinetix Womens Loungewear Tank/size.png";
import size3 from "../../src/data/ECPPhotos/Kelly Kinetix Sweat Resistant Tank Top_/size.png";

import p1img from "../../src/data/ECPPhotos/Kelly Kinetix Sweat Resistant Tank Top_/IMG_1392.jpg";

export const merch2Data = [
  {
    id: 1,
    images: [p1img],
    title: "KELLYKINETIX Womens Sweat Resistant T-Shirt",
    sizeOptions: ["XS", "S", "M", "L", "XL", "XXL"],
    colorOptions: [
      {
        text: "Blue",
        colorSwatch: "#243c9b",
      },
      {
        text: "White",
        colorSwatch: "#ededed",
      },
      {
        text: "Black",
        colorSwatch: "#111111",
      },
    ],
    frontDesignOptions: ["A", "B", "C", "D"],
    backDesignOptions: ["A", "B", "C", "D"],
    sleeveOptions: ["A", "B", "C", "D"],
    clientPrice: 28.1,
    publicPrice: 37.46,
    materialInformation: "Solid: 94% Poltrueter, 6% Spandex",
    sizeInformation: { text: "", image: [] },
  },
  {
    id: 2,
    images: [],
    title: "KELLYKINETIX Mens Sweat Resistant T-Shirt",
    sizeOptions: ["XS", "S", "M", "L", "XL", "XXL"],
    colorOptions: [
      {
        text: "Black",
        colorSwatch: "#121212",
      },
      {
        text: "White",
        colorSwatch: "#ececec",
      },
      {
        text: "Blue",
        colorSwatch: "#162f57",
      },
      {
        text: "Orange",
        colorSwatch: "#da5102",
      },
    ],
    frontDesignOptions: ["A", "B", "C", "D"],
    backDesignOptions: ["A", "B", "C", "D"],
    sleeveOptions: ["A", "B", "C", "D"],
    clientPrice: 28.95,
    publicPrice: 38.59,
    materialInformation: "100% Poltrueter",
    sizeInformation: { text: "", image: [] },
  },
  {
    id: 3,
    images: [],
    title: "KELLYKINETIX Womens Sweat Resistant Tank Tops",
    sizeOptions: ["S", "M", "L", "XL", "XXL"],
    colorOptions: [
      {
        text: "Blue",
        colorSwatch: "#162f57",
      },
      {
        text: "White",
        colorSwatch: "#ececec",
      },
      {
        text: "Black",
        colorSwatch: "#121212",
      },
    ],
    frontDesignOptions: ["A", "B", "C"],
    backDesignOptions: ["A", "B"],
    sizeChart: true,
    clientPrice: 40.47,
    publicPrice: 53.96,
    materialInformation: "95% Poltrueter, 5% Spandex",
    sizeInformation: { text: "", image: [size3] },
  },
  {
    id: 4,
    images: [],
    title: "KELLYKINETIX Womens Lounge Tank Tops",
    sizeOptions: ["S", "M", "L", "XL", "XXL"],
    colorOptions: [
      {
        text: "Black",
        colorSwatch: "#121212",
      },
      {
        text: "White",
        colorSwatch: "#ececec",
      },
      {
        text: "Grey",
        colorSwatch: "#a3a4a2",
      },
    ],
    frontDesignOptions: ["A", "B", "C"],
    backDesignOptions: ["A", "B"],
    sizeChart: true,
    clientPrice: 18.38,
    publicPrice: 24.5,
    materialInformation: "95% Modal 5% Spandex",
    sizeInformation: { text: "", image: [size4] },
  },
  {
    id: 5,
    images: [],
    title: "KELLYKINETIX Womens Lounge shorts",
    sizeOptions: ["XS", "S", "M", "L", "XL", "XXL"],
    colorOptions: [
      {
        text: "Grey",
        colorSwatch: "#a5a5a3",
      },
      {
        text: "Black",
        colorSwatch: "#121212",
      },
    ],
    sizeChart: true,
    clientPrice: 44.99,
    publicPrice: 59.98,
    materialInformation: "63.5% cotton+32% poltrueter+4.5% spandex",
    sizeInformation: { text: "", image: [size5] },
  },
  {
    id: 6,
    images: [],
    title: "KELLYKINETIX Metal Bottle",
    colorOptions: [
      {
        text: "Black",
        colorSwatch: "#1f1e20",
      },
      {
        text: "Blue",
        colorSwatch: "#002083",
      },
      {
        text: "Champaign",
        colorSwatch: "#858889",
      },
    ],
    frontDesignOptions: ["A", "B", "C"],
    backDesignOptions: ["A", "B"],
    clientPrice: 28.49,
    publicPrice: 37.98,
    materialInformation: "Material Stainless Steel",
    sizeInformation: { text: "", image: [] },
  },
  {
    id: 7,
    images: [],
    title: "KELLYKINETIX Womens Lounge Joggers",
    sizeOptions: ["XS", "S", "M", "l", "XL", "XXL", "3XL", "4XL", "5XL"],
    colorOptions: [
      {
        text: "Black",
        colorSwatch: "#111111",
      },
    ],
    frontDesignOptions: ["A", "B"],
    clientPrice: 47.96,
    publicPrice: 63.94,
    materialInformation: "Material: cotton: 30%, poltrueter: 65%, spandex: 5%.",
    sizeInformation: { text: "", image: [size7] },
  },
  {
    id: 8,
    images: [],
    title: "KELLYKINETIX Women Sweat Resistant Joggers",
    sizeOptions: ["S", "M", "L", "XL", "XXL"],
    colorOptions: [
      {
        text: "Light Blue",
        colorSwatch: "#9cb0cc",
      },
      {
        text: "Black",
        colorSwatch: "#121212",
      },
      {
        text: "Green",
        colorSwatch: "#706e6a",
      },
    ],
    frontDesignOptions: ["A", "B"],
    clientPrice: 58.49,
    publicPrice: 77.98,
    materialInformation: "80% polyamide and 20% spandex",
    sizeInformation: { text: "", image: [size8] },
  },
  {
    id: 9,
    images: [],
    title: "KELLYKINETIX Lounge Zip Up",
    sizeOptions: ["S", "M", "L", "XL", "XXL"],
    colorOptions: [
      {
        text: "Black",
        colorSwatch: "#111111",
      },
      {
        text: "White",
        colorSwatch: "#ededed",
      },
    ],
    clientPrice: 36.96,
    publicPrice: 49.28,
    materialInformation: "100% Cotton",
    sizeInformation: { text: "", image: [size9] },
  },
  {
    id: 10,
    images: [],
    title: "KELLYKINETIX Face Masks",
    colorOptions: [
      {
        text: "Black",
        colorSwatch: "#211b17",
      },
    ],
    frontDesignOptions: ["A", "B"],
    clientPrice: 4.5,
    publicPrice: 6.0,
  },
  {
    id: 11,
    images: [],
    title: "KELLYKINETIX Stop Watch",
    colorOptions: [
      {
        text: "Black",
        colorSwatch: "",
      },
    ],
    frontDesignOptions: ["A", "B"],
    clientPrice: 18.74,
    publicPrice: 24.98,
    materialInformation: "Material Plastic",
    sizeInformation: { text: "", image: [] },
  },
];

import React from "react";
import backdrop from "./cta-image.jpg";
// font awesome icons
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaEnvelope,
  FaPinterest,
} from "react-icons/fa";

const backgroundStyleObjectLg = {
  background: `url(${backdrop})`,
  backgroundPosition: "0% 0%",
  backgroundSize: "cover",
  fontFamily: "'Nunito Sans', sans-serif",
  height: "700px",
};

const backgroundStyleObjectSm = {
  background: `url(${backdrop})`,
  backgroundPosition: "45% 0%",
  backgroundSize: "cover",
  fontFamily: "'Nunito Sans', sans-serif",
  height: "50vh",
};

const CallToAction = () => {
  return (
    <div className="h-auto flex">
      {/* begin call to action */}
      <div
        className="w-full sm:h-screen flex flex-col justify-start cta-style"
        style={
          window.innerWidth < 767
            ? backgroundStyleObjectSm
            : backgroundStyleObjectLg
        }
      >
        {/* begin call to action card */}
        <div className="h-12 sm:h-60 w-full sm:w-2/5 px-8 sm:pl-24 hidden pt-4 pb-4 flex flex-col items-center sm:items-start xl:block">
          {/*<span className="text-base sm:text-3xl hidden font-black xl:block">*/}
          {/*  Join our Limited Time Women's Day contest offer! <br/></span>*/}
          {/*  <Link*/}
          {/*    to="/WomensDayContest"*/}
          {/*    rel="noopener noreferrer"*/}
          {/*    target="_blank"*/}
          {/*  >*/}
          {/*    <button type="button" className="btn btn-dark" style={{ margin:"2rem 0", color: "white", backgroundColor: "orangered", borderColor: "orangered"}}>Join now</button>*/}

          {/*  </Link>*/}
          <span
            className="text-base sm:text-3xl hidden pb-5 xl:block"
            style={{ paddingTop: "9rem", fontSize: "1.5rem", color: "#081359" }}
          >
            Empowering women to regain their energy through coaching in dance,
            strength, mobility & lifestyle habit changes.
            <br />
          </span>
          <span className="text-base sm:text-3xl hidden font-black xl:block">
            Start your journey Here!
            <br />
          </span>
          <a
            href="https://live.vcita.com/site/kellykinetix/action/r1ssrsuz3i4o2698"
            rel="noopener noreferrer"
            target="_blank"
            style={{ margin: "auto" }}
            className="hidden xl:block"
          >
            <button
              type="button"
              className="btn btn-dark-blue  bg-black text-white
              hover:bg-white hover:text-black
              transition duration-300
              ease-in-out rounded-full mt-3 mb-5"
              style={{ fontWeight: "700" }}
            >
              Join now
            </button>
          </a>

          <span className="text-base sm:text-3xl hidden font-black xl:block">
            Follow us on social media
            <br />
          </span>
          <div className="h-12 flex items-start sm:items-center mt-2">
            <span
              className="
              p-2 mr-2 
              bg-black text-white 
              hover:bg-white hover:text-black
              transition duration-300 
              ease-in-out rounded-full
            "
            >
              <a
                href="https://www.instagram.com/kellykinetix"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
              </a>
            </span>
            <span
              className="
              p-2 mr-2 
              bg-black text-white 
              hover:bg-white hover:text-black
              transition duration-300 
              ease-in-out rounded-full
            "
            >
              <a
                href="https://www.facebook.com/kellykinetix"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook />
              </a>
            </span>
            <span
              className="
              p-2 mr-2 
              bg-black text-white 
              hover:bg-white hover:text-black
              transition duration-300 
              ease-in-out rounded-full
            "
            >
              <a
                href="https://www.linkedin.com/company/kellykinetics"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin />
              </a>
            </span>
            <span
              className="
              p-2 mr-2 
              bg-black text-white 
              hover:bg-white hover:text-black
              transition duration-300 
              ease-in-out rounded-full
            "
            >
              <a
                href="mailto:info@kellykinetix.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaEnvelope />
              </a>
            </span>
            <span
              className="
              p-2 mr-2 
              bg-black text-white 
              hover:bg-white hover:text-black
              transition duration-300 
              ease-in-out rounded-full
            "
            >
              <a
                href="https://www.pinterest.com/KellyKinetix"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaPinterest />
              </a>
            </span>
          </div>
        </div>
        {/* end call to action card */}
      </div>
      {/* end call to action */}
    </div>
  );
};

export default CallToAction;

import React from "react";

// react-helmet
import { Helmet } from "react-helmet";

// Testimonial component
import { TestimonialSectionComponent } from "../../Testimonial";

// testimonial data
import { testimonials } from "../../../data";

// images
import greenery from "./images/greenery.jpg";
import deadlift from "./images/deadlift.jpg";
import machine from "./images/machine.jpg";
import grinning from "./images/grinning.jpg";
import client from "./images/client.jpg";

const HealthBenefitsPage = () => {
  const { fitnessTestimonialData } = testimonials;
  // begin fitness page
  return (
    <div className="h-auto flex flex-col">
      {/* begin react-helmet component */}
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="About the fitness services offered by KellyKinetix which include mobility and strength training. KellyKinetix offers belly dancing and personal trainers in north york, Toronto, GTA and Ontario through online classes."
        />

        {/* meta tags for social media sharing */}
        <meta property="og:title" content="KellyKinetix Fitness" />
        <meta
          property="og:url"
          content="https://www.kellykinetix.com/fitness"
        />
        <meta
          name="keywords"
          content="gym instruction, training, working out, fitness classes, exercise classes near me, group fitness classes near me, workout classes, exercise classes, group workout classes near me, exercise groups near me, zumba exercise for beginners, exercise classes near me, group fitness classes near me, exercise classes, group workout classes near me, workout classes, zumba dance, circuit training, fitness classes near me, workout classes near me, zumba, cpr, personal trainer, personal trainer downtown toronto, fit women, goodlife workout, housefit personal training toronto, personal trainer, trainer, physical trainer, personal trainer at home, female personal trainer, personal training studio, fitness classes, personal trainers near me, fitness studio, fitness studio toronto, personal trainer, personal training studio, private trainer, personal fitness, studio north, health & fitness, personal training, fitness gym, health fitness, fitness centre, toronto fitness, gym in toronto, downtown toronto gym, in love yourself, love your self, love yourself, my self love, linktree instagram, facebook instagram, instagram and facebook, tiktok instagram, face book face book, mind body connection, mind body, mind and body, body mind, cardiovascular fitness, benefits of physical activity, benefits of physical fitness, 10 benefits of physical activity, workout benefits, cardio vascular fitness, paul chek, smart goals examples, smart goals acronym, client testimonials, mobility, mobility plus, mobility, resilience, resiliency, resilience examples, mental resilience, prestige awards, corporate livewire prestige awards, prestige trophies"
        />

        <meta
          property="og:description"
          content="Reserve a consultation to book your personal training session. Also offer group fitness classes and customized programming to get everyone to reach their goals."
        />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:image" content={"%PUBLIC_URL%/" + deadlift} />
        <meta property="og:type" content="website" />

        <title>Health Benefits of Fitness</title>
      </Helmet>
      {/* end react-helmet component */}
      {/* begin personal training benefits section */}
      <div className="h-auto flex flex-col my-12">
        <span className="text-xl sm:text-3xl font-bold text-center header-text">
          HEALTH BENEFITS OF FITNESS
        </span>
        <p className="pt-8 pb-16 sm:px-32 text-base sm:text-xl my-4 content-text">
          As with dance, there are also many health benefits to all forms of
          fitness. At KellyKinetix, fitness falls under many different hats and
          include elements of working out, working in (more on this in a bit),
          dance and martial arts. Health benefits of fitness training include:
        </p>
        <ul className="content-text px-16 sm:px-64">
          <li className="text-base sm:text-xl text-center py-2">
            Better weight management
          </li>
          <li className="text-base sm:text-xl text-center py-2">
            Alleviate and control health conditions or diseases
          </li>
          <li className="text-base sm:text-xl text-center py-2">
            Improves mood
          </li>
          <li className="text-base sm:text-xl text-center py-2">
            Boosts energy and promotes better sleep habits
          </li>
          <li className="text-base sm:text-xl text-center py-2">
            Puts a spark back into your sex life!
          </li>
          <li className="text-base sm:text-xl text-center py-2">
            It's fun and social!
          </li>
        </ul>
      </div>
      <p className="px-8 sm:px-32 text-base sm:text-xl pb-8 mb-12 content-text">
        learn about{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="/workingOutAndWorkingIn"
          className="content-link"
        >
          Working Out and Working In
        </a>
        .
      </p>
      {/* end personal training benefits section */}
    </div>
  );
  // end fitness page
};

export default HealthBenefitsPage;

// image imports
import React from "react";
import withClient from "../HomePage/kelly-client.jpg";

import { BookNowBtn } from "../ConsultationPage";

// React-helmet
import { Helmet } from "react-helmet";

// image for thumbnail meta tag
import backdrop from "../../CallToAction/cta-image.jpg";

export const jsonLD = {
  "@context": "https://schema.org/",
  "@type": "LocalBusiness",
  name: "KellyKinetix",
  author: {
    "@type": "Person",
    name: "Kelly Enston",
  },
  description:
    "Take care of your body in fun was, through group belly dancing and body resilience classes. Personal training can also be included, as well as various other forms of exercise (Jiu Jitsu, Kick Boxing), all in the Greater Toronto Area (GTA).",
  areaServed: {
    "@type": "Province",
    name: "Ontario",
  },
  priceRange: "",
  paymentAccepted: "Credit Card",
  hasOfferCatalog: {
    "@type": "OfferCatalog",
    name: "KellyKinetix Services",
    itemListElement: [
      {
        "@type": "OfferCatalog",
        name: "Classes and Training",
        itemListElement: [
          {
            "@type": "Offer",
            itemOffered: {
              "@type": "Service",
              name: "Personal Training",
            },
          },

          {
            "@type": "Offer",
            itemOffered: {
              "@type": "Service",
              name: "Belly Dance",
            },
          },

          {
            "@type": "Offer",
            itemOffered: {
              "@type": "Service",
              name: "Body Resilience and Strength Training Group Class",
            },
          },

          {
            "@type": "Offer",
            itemOffered: {
              "@type": "Service",
              name: "Dance and Nutrition Workshops",
            },
          },
        ],
      },
      {
        "@type": "OfferCatalog",
        name: "One-time Service",
        itemListElement: [
          {
            "@type": "Offer",
            itemOffered: {
              "@type": "Service",
              name: "Consultation",
            },
          },
        ],
      },
    ],
  },
};
export const metaTagConstants = {
  title: "KellyKinetix Home",
  description:
    "Stay fit and active through personal training sessions or group belly dancing and body strengthing classes. Classes are held over zoom currently until COVID-19 regulations allow in-person.",
  locale: "en_GB", // defines language type
  url: "https://www.kellykinetix.com/",
  image: backdrop,
  keywords:
    "gym instruction, training, working out, personal trainer, personal trainer downtown toronto, fit women, goodlife workout, housefit personal training toronto, personal trainer, trainer, rainer, physical trainer, personal trainer at home, female personal trainer, personal training studio, fitness classes, personal trainers near me, fitness studio, fitness studio toronto, personal trainer, personal training studio, private trainer, personal fitness, studio north, health & fitnes, personal training, fitness gym, health fitness, fitness centre, health & fitness, personal training, fitness gym, health fitness, fitness centre, toronto fitness, gym in toronto, downtown toronto gym, in love yourself, love your self, love yourself, my self love, linktree instagram, facebook instagram, instagram and facebook, tiktok instagram, face book face book, belly dance toronto, belly dancing classes toronto, mind body connection, mind body, mind and body, body mind",
};

const CompanyMissionPage = () => {
  // begin company mission page and header
  return (
    <div
      className="h-auto flex flex-col mb-20"
      itemScope
      itemType="https://schema.org/LocalBusiness"
    >
      {/* begin react-helmet component */}
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="KellyKinetix is a company that offers fitness and belly dance classes as well as personal training in Toronto, North York, GTA and Ontario, Canada and remotely through Zoom."
        />
        <meta name="keywords" content={metaTagConstants.keywords} />
        <title>KellyKinetix | Home</title>

        {/* key opengraph meta tags */}
        <meta property="og:title" content={metaTagConstants.title} />
        <meta property="og:url" content={metaTagConstants.url} />
        <meta
          property="og:description"
          content={metaTagConstants.description}
        />
        <meta property="og:locale" content={metaTagConstants.locale} />
        <meta
          property="og:image"
          content={"%PUBLIC_URL%/" + metaTagConstants.image}
        />
        <meta property="og:type" content="website" />

        {/* JSON-LD data for SEO */}
        <script type="application/json+ld">{JSON.stringify(jsonLD)}</script>
      </Helmet>
      {/* end react-helmet component */}

      {/*/!* begin mission section *!/*/}
      <span className="text-xl lg:text-3xl self-center font-bold header-text mb-6 pt-10">
        External Mission
      </span>
      <div className="flex flex-col lg:flex-row justify-center items-center lg:items-start lg:mt-10">
        {/* begin side content section */}
        <div className="w-full lg:w-1/2 h-auto flex flex-col">
          <p className="px-8 sm:pr-12 sm:pl-32 text-base sm:text-xl my-4 content-text">
            We are in the business of providing people with resiliency to enable
            them to adapt in a changing world. At KellyKinetix, we change lives
            through Dance and Fitness. Through every interaction our community
            is healthier, stronger, happier and far more confident. Your journey
            is a shared experience. We celebrate our clients.
          </p>
        </div>
        {/* end side content section */}
        {/* begin image section */}
        <div className="w-3/4 lg:W-1/2 h-auto block sm:flex items-center">
          <img
            src={withClient}
            alt="working with a client"
            className="rounded w-full lg:w-4/5 block m-auto"
          />
        </div>
        {/* end image section */}
      </div>
      {/*/!* end mission section *!/*/}

      {/*begin credentials section */}
      <div className="h-auto flex flex-col lg:my-12 my-8">
        <p className="text-xl lg:text-2xl self-center font-bold header-text mb-6 pt-6 px-8 text-center">
          What we bring to client/customer - what they can expect when they join
        </p>
        <ul className="content-text px-12 lg:px-64 list-disc">
          <li className="text-base sm:text-xl py-2">
            30 minutes complimentary discovery calls with 1 of our personal
            trainers
          </li>
          <li className="text-base sm:text-xl py-2">CPR with AED Certified</li>
          <li className="text-base sm:text-xl py-2">
            60 minutes health & wellness consultation with 1 of our personal
            trainers
          </li>
          <li className="text-base sm:text-xl py-2">
            Extensive Assessment included as part of your training package
          </li>
          <li className="text-base sm:text-xl py-2">
            1-on-1 personal training, semi-private training, or group training
            classes
            <ul className="list-disc pl-8 lg:pl-16">
              <li className="text-base sm:text-xl py-2">Online</li>
              <li className="text-base sm:text-xl py-2">In-Person</li>
              <ul className="list-disc pl-8 lg:pl-16">
                <li className="text-base sm:text-xl py-2">
                  Studio facilities (future)
                </li>
                <li className="text-base sm:text-xl py-2">In-home</li>
                <li className="text-base sm:text-xl py-2">Outdoors</li>
              </ul>
            </ul>
          </li>
          <li className="text-base sm:text-xl py-2">
            Access to multiple dance styles - through an assessment of current
            life situations specific dance styles may be recommended over others
          </li>
          <li className="text-base sm:text-xl py-2">
            If a client has a particular interest in one type of dance style and
            wants more attention, private classes can be arranged.
          </li>
          <li className="text-base sm:text-xl py-2">
            Live Belly Dance and Bollywood performances and/or lessons for your
            small to large functions and events
          </li>
          <li className="text-base sm:text-xl py-2">
            Special seasonal workshops/bootcamps in:
            <ul className="list-disc pl-8 lg:pl-16">
              <li className="text-base sm:text-xl py-2">
                Specific aspects of fitness (weight loss, building strength, etc
                and more specific topics based on collective client needs)
              </li>
              <li className="text-base sm:text-xl py-2">
                Specific aspects of dance ( intro workshops, stylistic
                workshops, props workshops, choreography workshops, etc)
              </li>
              <li className="text-base sm:text-xl py-2">
                Specific aspects of lifestyle (nutrition, sleep, stress, etc)
              </li>
              <li className="text-base sm:text-xl py-2">
                Specialty fitness workshops - Sport related - martial arts etc
              </li>
              <li className="text-base sm:text-xl py-2">
                Workshops by special guests
              </li>
            </ul>
          </li>
          <li className="text-base sm:text-xl py-2">
            Access to K² Ambassador Referral Program
          </li>
          <li className="text-base sm:text-xl py-2">
            Access to multiple Biometric training systems to enhance and change
            your life (physical, emotional, professional etc.)
          </li>
          <li className="text-base sm:text-xl py-2">
            Access to high quality, safe, natural and great prices for home
            products
          </li>
          <li className="text-base sm:text-xl py-2">
            Special Holiday related events and or Quarterly events
            (parties/shows)
          </li>
          <li className="text-base sm:text-xl py-2">
            A place you can go that is like a second home. Everyone feels
            welcome through connections, openness, inclusiveness and fun
          </li>
        </ul>
      </div>
      {/*end credentials section */}

      <BookNowBtn
        href="/values"
        target="blank"
        rel="noopener noreferrer"
        className="block w-3/4 lg:w-1/2 mx-auto lg:my-20"
      >
        Learn about our Company Core Values
      </BookNowBtn>
      {/* Footer component */}
    </div>
    // end company mission page
  );
};

export default CompanyMissionPage;

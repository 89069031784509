import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import clsx from 'clsx';
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
// import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from "@material-ui/core/CardMedia";
// import CardContent from '@material-ui/core/CardContent';
import Typography from "@mui/material/Typography";
import laneway from "./images/laneway.jpg";

const useStyles = makeStyles((theme) => ({
  signUpButton: {
    border: "1px solid white",
    color: "white",
    borderRadius: "5px",
    padding: "14px 28px",
    fontSize: "16px",
    cursor: "pointer",
    "&:hover": {
      background: "rgba(231, 231, 231, 0.5)",
      color: "white",
    },
  },
}));

export default function EventItem(props) {
  const classes = useStyles();
  const [day, setDay] = useState(0);
  const [month, setMonth] = useState(0);
  const [venue, setVenue] = useState(0);

  const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    const date = new Date(props.workshopStartDate);
    setDay(date.getDay());
    setMonth(date.getMonth());
    const fetchVenue = async () => {
      await fetch(
        `https://www.eventbriteapi.com/v3/venues/${props.venue_id}/?token=${process.env.REACT_APP_EVENTBRITE_TOKEN}`,
        {
          method: "GET",
        }
      )
        .then((data) => data.json())
        .then((jsonData) => {
          setVenue(jsonData);
          console.log(props);
        });
    };

    if (props.venue_id !== undefined) {
      fetchVenue().catch((err) => {
        console.log(err);
      });
    }
  }, []);
  return (
    <a href={props.signUpLink} target="_blank" rel="noopener noreferrer">
      <Card
        style={{
          width: "345px",
          height: "370px",
          marginBottom: "15px",
          paddingBottom: "25px",
        }}
      >
        <CardActionArea style={{ height: "370px" }}>
          <CardMedia
            className={classes.media}
            image={props.cardImg ? props.cardImg : laneway}
            style={{ backgroundPositionY: "1rem", height: "170px" }}
            title={props.workshopTitle}
          />
          <Typography
            sx={{ fontSize: 18, padding: "24px 16px 0 16px" }}
            color="text.primary"
            gutterBottom
          >
            {props.workshopTitle}
          </Typography>
          {props.workshopStartDate && props.workshopEndDate && (
            <Typography
              sx={{
                fontSize: 14,
                padding: "0 16px",
                color: "#f1592a",
                fontWeight: "600",
              }}
              color="text.secondary"
              gutterBottom
            >
              {weekday[day] +
                `, ` +
                months[month] +
                ` ` +
                props.workshopStartDate.slice(8, 10) +
                `, ` +
                props.workshopStartDate.slice(0, 4) +
                `, ` +
                props.workshopStartDate.slice(11, 16) +
                ` to ` +
                props.workshopEndDate.slice(11, 16)}
            </Typography>
          )}
          {props.time && (
            <Typography
              sx={{
                fontSize: 14,
                padding: "0 16px",
                color: "#f1592a",
                fontWeight: "600",
              }}
              color="text.secondary"
              gutterBottom
            >
              {props.time}
            </Typography>
          )}
          {venue.name !== undefined && (
            <Typography
              style={{ fontSize: 14, padding: "0 16px" }}
              color="text.secondary"
            >
              {venue.name + ` •` + venue?.address?.localized_area_display}
            </Typography>
          )}
          {venue.name === undefined && (
            <Typography
              style={{ fontSize: 14, padding: "0 16px" }}
              color="text.secondary"
            >
              {"Online"}
            </Typography>
          )}
          <Typography
            style={{ fontSize: 14, padding: "0 16px" }}
            color="text.secondary"
          >
            {props.workshopInstructor}
          </Typography>
          <Typography
            style={{ fontSize: 14, padding: "0 16px" }}
            color="#6F7287"
          >
            {parseFloat(props.ticket_price_max) > 0 &&
              parseFloat(props.ticket_price_max).toFixed(2) ===
                parseFloat(props.ticket_price_min).toFixed(2) && (
                <p>
                  {" "}
                  {props.currency} ${props.ticket_price_min}
                </p>
              )}
            {parseFloat(props.ticket_price_max) > 0 &&
              parseFloat(props.ticket_price_max).toFixed(2) !==
                parseFloat(props.ticket_price_min).toFixed(2) && (
                <p>
                  {" "}
                  {props.currency} ${props.ticket_price_min} - {props.currency}{" "}
                  ${props.ticket_price_max}
                </p>
              )}
            {parseFloat(props.ticket_price_min) == 0 &&
              parseFloat(props.ticket_price_max) == 0 && <p>Free</p>}
          </Typography>
        </CardActionArea>
      </Card>
    </a>
  );
}

import React from "react";

import { Helmet } from "react-helmet";

// import styled components
import styled from "styled-components";

// Testimonial component
import { TestimonialSectionComponent } from "../../Testimonial/index";
import DanceItem from "../DancePage/DanceItem";

// images
import bellyDance from "../DancePage/images/bd.jpg";
import armsUp from "../DancePage/images/arms-up.jpg";
import bollywoodDanceImg from "../DancePage/images/bwd.png";
import deadlift from "../FitnessPage/images/deadlift.jpg";
import client from "../FitnessPage/images/client.jpg";
import group from "../DancePage/images/group-dance.jpg";
// testimonial data
// import { testimonials } from "../../../data";

// json-LD import
import { jsonLD } from "../HomePage/index";
import { metaTagConstants } from "../HomePage/index";

// import social media thumbnail image for sharing
import spreadArms from "../DancePage/images/arms-up.jpg";
import { useHistory } from "react-router-dom";
import EventItem from "../WorkshopPage/EventItem";

// const { danceTestimonialData } = testimonials;

const danceTypes = [
  {
    danceTitle: "Dance",
    cardImg: bellyDance,
    imgPosition: "40% 20%",
    pageLink: "/dance",
  },
  {
    danceTitle: "Fitness",
    cardImg: deadlift,
    imgPosition: "50% 50%",
    pageLink: "/fitness",
  },
  {
    danceTitle: "Workshops & Events",
    cardImg: group,
    imgPosition: "50% 0%",
    pageLink: "/workshops",
  },
  {
    danceTitle: "Classes",
    cardImg: client,
    imgPosition: "20% 50%",
    pageLink: "/classes",
  },
];

const ProgramsAndServicesPage = () => {
  const history = useHistory();

  // dance meta tags
  const danceTags = {
    ...metaTagConstants,
    title: "Dance",
    url: "https://www.kellykinetix.com/dance",
    description:
      "Improve your health through dance classes. Work on weight management, balance and flexibility.",
    image: spreadArms,
  };

  // begin dance page
  return (
    <div className="h-auto flex flex-col">
      {/* begin react-helmet component */}
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Dance classes and training offered by KellyKinetix which focus on belly dance in solo or group settings. KellyKinetix offers belly dancing and personal trainers in north york, Toronto, GTA and Ontario through online classes."
        />
        <meta
          name="keywords"
          content="belly dance toronto, belly dancing classes toronto, mind body connection, mind body, mind and body, body mind, meditation, meditation music, sleep music, mindfulness meditation, guided meditation, mindfulness meditation, mindfulness, dance lessons, dance classes near me, dance lessons near me, dance schools near me, belly dance classes near me, benefits of dancing, benefits of dance, cardiovascular fitness, benefits of physical activity, benefits of physical fitness, 10 benefits of physical activity, workout benefits, cardio vascular fitness, resilience, resiliency, resilience examples, mental resilience, prestige awards, corporate livewire prestige awards, prestige trophies, fitness classes, exercise classes near me, group fitness classes near me, workout classes, exercise classes, group workout classes near me, exercise groups near me, zumba exercise for beginners, exercise classes near me, group fitness classes near me, exercise classes, group workout classes near me, workout classes, zumba dance"
        />

        <title>Programs and Services</title>

        {/* adding social media meta tags */}
        <meta property="og:title" content={danceTags.title} />
        <meta property="og:url" content={danceTags.url}></meta>
        <meta property="og:description" content={danceTags.description} />
        <meta property="og:locale" content={danceTags.locale} />
        <meta property="og:image" content={"%PUBLIC_URL%/" + danceTags.image} />

        {/* adding seo constants to each page */}
        <script type="application/json+ld">{JSON.stringify(jsonLD)}</script>
      </Helmet>
      {/* end react-helmet component */}

      <div className="h-auto flex flex-col sm:my-12">
        <span className="text-xl sm:text-3xl font-bold text-center header-text my-4">
          Programs and Services
        </span>
        <p className="px-8 sm:px-32 text-base sm:text-xl py-12 content-text">
          For those looking to improve their overall health and wellbeing to
          gain and retain resilience for life we offer both dance and fitness.
          How you can become involved in one or both is varied. Read about our
          offerings and then go to our{" "}
          <a href="/consultation" target="_blank" rel="noopener noreferrer">
            consultation page
          </a>
          .
        </p>
        <DanceContainer className="w-full" style={{ columnGap: "2rem" }}>
          {danceTypes.map((dance) => {
            return (
              <DanceItem
                danceTitle={dance.danceTitle}
                instructor={dance.instructor}
                cardImg={dance.cardImg}
                pageLink={dance.pageLink}
                imgPosition={dance.imgPosition}
                height={"350px"}
                maxWidth={"300px"}
              ></DanceItem>
            );
          })}
        </DanceContainer>
      </div>
      {/* end health benefits of dance */}

      <div className="h-auto flex flex-col sm:my-12">
        <p className="px-8 sm:px-32 text-base sm:text-xl pb-12 content-text mb-12">
          For those looking for internship/co-op opportunities or if you are
          looking for a career change check out our{" "}
          <a href="/internship" target="_blank" rel="noopener noreferrer">
            Internship Program
          </a>
          .
        </p>
      </div>
    </div>
  );
  // end dance page
};

export default ProgramsAndServicesPage;

const BellyDanceBtnContainer = styled.div`
  width: 100%;
  display: grid;
  justify-items: center;
`;

const BellyDanceBtn = styled.button`
  width: 60vw;
  /* transform: translateX(20vw); */
  height: 7vh;
  border-radius: 30px 30px 30px 30px;
  text-align: center;
  background: linear-gradient(30deg, slateblue, orange);
  font-weight: 700;
  font-size: 1.5rem;
  margin: 1.5rem;

  &:hover {
    color: white;
    background: #ff5722;
  }
  @media screen and (max-width: 600px) {
    width: 85vw;
  }
`;

const DanceContainer = styled.div`
  display: flex;
  flex-wrap: wrap !important;
  gap: 2rem;
  /* margin-left: 1rem;
  margin-right: 1rem; */
  justify-content: center;
  font-size: 1.25rem;
  max-width: 100%;
  margin: 1rem auto;

  @media (min-width: 768px) {
    column-gap: 5rem;
  }
`;

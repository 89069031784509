// bring in imports
import React, { useEffect, useState } from "react";

// dependency imports
import styled, { keyframes } from "styled-components";
import { Helmet } from "react-helmet";

// component imports

// icon for background
import Bg from "./faqIcon.svg";

// importing Questions array which is stored in separate file
import { Questions } from "./Questions";
import Question from "./Question";

// hash function used to hash object and can be used as key
import hash from "object-hash";

const FAQHeader = styled.div`
  height: 60vh;
  width: 100vw;
  display: flex;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    min-height: 80vh;
  }
`;

const FAQHeaderLeft = styled.div`
  width: 50%;
  background-image: url(${Bg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 75% 75%;
  z-index: 2;
  @media screen and (max-width: 768px) {
    height: 50vh;
    width: 100%;
  }
`;

const FAQHeaderRight = styled.div`
  width: 50%;
  display: flex;
  justify-content: left;
  align-items: center;
  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }
`;

// used to animate title "How we can help you?"
const animatedText = keyframes`
    0% { 
        opacity: 1; 
    }
	49% { 
        opacity: 1; 
    }
	50% { 
        opacity: 0; 
    }
	100% { 
        opacity: 0; 
    }
`;

const FAQTitle = styled.p`
  overflow: hidden;
  display: inline-block;
  width: 60%;
  font-weight: 700;
  font-size: 3.5rem;
  color: #324da1;
  text-shadow: 1px 0px 1px #ccc, 0px 1px 1px #eee, 2px 1px 1px #ccc,
    1px 2px 1px #eee, 3px 2px 1px #ccc, 2px 3px 1px #eee, 4px 3px 1px #ccc,
    3px 4px 1px #eee, 5px 4px 1px #ccc, 4px 5px 1px #eee, 6px 5px 1px #ccc,
    5px 6px 1px #eee, 7px 6px 1px #ccc;
  &:after {
    content: "";
    display: inline-block;
    background-color: white;
    margin-left: 6px;
    height: 25px;
    width: 4px;
    animation: ${animatedText} 0.4s infinite;
  }
  @media screen and (max-width: 768px) {
    font-size: 2.4rem;
    width: 80%;
    text-align: center;
  }
`;

const FAQMain = styled.div`
  margin-top: -15vh;
  z-index: 1;
`;

const FAQCategory = styled.div`
  margin-bottom: 5vh;
`;

const QuestionsSection = styled.div`
  min-height: 50vh;
  margin-top: -5vh;
  background: #f3f4f6;
  display: flex;
  flex-direction: column;
  padding: 0 15vw;
  @media screen and (max-width: 768px) {
    padding: 0 10vw;
    margin-top: 0vh;
  }
`;

const Category = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
  color: #324da1;
  margin-bottom: 2vh;
  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export default function FAQs() {
  const [questions, setQuestions] = useState(Questions);
  const categories = [
    "General",
    "Discovery Call",
    "Online Classes",
    "Workshops",
    "In-person classes",
  ];

  // runs only 1 time to animate page title - "How we can help you?"
  useEffect(() => {
    let i = 0;
    let tag = document.getElementById("text");
    let html = document.getElementById("text").innerHTML;
    /* eslint-disable no-unused-vars */
    let attr = tag.setAttribute("data", html);
    let txt = tag.getAttribute("data");
    let speed = 170;
    function typeWriter() {
      if (i <= txt.length) {
        document.getElementById("text").innerHTML = txt.slice(0, i + 1);
        i++;
        setTimeout(typeWriter, speed);
      }
    }
    typeWriter();
  }, []);

  return (
    <div>
      {/* begin react-helmet component */}
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Workshops offered through KellyKinetix which include mobility, fitness and dance training. KellyKinetix offers belly dancing and personal trainers in north york, Toronto, GTA and Ontario through online classes."
        />
        <meta
          name="keywords"
          content="faq, faq meaning, faq examples, benefits of physical activity, benefits of physical fitness, 10 benefits of physical activity, workout benefits, cardio vascular fitness, paul chek, smart goals examples, smart goals acronym, client testimonials, discovery call, gym instruction, training, working out, fitness classes, exercise classes near me, group fitness classes near me, workout classes, exercise classes, group workout classes near me, exercise groups near me, zumba exercise for beginners, exercise classes near me, group fitness classes near me, exercise classes, group workout classes near me, workout classes, zumba dance, circuit training, fitness classes near me, dance workout, workout classes near me, zumba, cpr, personal trainer, personal trainer downtown toronto, fit women, goodlife workout, housefit personal training toronto, personal trainer, trainer, physical trainer, personal trainer at home, female personal trainer, personal training studio, fitness classes, zumba, zumba dance, fitness classes near me, dance fitness, belly dance, belly dancing near me, belly dance class, personal trainers near me, fitness studio, fitness studio toronto, personal trainer, personal training studio, private trainer, personal fitness, studio north, health & fitness, personal training, fitness gym, health fitness, fitness centre, toronto fitness, gym in toronto, downtown toronto gym"
        />
        <title>FAQ</title>
      </Helmet>
      {/* end react-helmet component */}
      <div>
        <FAQHeader>
          <FAQHeaderLeft></FAQHeaderLeft>
          <FAQHeaderRight>
            <FAQTitle id="text"> How can we help you ?</FAQTitle>
          </FAQHeaderRight>
        </FAQHeader>
        <FAQMain>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="#f3f4f6"
              fillOpacity="1"
              d="M0,224L40,202.7C80,181,160,139,240,133.3C320,128,400,160,480,154.7C560,149,640,107,720,106.7C800,107,880,149,960,144C1040,139,1120,85,1200,64C1280,43,1360,53,1400,58.7L1440,64L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
            ></path>
          </svg>
          {/* 
              iteration through "categories" array which is followed by iteration through "questions" array 
              and only those questions are displayed whose category property is the same as the current category 
          */}
          <QuestionsSection>
            {categories.map((category) => (
              <FAQCategory key={hash(category)}>
                <Category> {category} </Category>
                {questions.map(
                  (question, index) =>
                    category === question.category && (
                      <Question
                        key={hash(question)}
                        setQuestions={setQuestions}
                        question={question}
                        index={index}
                      />
                    )
                )}
              </FAQCategory>
            ))}
          </QuestionsSection>
        </FAQMain>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#f3f4f6"
            fillOpacity="1"
            d="M0,0L80,37.3C160,75,320,149,480,149.3C640,149,800,75,960,53.3C1120,32,1280,64,1360,80L1440,96L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
          ></path>
        </svg>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import clsx from 'clsx';
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
// import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from "@material-ui/core/CardMedia";
// import CardContent from '@material-ui/core/CardContent';
import Typography from "@mui/material/Typography";
import laneway from "./images/laneway.jpg";

const useStyles = makeStyles((theme) => ({
  signUpButton: {
    border: "1px solid white",
    color: "white",
    borderRadius: "5px",
    padding: "14px 28px",
    fontSize: "16px",
    cursor: "pointer",
    "&:hover": {
      background: "rgba(231, 231, 231, 0.5)",
      color: "white",
    },
  },
}));

export default function DanceItem(props) {
  const height = props.height ? props.height : "450px";
  const maxWidth = props.maxWidth ? props.maxWidth : "400px";

  return (
    <a
      href={props.pageLink}
      target="_blank"
      rel="noopener noreferrer"
      className="w-4/5 md:w-1/3"
      style={{ maxWidth: `${maxWidth}` }}
    >
      <Card>
        <CardActionArea style={{ height: `${height}` }}>
          <CardMedia
            image={props.cardImg ? props.cardImg : laneway}
            style={{
              backgroundPosition: props.imgPosition,
              height: "80%",
              backgroundSize: "cover",
            }}
            title={props.danceTitle}
          />
          <Typography
            sx={{ fontSize: 18, padding: "24px 16px 0 16px" }}
            color="text.primary"
            gutterBottom
          >
            {props.danceTitle}
          </Typography>
          <Typography
            style={{ fontSize: 14, padding: "0 16px" }}
            color="text.secondary"
          >
            {props.instructor}
          </Typography>
        </CardActionArea>
      </Card>
    </a>
  );
}

import React from "react";
import styled from "styled-components";

const OrderItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  padding: 2rem 3rem;
  margin-bottom: 20px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 1rem;
  }
`;

const ItemTitle = styled.p`
  font-weight: 500;
  max-width: 80%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const ItemPrice = styled.div`
  font-weight: 600;
  color: #529c29;
  display: flex;
  height: 100%;
  align-items: center;
`;

const MultiplyIcon = styled.p`
  padding: 0 10px;
  color: black;
  font-size: 0.6rem;
  margin: 0;
`;

const ItemQty = styled.p`
  color: black;
  margin: 0;
`;

const OrderItem = ({ orderItem }) => {
  return (
    <OrderItemContainer>
      <ItemTitle> {orderItem.name}</ItemTitle>
      <ItemPrice>
        {orderItem.unit_amount.value}
        <MultiplyIcon> X </MultiplyIcon>
        <ItemQty> {orderItem.quantity}</ItemQty>
      </ItemPrice>
    </OrderItemContainer>
  );
};

export default OrderItem;

import React from "react";
import styled from "styled-components";

// hash function used to hash object and can be used as key
import hash from "object-hash";

// required for Swiper configuration
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";

const Slide = styled.div`
  opacity: 0.6;
  height: 100%;
  width: 100%;
`;

const MobileImg = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

const MobileHeader = ({ bgArray }) => {
  const params = {
    loop: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
  };

  return (
    <Swiper {...params}>
      {bgArray.map((bg, index) => (
        <Slide key={hash(bg)}>
          <MobileImg src={bg} alt="kellyKinetix merch image" />
        </Slide>
      ))}
    </Swiper>
  );
};

export default MobileHeader;

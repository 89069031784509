import React from "react";
import "./css/Thankyou.css";
import "./css/general.css";
export default function NewYearThankYouPage() {
  return (
    <>
      <section className="u-align-left u-clearfix thankyou-page" id="sec-6128">
        <div className="u-clearfix u-sheet u-sheet-1">
          <div className="u-container-style u-expanded-width-sm u-expanded-width-xs u-grey-5 u-group u-group-1">
            <div className="u-container-layout u-container-layout-1">
              <h1 className="u-align-center u-text u-text-default u-text-palette-4-base u-text-1">
                <b>Thank you for visiting KellyKinetix!&nbsp;</b>
              </h1>
              <p className="u-align-center u-heading-font u-text u-text-2">
                Subscribe and take a self-assessment to enter the contest
              </p>
              <p className="u-align-left-lg u-align-left-md u-align-left-sm u-align-left-xs u-custom-font u-heading-font u-text u-text-palette-4-base u-text-3">
                <b>Subscribe us</b>
              </p>

              <div className="u-form u-form-1">
                <form
                  action="https://kellykinetix.us20.list-manage.com/subscribe/post?u=f4ee6227092fe6979cd63b499&amp;id=8403097503"
                  method="post"
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  target="_blank"
                  className="u-clearfix u-form-horizontal u-form-spacing-0 u-inner-form"
                  source="custom"
                >
                  <div className="u-form-email u-form-group">
                    <label
                      htmlFor="email-737d"
                      className="u-form-control-hidden u-label"
                      wfd-invisible="true"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      placeholder="Enter a valid email address"
                      id="email-737d"
                      name="EMAIL"
                      className="u-border-5 u-border-white u-input u-input-rectangle u-white u-input-1"
                      required=""
                    />
                  </div>
                  <div className="u-form-group u-form-submit">
                    <input
                      type="submit"
                      value="Subscribe"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      className="u-active-black u-border-5 u-border-active-black u-border-hover-palette-4-base u-border-palette-2-base u-btn u-btn-submit u-button-style u-hover-palette-4-base u-palette-2-base u-text-active-white u-text-hover-white u-btn-1"
                    />
                  </div>
                  <div
                    className="u-form-send-message u-form-send-success"
                    wfd-invisible="true"
                  >
                    Thank you! You have successfully subscribed to us.
                  </div>
                  <div
                    className="u-form-send-error u-form-send-message"
                    wfd-invisible="true"
                  >
                    Something went wrong. Please try again.
                  </div>
                  <input
                    type="hidden"
                    value=""
                    name="recaptchaResponse"
                    wfd-invisible="true"
                  />
                </form>
              </div>
              <p className="u-align-left u-heading-font u-text u-text-palette-4-base u-text-4">
                <b> Take your self-assessment</b>
              </p>
              <a
                href="https://live.vcita.com/site/kellykinetix/leave-details"
                target="_blank"
                rel="noopener noreferrer"
                className="u-btn u-btn-round u-button-style u-hover-palette-4-base u-palette-2-base u-radius-2 u-btn-2"
              >
                Click Here&nbsp;
                <span className="u-icon u-text-white">
                  <svg
                    className="u-svg-content"
                    viewBox="0 0 512 512"
                    x="0px"
                    y="0px"
                    style={{
                      width: "1em",
                      height: "1em",
                    }}
                  >
                    <path d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068 c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557 l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104 c0.006-.006,0.011-.013,0.018-.019C513.968,262.339,513.943,249.635,506.134,241.843z"></path>
                  </svg>
                </span>
              </a>
              <p className="u-align-left u-heading-font u-text u-text-palette-4-base u-text-5">
                <b> J</b>
                <b>oin our Facebook Community</b>
              </p>
              <p className="u-align-left-sm u-align-left-xs u-align-right-lg u-align-right-md u-align-right-xl u-heading-font u-text u-text-default u-text-6">
                Winners of the contest will be announced on the KellyKinetix
                Facebook Page.{" "}
              </p>
              <a
                href="https://www.facebook.com/kellykinetix"
                target="_blank"
                rel="noopener noreferrer"
                className="u-btn u-btn-round u-button-style u-hover-palette-4-base u-palette-2-base u-radius-2 u-btn-3"
              >
                Click Here&nbsp;
                <span className="u-icon u-text-white">
                  <svg
                    className="u-svg-content"
                    viewBox="0 0 512 512"
                    x="0px"
                    y="0px"
                    style={{
                      width: "1em",
                      height: "1em",
                    }}
                  >
                    <path d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068 c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557 l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104 c0.006-.006,0.011-.013,0.018-.019C513.968,262.339,513.943,249.635,506.134,241.843z"></path>
                  </svg>
                </span>
              </a>
              <p className="u-align-left u-heading-font u-text u-text-palette-4-base u-text-4">
                <b> Book a free consultation</b>
              </p>
              <a
                href="https://calendly.com/info-kellykinetix/60minconsultation"
                target="_blank"
                rel="noopener noreferrer"
                className="u-btn u-btn-round u-button-style u-hover-palette-4-base u-palette-2-base u-radius-2 u-btn-2"
              >
                Click Here&nbsp;
                <span className="u-icon u-text-white">
                  <svg
                    className="u-svg-content"
                    viewBox="0 0 512 512"
                    x="0px"
                    y="0px"
                    style={{
                      width: "1em",
                      height: "1em",
                    }}
                  >
                    <path d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068 c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557 l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104 c0.006-.006,0.011-.013,0.018-.019C513.968,262.339,513.943,249.635,506.134,241.843z"></path>
                  </svg>
                </span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

// icon for arrow at header section
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

// pageNotFound component
import PageNotFound from "../../PageNotFound/pageNotFound";

const OrderCancelContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 80vh;
  background: #f9f9f9;
`;

const SuccessIcon = styled.div`
  color: #ff3b30;
  font-size: 4rem;
  @media screen and (max-width: 768px) {
    font-size: 3rem;
  }
`;

const PageTitle = styled.h3`
  font-size: 2rem;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 40px;
  @media screen and (max-width: 768px) {
    text-align: center;
    font-size: 1.6rem;
  }
`;

const OrderCancel = () => {
  const location = useLocation();

  return location.state !== undefined ? (
    <OrderCancelContainer>
      <SuccessIcon>
        <FontAwesomeIcon icon={faTimesCircle} />
      </SuccessIcon>
      <PageTitle>payment cancelled</PageTitle>
    </OrderCancelContainer>
  ) : (
    <PageNotFound />
  );
};

export default OrderCancel;

import React from "react";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";

const Slider = ({ children }) => {
  const params = {
    effect: "coverflow",
    grabCursor: true,
    breakpoints: {
      // when window width is >= 480px
      480: {
        slidesPerView: 1,
        centeredSlides: false,
      },
      // when window width is >= 768px
      768: {
        slidesPerView: 2,
        centeredSlides: true,
      },
    },
    loop: true,
    coverflowEffect: {
      rotate: 0,
      stretch: 0, // Stretch space between slides (in px)
      depth: 700, // Depth offset in px (slides translate in Z axis)
      modifier: 1, // Effect multiplier
      slideShadows: false, // Enables slides shadows
    },
    autoplay: {
      delay: 8000,
      disableOnInteraction: true,
    },
    pagination: {
      el: ".swiper-pagination",
    },
  };

  return <Swiper {...params}>{children}</Swiper>;
};

export default Slider;

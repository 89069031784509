import React, { useCallback } from "react";
import styled from "styled-components";
import produce from "immer";

// icons - arrow down, up
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

// used to convert strings containing HTML into React components
import ReactHtmlParser from "react-html-parser";

const QuestionContainer = styled.div`
  width: 70vw;
  padding: 1rem 2rem;
  background: white;
  border-bottom: 3px solid #324da1;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-bottom: 3vh;
  -webkit-tap-highlight-color: transparent;
  @media screen and (max-width: 768px) {
    width: 80vw;
  }
`;

const QuestionItself = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const QuestionTitle = styled.p`
  font-size: 1.4rem;
  color: #324da1;
  @media screen and (max-width: 768px) {
    font-size: 1rem;
    width: 80%;
  }
`;

const QuestionAnswer = styled.div`
  overflow: hidden;
  max-height: ${(props) => props.maxHeightProp};
  transition: all 0.5s ease-out;
  font-size: 1.4rem;
  margin-top: ${(props) => (props.maxHeightProp === "0px" ? "0px" : "2vh")};
  scroll-behavior: smooth;
  color: #737373;
  font-family: "Poppins", sans-serif;
  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Question = ({ index, question, setQuestions }) => {
  /* function used to show/hide answer by setting maxHeight of component to "300vh" 
  if answer is hidden, or to "0px" if answer is visible */
  const showAnswer = useCallback((questionNumber) => {
    setQuestions(
      produce((draftState) => {
        draftState[questionNumber].maxHeight =
          draftState[questionNumber].maxHeight === "0px" ? "300vh" : "0px";
      })
    );
    //eslint-disable-next-line
  }, []);

  return (
    <QuestionContainer>
      <QuestionItself onClick={() => showAnswer(index)}>
        <QuestionTitle>{question.question}</QuestionTitle>
        <FontAwesomeIcon
          icon={question.maxHeight === "0px" ? faChevronDown : faChevronUp}
          style={{ marginTop: "8px" }}
        />
      </QuestionItself>
      <QuestionAnswer maxHeightProp={question.maxHeight}>
        {ReactHtmlParser(question.answer)}
      </QuestionAnswer>
    </QuestionContainer>
  );
};

export default Question;

export const testimonials = {
  fitnessTestimonialData: [
    {
      client: "Rhoda",
      message: `Kelly changed my life! After a lifetime of yo-yoing, working with Kelly helped me find a mental and physical strength I still tap into today. To say that I was extremely satisfied with my results is an understatement. She helped me push my body to limits I never thought I could reach and helped me reframe my eating habits. I wouldn’t be where I am today without Kelly. I highly recommend her!`,
    },
    {
      client: "Wendy",
      message: `I have been able to keep over 20lbs off over the past two years!. I have a whole new body shape and look healthier overall!. I can now wear clothes that I was never able to wear before. No more loose fitting clothes for me!!

        My motivation levels to work on healthier nutrition and losing weight have increased, and as a result I have much more energy and less chronic back fatigue. Playing on the floor and lifting my grandchildren is much more enjoyable.

        Thank you to Kelly for creating an excellent private training atmosphere that allows me to get the best personal training service and results!`,
    },
    {
      client: "Jen",
      message: `I can’t express how much my life has changed since meeting Kelly. I am a 40+ woman who has had type 1 diabetes for 33 years and have been overweight most of my life. More than the weight she has helped me lose, it’s the way I feel, the way I move more easily, the way I am now living my life. With Kelly’s nursing background, her nurturing nature and consistent training techniques have made it so easy to enjoy exercise...which is a statement I NEVER thought I would say. I highly recommend Kelly for people who want to make a change in their lives.`,
    },
    {
      client: "Karti",
      message: `I first started training with Kelly after recovering from an illness that weakened my muscles and joints. Initially she taught me the basics in strengthening to perform exercises that will eventually assist in my recovery. With consistent training we progressed to the point where I not only saw physical changes in my body, but I felt strong and confident. Kelly is not your average personal trainer, she’s vivacious and inspirational and she has supported me through some difficult times by teaching me about mindset and keeping me motivated to achieve my fitness goals. My life has changed because of her endless support and I’m happy that she’s part of my fitness journey. I highly recommend Kelly as a personal trainer!`,
    },
    {
      client: "Susan",
      message: `Kelly You’re awesome! I love training with you! It’s been so many years now....I’ve lost track! You’ve always been so upbeat, encouraging and helpful, always getting me to perform to my best possible level, but also respectful when I’ve hit my limits. Being a dancer yourself, you’ve always been in tune with my needs. Our training sessions are always fun and uplifting! It’s been great being on this journey with you! You’ve kept me in shape for my dancing and fit for my life. You’re not just a great trainer but a wonderful friend! Thank you!`,
    },
    {
      client: "Bonnie",
      message: `Kelly listens, watches your body movements, assesses your needs vs your wants with fitness and builds a plan just for you. Tweaking as required. I recommend Kelly as an online personal trainer. She is knowledgeable about fitness and works with you holistically to achieve your goals. Don’t let her quiet demeanour fool you, she works hard to work you hard and you won’t regret it.`,
    },
    {
      client: "Rita",
      message: `Kelly is a fantastic, fun and knowledgeable trainer, able to customize your training and wellness programs to fit your needs. Plus her classes and workshops are great, and some of them are even free. Highly recommend, if you want to maintain, enhance, or reboot your wellness journey.`,
    },
    {
      client: "Daniela",
      message: `I did the More to the Core workshop yesterday with KellyKinetics, and I am feeling it in my core big time today. One of the main reasons I love working with Kelly is that she always makes sure you are using and activating the proper muscles for each move. So important! Her knowledge and experience is vast and always growing. I’ve worked with Kelly over the years both in fitness and belly dancing and she genuinely cares about her clients. I highly recommend her and her services.`,
    },
    {
      client: "Patti",
      message: `I started working with Kelly so that I could gain confidence in adding physical activity to my life. Kelly was terrific! She met me where I was and worked with my skills and limitations to help me become stronger and more confident. I have a lot of injuries and underlaying health concerns, so Kelly’s experience in health care and her dedication to ongoing education gives me a lot of confidence that every class, workshop, and session with her will help me get stronger while minimizing the risk of injury.

        Finally, Kelly is so much fun to work with. I laugh a lot in every class and session I do with her. Kelly has built a wonderful community of women who are dedicated to staying healthy. I look forward to all of my classes with Kelly, even if I would rather skip the workout and spend the night on the couch.`,
    },
    {
      client: "Lisa",
      message: `Kelly can tap into your motivation and bring about change. She operates out of integrity, knowledge and passion. She is an avid lifelong learner and brings this knowledge to her clients. As a former client, I highly recommend Kelly!`,
    },
  ],
  danceTestimonialData: [
    {
      client: "Daniela",
      message: `Ok, so I think I’m hooked on belly dancing! lol I’ve noticed lately I’ll be doing something, for example reading a book, and I’ll start to practice a move without thinking about what I am doing. Things are starting like my body is in flow rather than awkward and unsmooth. I’m going to continue classes! On another note, I just wanted to say thank you for creating such a welcoming & fun environment. I’ve had such a positive experience!`,
    },
    {
      client: "Patricia",
      message: `Despite having taken classes before, I have learnt more from this one session than I ever did in previous lessons [elsewhere], and at a lesser time! I appreciate the one-on-one encouragement during classes. I enjoy your energy and dedication. Thank You!`,
    },
    {
      client: "Makia",
      message: `I had a wonderful time learning from Caliana’s workshop. Caliana is a phenomenal listener and educator. During the workshop, I felt free to examine her movements and come to a place of self understanding. For the longest time I had a tough time figuring out my dance personality until this particular workshop... a light hearted, comedic dancer. What a relief! While Caliana was demonstrating her sultry, sassy and inspired moves, I was right behind exploring how that translated for me, that is the essence of learning. Thanks Caliana!`,
    },
    {
      client: "Margaret",
      message: `Thank you Caliana. I had a lot of fun at last night’s class and look forward to the coming weeks. You’re an awesome teacher who made the class inviting rather than intimidating.`,
    },
    {
      client: "Judy",
      message: `Kelly is an outstanding instructor with a keen eye for detail. Kelly cares deeply about her students, and helps them excel at every level. I am looking forward to working with Kelly soon, and do not hesitate to recommend her.`,
    },
    {
      client: "Linda",
      message: `When I attended an evening of belly dance demonstration(s) with Kelly, I was impressed by the range of skills in the room, and the evening was well coordinated and warmly presented by Kelly. In addition to enjoying the dance and feeling that I was in a place where I could learn, there was a deeply warm and joyous feeling about the evening. It was memorable.`,
    },
  ],
};

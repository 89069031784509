import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

// icon for "Approved" order
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

// hash function used to hash object and can be used as key
import hash from "object-hash";

// individual item component
import OrderItem from "./orderItem";

// pageNotFound component
import PageNotFound from "../../PageNotFound/pageNotFound";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  padding: 10vh 0;
  background: #f9f9f9;
`;

const SuccessIcon = styled.div`
  color: #529c29;
  font-size: 4rem;
`;

const PageTitle = styled.h3`
  font-size: 2rem;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 10px;
  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

const PageSubtitle = styled.p`
  font-size: 1rem;
  color: #737373;
  margin-bottom: 40px;
  @media screen and (max-width: 768px) {
    text-align: center;
    padding: 0 1rem;
  }
`;

const UserEmail = styled.b`
  font-weight: 700;
`;

const OrderTotal = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 80%;
  padding: 2rem 3rem 0 3rem;
  gap: 10%;
  font-size: 1.2rem;
`;

const TotalAmount = styled.div`
  font-weight: 600;
`;

const PaypalThankYou = () => {
  const location = useLocation();
  const [orderItems, setOrderItems] = useState([]);
  const [payerData, setPayerData] = useState(null);
  const [orderData, setOrderData] = useState({});

  useEffect(() => {
    if (location.state !== undefined) {
      const orderData = location.state.orderData;
      setPayerData(orderData.payer.email_address);
      setOrderItems(orderData.purchase_units[0].items);
      setOrderData({
        subtotal: orderData.purchase_units[0].amount.breakdown.item_total.value,
        tax: orderData.purchase_units[0].amount.breakdown.tax_total.value,
        shipping: orderData.purchase_units[0].amount.breakdown.shipping.value,
        total: orderData.purchase_units[0].amount.value,
        currency: orderData.purchase_units[0].amount.currency_code,
      });
    }
    // eslint-disable-next-line
  }, []);

  return location.state !== undefined ? (
    <PageContainer>
      <SuccessIcon>
        <FontAwesomeIcon icon={faCheckCircle} />
      </SuccessIcon>
      <PageTitle>Thank You for your purchase</PageTitle>
      <PageSubtitle>
        We have sent the order confirmation details to
        <UserEmail> {payerData} </UserEmail>
      </PageSubtitle>
      {orderItems.map((orderItem) => (
        <OrderItem key={hash(orderItem)} orderItem={orderItem} />
      ))}
      <OrderTotal>
        <p>Subtotal: </p>
        <TotalAmount> ${orderData.subtotal} </TotalAmount>
      </OrderTotal>
      <OrderTotal>
        <p>Tax: </p>
        <TotalAmount> ${orderData.tax} </TotalAmount>
      </OrderTotal>
      <OrderTotal>
        <p>Shipping: </p>
        <TotalAmount> ${orderData.shipping} </TotalAmount>
      </OrderTotal>
      <OrderTotal>
        <p>Total: </p>
        <TotalAmount> ${orderData.total} </TotalAmount>
      </OrderTotal>
    </PageContainer>
  ) : (
    <PageNotFound />
  );
};

export default PaypalThankYou;

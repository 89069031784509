import React, { useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";

// React-helmet for SEO
import { Helmet } from "react-helmet";

// import reusable JSON-LD SEO data
import { jsonLD } from "../HomePage/index";
import { metaTagConstants } from "../HomePage/index";

const ClassPage = () => {
  const classTags = {
    ...metaTagConstants,
    url: "https://www.kellykinetix.com/classes",
    description:
      "KellyKinetix offers a wide amount of fitness activites. To get fit, classes include belly dance essentials, dance choreography, body resilience, mobility, and technique skill and drill classes.",
    title: "Classes",
  };

  // sets the visibility of class cards
  const [classVisible, setClassVisible] = useState({
    bellyDanceEssentials: false,
    mixedLevelBellyDance: false,
    techniqueSkillAndDrill: false,
    choreography: false,
    bodyResilience: false,
    mobility: false,
  });
  return (
    // begin class page
    <div className="h-auto flex flex-col">
      {/* begin react-helmet component */}
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Assorted dance, mobility and fitness classes offered through KellyKinetix, fitness and dance instruction company. KellyKinetix offers belly dancing and personal trainers in north york, Toronto, GTA and Ontario through online classes."
        />
        <meta
          name="keywords"
          content="gym instruction, training, working out, fitness classes, exercise classes near me, group fitness classes near me, workout classes, exercise classes, group workout classes near me, exercise groups near me, zumba exercise for beginners, exercise classes near me, group fitness classes near me, exercise classes, group workout classes near me, workout classes, zumba dance, circuit training, fitness classes near me, workout classes near me , fit women, goodlife workout, housefit personal training toronto, personal trainer, trainer, fitness classes, zumba, zumba dance, fitness classes near me, dance fitness, belly dance, belly dancing near me, belly dance class, personal trainers near me, fitness studio, fitness studio toronto, personal trainer, personal training studio, private trainer, personal fitness, studio north, health & fitness, personal training, fitness gym, health fitness, fitness centre, toronto fitness, gym in toronto, downtown toronto gym, in love yourself, love your self, love yourself, my self love, belly dance toronto, belly dancing classes toronto, mind body connection, mind body, mind and body, body mind, meditation, meditation music, sleep music, mindfulness meditation, guided meditation, mindfulness meditation, mindfulness, dance lessons, dance classes near me, dance lessons near me, dance schools near me, belly dance classes near me, benefits of dancing, benefits of dance, cardiovascular fitness, benefits of physical activity, benefits of physical fitness, 10 benefits of physical activity, workout benefits, cardio vascular fitness, paul chek, smart goals examples, smart goals acronym, client testimonials, mobility, mobility plus, mobility, resilience, resiliency, resilience examples, mental resilience, myofascial release, myofascial, myofascial release near me, myofascial massage, myofascial release therapy, myofascial release massage"
        />

        <title>Classes</title>

        {/* meta tags for social media sharing */}
        <meta property="og:title" content={classTags.title} />
        <meta property="og:url" content={classTags.url} />
        <meta property="og:description" content={classTags.description} />
        <meta property="og:locale" content={classTags.locale} />
        <meta property="og:image" content={"%PUBLIC_URL%/" + classTags.image} />

        {/* add json-ld for seo through script tag */}
        <script type="application/json+ld">{JSON.stringify(jsonLD)}</script>
      </Helmet>
      {/* end react-helmet component */}

      {/* beginning of JSON-LD for SEO */}
      <script type="application/ld+json">
        {/* {{
          "@context": "https://schema.org",
          "@type": "LocalBusiness",
          "name": "KellyKinetix Classes",
          "description": "Assorted dance, mobility and fitness classes offered through KellyKinetix, fitness and dance instruction company. Includes belly dance and body resilience",
          "url": `${window.location.href}`,
          "address": {
            "@type:": "PostalAddress",
            "addressLocality": "Greater Toronto Area",
            "addressRegion": "ON",
            "addressCountry": "CA"
          }

        }} */}
      </script>

      {/* begin dance class description section */}
      {/* begin header */}
      <span className="text-xl sm:text-3xl font-bold text-center header-text my-12">
        CLASS DESCRIPTIONS
      </span>
      {/* end header */}

      {/* begin belly dance essentials description */}
      <div className="h-auto mx-4 sm:mx-12 sm:mb-12 flex flex-col justify-center bg-gradient-to-r from-indigo-900 to-indigo-500 pt-8 sm:py-8">
        <span className="flex justify-between align-center text-lg sm:text-2xl font-semibold description-text-header py-4 sm:py-8 px-4 sm:px-32 bg-indigo-900 hover:bg-purple-900">
          BELLY DANCE ESSENTIALS
          <MdKeyboardArrowDown
            onClick={() =>
              setClassVisible({
                ...classVisible,
                bellyDanceEssentials: !classVisible.bellyDanceEssentials,
              })
            }
          />
        </span>
        {/* blank div here used to render class fragment */}
        {classVisible.bellyDanceEssentials && (
          <div className="mt-8 px-8">
            <span className="sm:text-xl self-center sm:self-start sm:py-12 sm:px-24 description-text font-bold">
              Fridays 6:00 - 7:00 pm online through zoom
            </span>
            <br /> <br />
            <span className="sm:text-xl self-center sm:self-start sm:px-24 sm:py-8 description-text font-bold">
              This class is suitable for people who:
            </span>
            <ul className="sm:px-40 py-8 sm:text-xl description-text list-none sm:list-disc">
              <li className="py-2">
                Are complete beginners to any type of dance
              </li>
              <li className="py-2">
                Current belly dancers who are looking for a new approach to
                learning
              </li>
              <li className="py-2">
                Dancers of other styles looking for a new way to learn moving
                their body to bring an extra essence to your current dance art
              </li>
              <li className="py-2">
                People who want a different and fun alternative to becoming fit
              </li>
              <li className="py-2">
                People who want to reconnect to their divine feminine selves
                through a new mind body connection experience
              </li>
            </ul>
            <p className="pb-4 sm:px-24 sm:text-xl description-text">
              The beauty of Bellydance ... no matter what age, shape, size or
              gender you are, my classes are for you! That is the beauty of
              Bellydance ... it is for everyone!
            </p>
            <p className="pb-4 sm:px-24 sm:text-xl description-text">
              While belly dance is an Art, you will learn it more from the
              perspective of fitness and anatomy, which muscles work to create
              each movement, and how that feels.
            </p>
            <p className="pb-4 sm:px-24 sm:text-xl description-text">
              Learn the essential dance building blocks of this beautiful
              artform (posture and alignment, arms and hands, basic shimmies of
              upper and lower body, basic ooey gooey moves, basic accented
              moves, and depending on the overall level of the classes learning,
              maybe more!) We will start with a dynamic warm up to ensure the
              body is well lubricated, warmed up and ready for action!
            </p>
            <p className="pb-4 sm:px-24 sm:text-xl description-text">
              We will always review what was learned in the class before and
              move forward until you have a basic repertoire under your belt at
              which time you may want to discover more.
            </p>
            <p className="pb-4 sm:px-24 sm:text-xl description-text">
              You will learn about isolation, ooey-gooey movements, accented
              movements, shimmies, and basic travelling steps. Your instructor
              will tell you what the move/step is called, demonstrate it, break
              it down, then get people to practice. After that, we put those
              movements into a small combo/just dance to practice and depending
              how participants are progressing your instructor may throw in a
              thing or two to challenge you a bit further. Classes always end
              with a cool down.
            </p>
          </div>
          // end belly dance essentials section
        )}
      </div>

      {/* begin mixed level belly dance description */}
      <div className="h-auto mx-4 sm:mx-12 sm:mb-12 flex flex-col justify-center bg-gradient-to-r from-indigo-900 to-indigo-500 pt-8 sm:py-8">
        <span className="flex justify-between align-center text-lg sm:text-2xl font-semibold description-text-header py-4 sm:py-8 px-4 sm:px-32 bg-indigo-900 hover:bg-purple-900">
          MIXED LEVEL BELLY DANCE
          <MdKeyboardArrowDown
            onClick={() =>
              setClassVisible({
                ...classVisible,
                mixedLevelBellyDance: !classVisible.mixedLevelBellyDance,
              })
            }
          />
        </span>
        {/* blank div here used to render class fragment */}
        {classVisible.mixedLevelBellyDance && (
          <div className="mt-8 px-8">
            <span className="sm:text-xl self-center sm:self-start sm:py-12 sm:px-24 description-text font-bold">
              Wednesdays 7:30 - 8:30 pm Hybrid - (online through zoom and
              in-studio near Mount Pleasant and Merton)
            </span>
            <br /> <br />
            <p className="pb-4 sm:px-24 sm:text-xl description-text">
              This class is very exciting for everyone! People in this class are
              at various levels from beginners to elementary/intermediate. Some
              have enough under their belt to learn choreography, and some love
              a challenge! Wherever you are, it will be fun! For those who have
              less belly dance experience, it's advisable to take Belly dance
              essentials concurrent to Mixed level.
            </p>
            <p className="pb-4 sm:px-24 sm:text-xl description-text">
              Each class will start with a dynamic warm up, then we will move
              into some review of previously learned movements for belly dance.
              Then we move into learning techniques for new steps that will be
              in the choreography. Learn a small portion of choreography in each
              class, review and practice. Depending on time, there may be a
              chance to just dance. Every class will have a cool down with
              stretches
            </p>
            {/*<p className="pb-4 sm:px-24 sm:text-xl description-text">*/}
            {/*    Choreography to - Sahra Intro (Sami Nossair Orchestra) with Sahra*/}
            {/*    Saidi (Gamal Goma)*/}
            {/*</p>*/}
            <p className="pb-4 sm:px-24 sm:text-xl description-text">
              We also review both essential movements as well as beginner moves.
              Step by step, we are moving towards more intermediate moves.
            </p>
            <p className="pb-4 sm:px-24 sm:text-xl description-text">
              In all classes/workshops you are encouraged to ask questions,
              voice concerns or just make comments. It makes your experience
              that much better.
            </p>
            <p className="pb-4 sm:px-24 sm:text-xl description-text">
              In 2023 we are working on Zills (finger cymbals), Basic rhythms
              for middle eastern dance, as well as sticking in a small amount of
              basic level flamenco so that students can spend a little bit of
              time working with Flamenco Belly Dance Fusion. Once we've spent
              time in these 3 areas, a choreography will be learned.
            </p>
            <p className="pb-4 sm:px-24 sm:text-xl description-text">
              Students are given various opportunities to share what they've
              been learning through performance
            </p>
            <p className="pb-4 sm:px-24 sm:text-xl description-text">
              **All classes and workshops are based on Hadia's "Body Logic"
              techniques and principles. No prior music or dance experience is
              required to enter the Belly Dance Essentials level.**
            </p>
          </div>
          // end mixed level belly dance  section
        )}
      </div>
      {/* begin technique skill and drill description */}
      {/*
      <div className="h-auto mx-4 sm:mx-12 sm:mb-12 flex flex-col justify-center bg-gradient-to-r from-indigo-900 to-indigo-500 pt-8 sm:py-8">
        <span className="flex justify-between align-center text-lg sm:text-2xl font-semibold description-text-header py-4 sm:py-8 px-4 sm:px-32 bg-indigo-900 hover:bg-purple-900">
          TECHNIQUE SKILL AND DRILL
          <MdKeyboardArrowDown
            onClick={() =>
              setClassVisible({
                ...classVisible,
                techniqueSkillAndDrill: !classVisible.techniqueSkillAndDrill,
              })
            }
          />
        </span>
        {classVisible.techniqueSkillAndDrill && (
          <div className="mt-8 px-8">
            <span className="sm:text-xl self-center sm:self-start sm:py-12 sm:px-24 description-text font-bold">
              Mondays 7:30-8:30pm (time/day subject to change) online through
              zoom
            </span>

            <p className="py-8 sm:px-24 sm:text-xl description-text">
              This class is best if you have at least 3-6 months in Belly dance
              essentials under your belt and are looking for a challenge. You
              can choose to take this class also concurrently with Belly dance
              essentials. This class was developed after student feedback.
              Continue to work on technique and then practice what we have been
              working on by following your instructor through various dancing
              and styles of music. The idea is our current students have been
              interested in learning how one move can really connect into
              another by following along and seeing what is possible.
            </p>
            <p className="pb-4 sm:px-24 sm:text-xl description-text">
              Each class will start with a dynamic warm up, then we will move
              into some review of previously learned movements for belly dance.
              Then we move into learning techniques for new steps.
            </p>
            <p className="pb-4 sm:px-24 sm:text-xl description-text">
              Each class plan will cover the following depending on level of the
              class:
            </p>
            <ul className="sm:px-40 pb-8 sm:text-xl description-text list-none sm:list-disc">
              <li className="py-2">Essential movement review and repetition</li>
              <li className="py-2">
                Beginner-level movement review and repetition
              </li>
              <li className="py-2">Intermediate learning and repetition</li>
            </ul>
            <p className="pb-4 sm:px-24 sm:text-xl description-text">
              Every class will end with a cooldown/stretch period. It is
              recommended to have a veil for this class.
            </p>
          </div>
          // end technique skill and drill description
        )}
      </div>
        */}

      {/* begin body resilience description */}
      <div className="h-auto mx-4 sm:mx-12 sm:mb-12 flex flex-col justify-center bg-gradient-to-r from-indigo-900 to-indigo-500 pt-8 sm:py-8">
        <span className="flex justify-between align-center text-lg sm:text-2xl font-semibold description-text-header py-4 sm:py-8 px-4 sm:px-32 bg-indigo-900 hover:bg-purple-900">
          BODY RESILIENCE
          <MdKeyboardArrowDown
            onClick={() =>
              setClassVisible({
                ...classVisible,
                bodyResilience: !classVisible.bodyResilience,
              })
            }
          />
        </span>
        {/* blank div here used to render class fragment */}
        {classVisible.bodyResilience && (
          <div className="mt-8 px-8">
            <p className="sm:text-xl self-center sm:self-start py-0 sm:py-0 sm:px-24 description-text font-bold">
              Tuesdays 7:00 - 8:00 pm online through zoom <br />
            </p>

            <ul className="sm:px-40 py-0 sm:text-xl description-text list-none sm:list-disc">
              <li className="py-2">
                Are you finding daily life becoming more difficult than it used
                to be?
              </li>
              <li className="py-2">
                Do you wish you had better range of motion in your shoulders and
                hips?
              </li>
              <li className="py-2">
                Do you find that you have back pain or knee pain?
              </li>
              <li className="py-2">
                Would you like to be more active but feel like you can’t move
                well enough?
              </li>
              <li className="py-2">
                Are you already an active person in sport, dance or general
                fitness but are finding if you were just a little more flexible
                you could get to the next level?
              </li>
            </ul>
            <p className="pb-4 sm:px-24 sm:text-xl description-text">
              You have come to the right place! I am a Certified Personal
              Trainer with Flexibility Trainer Certification and a background in
              Registered Nursing here to offer you a path to a resilient body.
            </p>
            <p className="pb-4 sm:px-24 sm:text-xl description-text">
              This class is good for all levels of flexibility and mobility.
              Based on your baseline movements/mobility/flexibility and
              threshold for discomfort in the first class I will help everyone
              to work towards their full body mobility and flexibility goals.
            </p>
            <p className="pb-4 sm:px-24 sm:text-xl description-text">
              Even though I am a very active person myself with a high level of
              fitness,I have my limitations. I will work alongside you every
              step of the way and as a group we will see and feel the changes
              from week to week. This class is ongoing. I will teach you how to
              do everything safely and effectively. Breathing methods are
              taught, and you will learn to determine what the difference
              between “good pain and bad pain” are.
            </p>
            <p className="pb-4 sm:px-24 sm:text-xl description-text">
              In order to feel as though you are thriving through life, mobility
              throughout our body at the different joints is so important. If
              you are a dancer at the age of 40+, this class is very important
              for increasing the longevity of your dancing life. Learning the
              techniques and skills in this class well enough to keep them up at
              home on a regular basis, will be the difference in your knee, hip,
              shoulder and foot health. Learn myofascial tissue release methods,
              various movement drills, stretches and strengthening techniques
              that will help your body feel revitalized and ready to take on the
              world. This class is great for the average person of any age, as
              well as athletes/dancers
            </p>
            <p className="pb-4 sm:px-24 sm:text-xl description-text">
              Learn proper breathing from the diaphragm, myofascial tissue
              release methods, various movement drills, stretches and
              strengthening techniques that will help your body feel revitalized
              and ready for more!
            </p>
            <p className="pb-4 sm:px-24 sm:text-xl description-text">
              **Required for this class - foam roller, lacrosse ball (1-2), yoga
              mat** <br />
              **depending on strength exercises done possibly minimal equipment,
              however bodyweight exercises only are often done)**
            </p>
          </div>
          // end body resilience description
        )}
      </div>

      {/* begin mobility description */}
      <div className="h-auto mx-4 sm:mx-12 sm:mb-12 flex flex-col justify-center bg-gradient-to-r from-indigo-900 to-indigo-500 py-8">
        <span className="flex justify-between align-center text-lg sm:text-2xl font-semibold description-text-header py-4 sm:py-8 px-4 sm:px-32 bg-indigo-900 hover:bg-purple-900">
          MOBILITY
          <MdKeyboardArrowDown
            onClick={() =>
              setClassVisible({
                ...classVisible,
                mobility: !classVisible.mobility,
              })
            }
          />
        </span>
        {/* blank div here used to render class fragment */}
        {classVisible.mobility && (
          <div className="mt-8 px-8">
            <p className="sm:text-xl self-center sm:self-start py-0 sm:py-0 sm:px-24 description-text font-bold">
              Thursdays 8:00 - 9:00 am online through Zoom
              <br />
            </p>
            <p className="pb-4 py-4 sm:px-24 sm:text-xl description-text">
              Move better, feel better, look better! Reduce stress, pain and
              stiffness.
            </p>
            <p className="pb-4 sm:px-24 sm:text-xl description-text">
              After a long day of work at home or at your workplace it is great
              to finish the day off feeling relaxed, and ready for a good
              night's sleep. Since sleep and decreased stress are so important
              to keeping our immune systems boosted, this class is important on
              a regular basis.
            </p>
            <p className="pb-4 sm:px-24 sm:text-xl description-text">
              This class is similar to Body Resilience however the main focus is
              myofascial release, movement/mobility and stretching sequences. No
              strength exercises are incorporated in this class.
            </p>
            <p className="pb-4 sm:px-24 sm:text-xl description-text">
              Main benefits or outcomes are feeling better, relaxation, getting
              rid of tightness/discomfort in the body, excellent if it is a
              later night class as it helps promote better sleep. If done as a
              morning class it helps people feel energized for the day ahead.
            </p>
            <p className="pb-4 sm:px-24 sm:text-xl description-text">
              Class Structure: <br /> <br />
              Free flowing warm-up right into foam rolling (myofascial release -
              you will need a foam roller and lacrosse ball at minimum). After
              we will go through a variety of mobility exercises and stretches
              for the full body. If at the beginning of class, participants have
              a specific need that night, let me know right at the beginning and
              I will focus on helping that problem. Everyone will benefit from
              the skill/knowledge learned when an individual has a specific
              issue. Participants are encouraged to ask questions throughout. As
              well, always speak up if there is something you are concerned
              about
            </p>
            <p className="pb-4 sm:px-24 sm:text-xl description-text">
              **Required for this class - foam roller, lacrosse ball (1-2), yoga
              mat**
            </p>
          </div>
          // end mobility description
        )}
      </div>
      {/* end dance class description section */}
    </div>
    // end class page
  );
};

export default ClassPage;

import React from "react";

// component for desktop navigation bar
import DesktopNav from "./desktopNav";

// component for mobile navigation bar
import MobileNavbar from "./mobileNavigation/mobileNavbar";

// shopping cart component
import ShoppingCart from "../shopping-cart/shoppingCart";

// custom hook to determine current width of the screen
import useCurrentWidth from "../customHooks/resizeHook";

const NavigationBar = () => {
  // sued to determine current width of the screen
  const resolution = useCurrentWidth();

  return (
    <div
      className={`bg-gray-100 flex  w-full flex-col sm:flex-row items-center justify-center sm:justify-between px-7`}
    >
      {resolution > 768 ? <DesktopNav /> : <MobileNavbar />}
      <ShoppingCart />
    </div>
  );
};

export default NavigationBar;

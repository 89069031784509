import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons";

const TestimonialContainer = styled.div`
  width: 100%;
  min-height: 280px;
  max-height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 2.6rem;
  color: #324da1;
  background: #fff;
  border: 3px solid #324da1;
  font-size: 1rem;
  margin: auto;
  @media screen and (max-width: 768px) {
    background: transparent;
    border: none;
    padding: 1.4rem;
    color: #f3f4f6;
    align-items: ${(props) => props.alignment};
  }
`;

const Icon = styled.div`
  font-size: 1.6rem;
  opacity: 0.6;
  @media screen and (max-width: 768px) {
    color: #c5c9cb;
    opacity: 1;
  }
`;

const TestimonialMessage = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: center;
  margin: 14px 0 30px 0;
  @media screen and (max-width: 768px) {
    -webkit-line-clamp: unset;
    text-align: ${(props) => props.alignment};
    margin: 14px 0;
    font-weight: 500;
    letter-spacing: 1.2px;
    padding-bottom: 2vh;
    border-bottom: 2px #c5c9cb solid;
  }
`;

const TestimonialAuthor = styled.p`
  font-size: 1.4rem;
  font-weight: 700;
  @media screen and (max-width: 768px) {
    color: #c5c9cb;
  }
`;

export const SingleTestimonial = (props) => {
  return (
    <TestimonialContainer
      alignment={props.index % 2 === 0 ? "flex-start" : "flex-end"}
    >
      <Icon>
        <FontAwesomeIcon icon={faQuoteRight} />
      </Icon>
      <TestimonialMessage alignment={props.index % 2 === 0 ? "left" : "right"}>
        {props.message}
      </TestimonialMessage>
      <TestimonialAuthor>{props.client}</TestimonialAuthor>
    </TestimonialContainer>
  );
};

// initial state for Redux state
const initialState = {
  menuState: false,
};

export default function menuReducer(state = initialState, action) {
  switch (action.type) {
    case "OPEN_MENU":
      return {
        ...state,
        menuState: true,
      };
    case "CLOSE_MENU":
      return {
        ...state,
        menuState: false,
      };
    default:
      return { ...state };
  }
}

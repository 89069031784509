// imported dependencies/components
import React from "react";
import group from "./images/group-dance.jpg";
import Helmet from "react-helmet";
import { TestimonialSectionComponent } from "../../Testimonial";
import bellyDance from "./images/bd.jpg";
import { useHistory } from "react-router-dom";

// import testimonials
import { testimonials } from "../../../data";
import styled from "styled-components";
const { danceTestimonialData } = testimonials;

const BookNowBtn = styled.a`
  position: relative;
  outline: none;
  background: none;
  padding: 0.5rem 2rem;
  width: 100%;
  display: block;
  margin: 1em 6em;
  padding-left: 2em;
  border: none;
  border-radius: 12px;
  color: #324da1;
  font-size: 1.2rem;
  font-weight: 600;
  z-index: 1;
  text-align: center;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    font-size: 1.3rem;
    padding: 0.5rem 1rem;
  }
  &:hover {
    color: #f3f4f6;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #324da1;
    opacity: 0.9;
    border-radius: 12px;
    transition: transform 0.5s;
    transform-origin: bottom right;
    transform: scale(0);
  }

  &:hover::before {
    transition: transform 0.5s;
    transform-origin: top left;
    transform: scale(1);
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: transparent;
    border-radius: 12px;
    border: 2px solid #035b96;
    transition: transform 0.5s;
    transform-origin: top left;
    transform: scale(1);
  }

  &:hover::after {
    transition: transform 0.5s;
    transform-origin: bottom right;
    transform: scale(0);
  }
`;

const BellyDanceBtnContainer = styled.div`
  width: 100%;
  display: grid;
  justify-items: center;
`;

const BellyDanceBtn = styled.button`
  width: 60vw;
  /* transform: translateX(20vw); */
  height: 7vh;
  border-radius: 30px 30px 30px 30px;
  text-align: center;
  background: linear-gradient(30deg, slateblue, orange);
  font-weight: 700;
  font-size: 1.5rem;
  margin: 1.5rem;

  &:hover {
    color: white;
    background: #ff5722;
  }
  @media screen and (max-width: 600px) {
    width: 85vw;
  }
`;

export default function BellyDancePage() {
  const history = useHistory();

  return (
    <div className="h-auto flex flex-col">
      {/* begin group belly dancing class section */}
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Belly dance classes and training offered by KellyKinetix which focus on belly dance in solo or group settings. Offered online or in-person. KellyKinetix offers belly dancing and personal trainers in north york, Toronto, GTA and Ontario through online classes."
        />
        <title>Belly Dance</title>
      </Helmet>

      {/* begin dance page intro section */}
      <div className="h-auto flex flex-col mt-0 sm:my-12">
        <span className="text-xl sm:text-3xl font-bold text-center header-text my-12">
          KELLY ENSTON’S DANCE JOURNEY
        </span>
        <div className="flex flex-col sm:flex-row sm:justify-between  sm:items-center xl:items-start  mt-0 mb-12 s: gap-4">
          {/* begin image section -- 1/4 rather than 1/2 because of image size here */}
          <div className="sm:w-1/3 h-64  mt-0 sm:h-auto flex items-center mb-16 sm:mb-0 sm:pl-4 xl:pl-32 sm:w-80 xl:w-100 m-auto overflow-hidden">
            <img
              src={bellyDance}
              alt="belly dancing portrait"
              className="rounded h-60 w-fs"
              style={{
                width: "100%",
                objectFit: "cover",
                height: "400px",
                objectPosition: "50% 90%",
              }}
            />
          </div>
          {/* end image section */}
          {/* begin side content section - compensate by going 3/4 rather than 1/2 */}
          <div className="sm:w-3/4 h-auto flex flex-col">
            <p className="sm:pl-12 xl:mt-0 px-4 sm:pr-32 text-base sm:text-xl  content-text">
              Dancing has always been an important component in my life. Having
              a long history of dancing since the age of 5, including a wide
              variety of dance styles such as ballet, jazz, hip hop and modern,
              I have performed and choreographed at both the amateur and
              professional levels. I found later in life that by pursuing my
              passion for dance, that it not only helps me to maintain my
              emotional and mental health but also allows me to help inspire
              others to lead a happy, balanced and healthier lifestyle.
            </p>
          </div>
        </div>
        {/* end dance page intro section */}
        {/* begin health benefits of dance */}
        {/*<BellyDanceBtnContainer>*/}
        {/*  <BellyDanceBtn onClick={() => history.push(`/bellyDance`)}>*/}
        {/*    Interested in Belly Dance?*/}
        {/*  </BellyDanceBtn>*/}
        {/*</BellyDanceBtnContainer>*/}
      </div>

      <span className="text-xl sm:text-3xl font-bold text-center header-text  my-4 md:my-12">
        BELLY DANCE CLASSES (ONLINE AND IN-PERSON)
      </span>
      <img
        src={group}
        alt="belly dancing class"
        className="rounded block md:hidden w-4/5 m-auto"
      />
      <div className="flex justify-between items-start mb-12 flex-col md:flex-row">
        {/* begin side content section */}
        <div className="sm:w-full md:w-2/3 h-auto flex flex-col">
          <p className="sm:pr-8 xl:pr-12 px-8 sm:pl-8 xl:pl-32 text-base sm:text-xl my-4 content-text">
            Belly dancing is an expressive form of dance from the Middle East
            which emphasizes complex movements of the torso and hips. Belly
            dancing is also a fantastic way to lose weight and get fit, as it
            exercises the abdominal muscles, hips, calves, thighs and arms. As
            the movements are so unique, participants also derive the
            satisfaction of learning a new skill and typically find it more
            interesting than other forms of more traditional exercise.
          </p>
          <p className="sm:pr-8 xl:pr-12 px-8 sm:pl-8 xl:pl-32 text-base sm:text-xl my-4 content-text">
            Kelly teaches belly dance with a method she studied intensively with
            the late Hadia *(Joceylyn Chouinard). It is called Body Logic.
            Taking body mechanics and applying it to the art of Middle Eastern
            Dance. What does that mean for you in our belly dance classes?
            Because a professional massage therapist and International Middle
            Eastern Performer and Master Instructor reworked many belly dance
            moves, and got rid of others from the repertoire, your experience
            was meant to heal and massage the body instead of hurt it.
          </p>
          <p className="sm:pr-8 xl:pr-12 px-8 sm:pl-8 xl:pl-32 text-base sm:text-xl my-4 content-text">
            Group classes are a great way to meet other people with similar
            interests and make new friends in a fun, supportive and friendly
            environment. If the idea of being in a gym even with a trainer is
            scary for you, belly dance can be a fun way to introduce being more
            active into your life. Later you may decide to supplement your dance
            with personal training to get stronger and dance better. If you want
            to bring fitness and health, joy and empowerment into your life,
            then come out and learn what belly dance can do for you!
          </p>
        </div>
        {/* end side content section */}
        {/* begin image section */}
        <div className="w-4/5 md:w-1/3 h-auto m-auto flex flex-col sm:flex items-center sm:pr-8 xl:pr-32  mt-4">
          <img
            src={group}
            alt="belly dancing class"
            className="rounded hidden md:block"
          />
          <BookNowBtn
            href="https://calendly.com/info-kellykinetix/30mindiscoverycall"
            target="blank"
            rel="noopener noreferrer"
          >
            Book a Discovery Call
          </BookNowBtn>
          <BookNowBtn
            href="https://checkout.square.site/merchant/XKYFNNXQV8WP1/checkout/GFCP7IT4R3XEU4PPWDVCW7F6"
            target="blank"
            rel="noopener noreferrer"
          >
            Try a Drop in Belly Dance Class (pay here)
          </BookNowBtn>
          <BookNowBtn
            href="https://checkout.square.site/merchant/XKYFNNXQV8WP1/checkout/XCQH43CNGZRZTYL7KGELGYFH"
            target="blank"
            rel="noopener noreferrer"
          >
            Book me for a full 8 week session of Belly Dance Essentials
          </BookNowBtn>
        </div>
        {/* end image section */}
      </div>
      {/* end group belly dancing class section */}
      <TestimonialSectionComponent
        title="BELLY DANCE CLIENT TESTIMONIALS"
        testimonialData={danceTestimonialData}
        backgroundImage={bellyDance}
      />
    </div>
  );
}

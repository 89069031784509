import React from "react";

// react-helmet
import { Helmet } from "react-helmet";

// Testimonial component
import { TestimonialSectionComponent } from "../../Testimonial";

// testimonial data
import { testimonials } from "../../../data";

// images
import greenery from "./images/greenery.jpg";
import deadlift from "./images/deadlift.jpg";
import machine from "./images/machine.jpg";
import grinning from "./images/grinning.jpg";
import client from "./images/client.jpg";

const CustomizedProgramPage = () => {
  const { fitnessTestimonialData } = testimonials;
  // begin fitness page
  return (
    <div className="h-auto flex flex-col">
      {/* begin react-helmet component */}
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="About the fitness services offered by KellyKinetix which include mobility and strength training. KellyKinetix offers belly dancing and personal trainers in north york, Toronto, GTA and Ontario through online classes."
        />

        {/* meta tags for social media sharing */}
        <meta property="og:title" content="KellyKinetix Fitness" />
        <meta
          property="og:url"
          content="https://www.kellykinetix.com/fitness"
        />
        <meta
          name="keywords"
          content="gym instruction, training, working out, fitness classes, exercise classes near me, group fitness classes near me, workout classes, exercise classes, group workout classes near me, exercise groups near me, zumba exercise for beginners, exercise classes near me, group fitness classes near me, exercise classes, group workout classes near me, workout classes, zumba dance, circuit training, fitness classes near me, workout classes near me, zumba, cpr, personal trainer, personal trainer downtown toronto, fit women, goodlife workout, housefit personal training toronto, personal trainer, trainer, physical trainer, personal trainer at home, female personal trainer, personal training studio, fitness classes, personal trainers near me, fitness studio, fitness studio toronto, personal trainer, personal training studio, private trainer, personal fitness, studio north, health & fitness, personal training, fitness gym, health fitness, fitness centre, toronto fitness, gym in toronto, downtown toronto gym, in love yourself, love your self, love yourself, my self love, linktree instagram, facebook instagram, instagram and facebook, tiktok instagram, face book face book, mind body connection, mind body, mind and body, body mind, cardiovascular fitness, benefits of physical activity, benefits of physical fitness, 10 benefits of physical activity, workout benefits, cardio vascular fitness, paul chek, smart goals examples, smart goals acronym, client testimonials, mobility, mobility plus, mobility, resilience, resiliency, resilience examples, mental resilience, prestige awards, corporate livewire prestige awards, prestige trophies"
        />

        <meta
          property="og:description"
          content="Reserve a consultation to book your personal training session. Also offer group fitness classes and customized programming to get everyone to reach their goals."
        />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:image" content={"%PUBLIC_URL%/" + deadlift} />
        <meta property="og:type" content="website" />

        <title>Fitness</title>
      </Helmet>
      {/* end react-helmet component */}
      {/* begin fitness journey content section */}
      <span className="text-3xl font-bold text-center my-12 header-text">
        CUSTOMIZED PROGRAMMING{" "}
      </span>
      {/* begin one on one personal training content section */}
      <div className="flex justify-between items-center mb-12">
        {/* begin side content section */}
        <div className="w-full sm:w-1/2 h-auto flex flex-col">
          <p className="sm:pr-12 px-8 sm:pl-32 text-base sm:text-xl my-4 content-text">
            There is also the option to customize a program to you personally.
            This would be specific to your specific development goals and
            abilities. Please note that this will usually be more expensive due
            to the added time and energy to create a program that delivers
            results. As time goes on and KellyKinetix expands, there may be the
            option to customize programming for semi-private and small groups.
          </p>
        </div>
        {/* end side content section */}
        {/* begin image section */}
        <div className="w-1/2 h-auto hidden sm:flex items-center pr-32">
          <img
            src={client}
            alt="Kelly working with one of her clients"
            className="rounded"
          />
        </div>
        {/* end image section */}
      </div>
      {/* end one on one personal training content section */}
      {/* begin smart goals section */}
      <div className="h-auto flex flex-col my-4 sm:my-12">
        <span className="text-xl sm:text-3xl font-bold text-center header-text">
          THE IDEA OF SMART GOALS
        </span>
        <p className="px-8 sm:px-32 text-base sm:text-xl mt-12 mb-4 content-text">
          Within KellyKinetix, one of the fundamental concepts that guides me in
          assisting my clients' progression in their fitness journey is the idea
          of helping them develop{" "}
          <span className="text-base sm:text-xl">
            <a
              href="https://corporatefinanceinstitute.com/resources/knowledge/other/smart-goal/"
              target="_blank"
              rel="noopener noreferrer"
              className="content-link"
            >
              SMART
            </a>
          </span>{" "}
          goals, which are used to help guide goal setting. SMART is an acronym
          that stands for:
        </p>
        <ul className="content-text px-8 sm:px-32">
          <li className="text-base sm:text-xl text-start py-2">
            <span className="font-semibold">Specific.</span> A SMART goal should
            be detailed as specific as possible, without any confusion as to
            what the goal is. An example of a specific goal would be "Lose five
            pounds by April 30, 2021" as opposed to "Lose weight by the end of
            the spring.".
          </li>
          <li className="text-base sm:text-xl text-start py-2">
            <span className="font-semibold">Measurable.</span> A SMART goal
            needs to be measurable. It is important to see a change from the
            previous state before the goal is achieved and after the goal is
            achieved.
          </li>
          <li className="text-base sm:text-xl text-start py-2">
            <span className="font-semibold">Achievable.</span> A SMART goal
            should always be something that isn't impossible to achieve.
          </li>
          <li className="text-base sm:text-xl text-start py-2">
            <span className="font-semibold">Realistic.</span> A SMART goal
            should always be realistic, within reach and relevant to you
            personally.
          </li>
          <li className="text-base sm:text-xl text-start py-2">
            <span className="font-semibold">Timely.</span> A SMART goal should
            always have a starting and ending date, to define the period of the
            goal. This is to make sure that the goal is attempted during the
            timeline, rather than to defer it.
          </li>
        </ul>
      </div>
      <p className="px-8 sm:px-32 text-base sm:text-xl pb-8 mb-12 content-text">
        Check out our{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="/consultation"
          className="content-link"
        >
          consultation page
        </a>
        .
      </p>
      {/* end smart goals section */}
    </div>
  );
  // end fitness page
};

export default CustomizedProgramPage;

import React, { useState, useEffect } from "react";
import styled from "styled-components";

//  hook that returns a reference to the dispatch function from the Redux store
import { useDispatch } from "react-redux";

// used to extract data from the Redux store state
import { useSelector } from "react-redux";

// action that passed to dispatch method
import { addNewProduct } from "../redux-setup/actions";

// components for color selection and quantity input
import QtyPicker from "./QtyPicker";
import ColourPicker from "./ColourPicker";

// icon for "Already in cart" section
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const DescWrapper = styled.div`
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;

  @media screen and (min-width: 768px) {
    text-align: left;
    flex: 4;
    padding-left: 1rem;
    align-items: start;
  }
`;

const CartButton = styled.button`
  width: 75%;
  min-width: 270px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background-color: ${(props) => props.bgColor};
  margin: 1.5em 0;
  &:hover {
    background-color: ${(props) => props.hoverBgColor} !important;
    color: white !important;
  }
`;

// const ClientPrice = styled.p`
//   color: red;
// `;

const AlreadyInCart = styled.div`
  color: #4bb543;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
`;

const AlreadyInCartText = styled.div`
  color: #f9f9f9;
`;

const Description = ({ product }) => {
  const cartProducts = useSelector((state) => state.cart.products);
  const [inCart, setInCart] = useState(false);
  const [qty, setQty] = useState(1);
  const [color, setColor] = useState(
    product.colorOptions ? product.colorOptions[0] : null
  );
  const [size, setSize] = useState(
    product.sizeOptions ? product.sizeOptions[0] : null
  );
  const [frontDesign, setFrontDesign] = useState(
    product.frontDesignOptions ? product.frontDesignOptions[0] : null
  );
  const [backDesign, setBackDesign] = useState(
    product.backDesignOptions ? product.backDesignOptions[0] : null
  );
  const [sleeve, setSleeve] = useState(
    product.sleeveOptions ? product.sleeveOptions[0] : null
  );

  // used to update state of React app (redux)
  const dispatch = useDispatch();

  const addItemToCart = () => {
    if (!inCart) {
      dispatch(
        addNewProduct({
          id: product.id,
          quantity: qty,
          images: product.images,
          title: product.title,
          publicPrice: product.publicPrice,
          clientPrice: product.clientPrice,
          color: color,
          size: size,
          frontDesign: frontDesign,
          backDesign: backDesign,
          sleeve: sleeve,
        })
      );
      setInCart(true);
    }
  };

  const findProduct = () => {
    const productWithGivenParams = cartProducts.filter((element) => {
      return (
        element.productID === product.id &&
        element.color.text === color.text &&
        element.size === size &&
        element.frontDesign === frontDesign &&
        element.backDesign === backDesign &&
        element.sleeve === sleeve
      );
    });
    return productWithGivenParams[0];
  };

  useEffect(() => {
    const product = findProduct();
    setInCart(product !== undefined ? true : false);
    // eslint-disable-next-line
  }, [color, size, frontDesign, backDesign, cartProducts]);

  return (
    <DescWrapper>
      <h3 style={{ margin: "0px" }}>{product.title}</h3>
      <p style={{ fontSize: "1.5em" }}>
        <b> $ {product.publicPrice.toFixed(2)}</b>
      </p>
      {/*<ClientPrice>*/}
      {/*  Client Price: $ {product.clientPrice.toFixed(2)}*/}
      {/*</ClientPrice>*/}
      {!inCart && <QtyPicker qty={qty} setQty={setQty} />}
      {product.colorOptions && (
        <ColourPicker
          title="Colour"
          product={product}
          color={color}
          setColor={setColor}
          className=""
        />
      )}
      {product.sizeOptions && (
        <ColourPicker
          title="Size"
          product={product}
          color={size}
          setColor={setSize}
          className=""
        />
      )}
      {product.frontDesignOptions && (
        <ColourPicker
          title="Front Design"
          product={product}
          color={frontDesign}
          setColor={setFrontDesign}
          className=""
        />
      )}
      {product.backDesignOptions && (
        <ColourPicker
          title="Back Design"
          product={product}
          color={backDesign}
          setColor={setBackDesign}
          className=""
        />
      )}
      {product.sleeveOptions && (
        <ColourPicker
          title="Sleeve Design"
          product={product}
          color={sleeve}
          setColor={setSleeve}
          className=""
        />
      )}
      <CartButton
        className="btn"
        onClick={addItemToCart}
        bgColor={inCart ? "black" : "none"}
        hoverBgColor={inCart ? "black" : "#ced4da"}
      >
        {inCart ? (
          <AlreadyInCart>
            <FontAwesomeIcon icon={faCheck} />
            <AlreadyInCartText> Already in cart </AlreadyInCartText>
          </AlreadyInCart>
        ) : (
          "Add to Cart"
        )}
      </CartButton>
    </DescWrapper>
  );
};

export default Description;

import React from "react";
import Award from "./images/Award.jpg";
import Winner from "./images/Winner.png";
import "./style.css";
// React-helmet for SEO
import { Helmet } from "react-helmet";
import { jsonLD } from "../HomePage";
import { metaTagConstants } from "../HomePage/index";

export default function AwardPage() {
  const awardsTags = {
    ...metaTagConstants,
    url: "https://www.kellykinetix.com/awards",
    description:
      "KellyKinetix offers a wide amount of fitness activites. To get fit, classes include belly dance essentials, dance choreography, body resilience, mobility, and technique skill and drill classes.",
    title: "KellyKinetix Awards",
  };
  return (
    <section>
      <div className="hero">
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Assorted dance, mobility and fitness classes offered through KellyKinetix, fitness and dance instruction company. KellyKinetix offers belly dancing and personal trainers in north york, Toronto, GTA and Ontario through online classes."
          />
          <meta
            name="keywords"
            content="prestige awards, corporate livewire prestige awards, prestige trophies, awards, start now, discovery call, fitness classes, exercise classes near me, group fitness classes near me, workout classes, exercise classes, group workout classes near me, exercise groups near me, fitness classes, exercise classes near me, group fitness classes near me, workout classes, exercise classes, group workout classes near me, exercise groups near me, zumba exercise for beginners, exercise classes near me, group fitness classes near me, exercise classes, group workout classes near me, workout classes, zumba dance, circuit training, fitness classes near me, dance fitness"
          />
          <title>Awards</title>

          {/* meta tags for social media sharing */}
          <meta property="og:title" content={awardsTags.title} />
          <meta property="og:url" content={awardsTags.url} />
          <meta property="og:description" content={awardsTags.description} />
          <meta property="og:locale" content={awardsTags.locale} />
          <meta
            property="og:image"
            content={"%PUBLIC_URL%/" + awardsTags.image}
          />

          {/* add json-ld for seo through script tag */}
          <script type="application/json+ld">{JSON.stringify(jsonLD)}</script>
        </Helmet>
        <div className="main1">
          <div className="content1">
            <div className="wrapper">
              <div className="card">
                <div className="back">
                  <div className="con">
                    <p className="descript">
                      The judges were particularly impressed by the community
                      feel established at KellyKinetix, which has become a great
                      way to get fit and meet likeminded people.{" "}
                    </p>
                    <p className="descript">
                      The classes are designed in such a way as to leave
                      exertion levels down to the individuals, who make them as
                      strenuous or as painless as they like, according to their
                      own personal goals.{" "}
                    </p>
                    <p className="descript">
                      Multiple packages are available for personal training and
                      group sessions, as well as fully bespoke packages that are
                      designed with specific goals in mind.{" "}
                    </p>
                    <p className="descript">
                      Kelly empowers women to release their potential while
                      looking forward to every class.
                    </p>
                    <br />
                    <br />
                    <span>
                      <a
                        href="https://www.kellykinetix.com"
                        title="classNameES Button"
                      >
                        ABOUT US
                      </a>
                    </span>
                  </div>
                </div>

                <div className="front">
                  <div className="con">
                    <h3 className="heading">
                      Dance Fitness Instructor of the Year
                    </h3>
                    <p className="descript">
                      KellyKinetix is an independent business run by certified
                      personal trainer, Kelly Enston, out of Toronto.{" "}
                    </p>
                    <p className="descript">
                      Kelly trains her clients through a combination of her
                      one-to-one and group sessions, as well as offering monthly
                      events and workshops for those interested in improving
                      their fitness and wellbeing.
                    </p>
                    <p className="descript">
                      While KellyKinetix has a steadily growing base of clients
                      in and around the Greater Toronto Area, the personal
                      trainer is hoping to expand her reach through the
                      provision of online classes, which proved to be a
                      resounding success during the pandemic.{" "}
                    </p>
                    <p className="descript">
                      Everyone is welcome to the dance and fitness classes on
                      offer, but most of Kelly's regular clients are women aged
                      between 30 and 65 who want to implement healthy lifestyle
                      habits.{" "}
                    </p>
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <figcaption>
            <img src={Award} alt="" />
          </figcaption>
        </div>

        <div className="main">
          <img src={Winner} alt="" />
          <div className="content">
            <h2 className="heading">CANADA </h2>
            <h2> PRESTIGE AWARDS</h2>
            <h2> 2022 WINNER</h2>
            <p className="descript">
              The community feel at KellyKinetix has made it a great way to get
              fit while meeting likeminded people.
            </p>
            <span>
              <a
                href="https://www.kellykinetix.com/classes"
                title="classNameES Button"
              >
                CLASSES
              </a>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}

import React from "react";

import { Helmet } from "react-helmet";

// import styled components
import styled from "styled-components";

// Testimonial component
import { TestimonialSectionComponent } from "../../Testimonial/index";
import DanceItem from "./DanceItem";

// images
import bellyDance from "./images/bd.jpg";
import bollywoodDanceImg from "./images/bwd.png";

// testimonial data
import { testimonials } from "../../../data";

// json-LD import
import { jsonLD } from "../HomePage/index";
import { metaTagConstants } from "../HomePage/index";

// import social media thumbnail image for sharing
import spreadArms from "../DancePage/images/arms-up.jpg";
import { useHistory } from "react-router-dom";
import EventItem from "../WorkshopPage/EventItem";

const { danceTestimonialData } = testimonials;

const danceTypes = [
  {
    danceTitle: "Belly Dance",
    instructor: "Kelly Enston",
    cardImg: bellyDance,
    imgPosition: "50% 20%",
    pageLink: "/bellyDance",
  },
  // {
  //   danceTitle: "Bollywood Dance",
  //   instructor: "Shashwat Mishra",
  //   cardImg: bollywoodDanceImg,
  //   imgPosition: "50% 50%",
  //   pageLink: "/bollywoodDance",
  // },
];

const DancePage = () => {
  const history = useHistory();

  // dance meta tags
  const danceTags = {
    ...metaTagConstants,
    title: "Dance",
    url: "https://www.kellykinetix.com/dance",
    description:
      "Improve your health through dance classes. Work on weight management, balance and flexibility.",
    image: spreadArms,
  };

  // begin dance page
  return (
    <div className="h-auto flex flex-col">
      {/* begin react-helmet component */}
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Dance classes and training offered by KellyKinetix which focus on belly dance in solo or group settings. KellyKinetix offers belly dancing and personal trainers in north york, Toronto, GTA and Ontario through online classes."
        />
        <meta
          name="keywords"
          content="belly dance toronto, belly dancing classes toronto, mind body connection, mind body, mind and body, body mind, meditation, meditation music, sleep music, mindfulness meditation, guided meditation, mindfulness meditation, mindfulness, dance lessons, dance classes near me, dance lessons near me, dance schools near me, belly dance classes near me, benefits of dancing, benefits of dance, cardiovascular fitness, benefits of physical activity, benefits of physical fitness, 10 benefits of physical activity, workout benefits, cardio vascular fitness, resilience, resiliency, resilience examples, mental resilience, prestige awards, corporate livewire prestige awards, prestige trophies, fitness classes, exercise classes near me, group fitness classes near me, workout classes, exercise classes, group workout classes near me, exercise groups near me, zumba exercise for beginners, exercise classes near me, group fitness classes near me, exercise classes, group workout classes near me, workout classes, zumba dance"
        />

        <title>Dance</title>

        {/* adding social media meta tags */}
        <meta property="og:title" content={danceTags.title} />
        <meta property="og:url" content={danceTags.url}></meta>
        <meta property="og:description" content={danceTags.description} />
        <meta property="og:locale" content={danceTags.locale} />
        <meta property="og:image" content={"%PUBLIC_URL%/" + danceTags.image} />

        {/* adding seo constants to each page */}
        <script type="application/json+ld">{JSON.stringify(jsonLD)}</script>
      </Helmet>
      {/* end react-helmet component */}

      <div className="h-auto flex flex-col sm:my-12">
        <span className="text-xl sm:text-3xl font-bold text-center header-text my-4">
          HEALTH BENEFITS OF DANCE
        </span>
        <p className="px-8 sm:px-32 text-base sm:text-xl py-12 content-text">
          Dance is a fun, expressive activity that has health benefits for
          everyone regardless of age. Here are some reasons why you should try
          dancing:
        </p>
        <ul className="content-text px-16 sm:px-40 list-disc">
          <li className="text-base sm:text-xl text-start py-2">
            Improved heart and lung condition
          </li>
          <li className="text-base sm:text-xl text-start py-2">
            Increased muscular strength and endurance
          </li>
          <li className="text-base sm:text-xl text-start py-2">
            Weight management
          </li>
          <li className="text-base sm:text-xl text-start py-2">
            Improved coordination and agility
          </li>
          <li className="text-base sm:text-xl text-start py-2">
            Improved balance and flexibility reducing the risk of injury
          </li>
          <li className="text-base sm:text-xl text-start py-2">
            It helps maintain optimum brain function and memory.{" "}
            <span className="text-base sm:text-xl">
              <a
                href="https://www.nejm.org/doi/full/10.1056/nejmoa022252"
                target="_blank"
                rel="noreferrer noopener"
                className="content-link"
              >
                Research
              </a>
            </span>
            &nbsp;has shown that dancing also reduces the risk of dementia in
            the elderly.
          </li>
        </ul>
      </div>
      {/* end health benefits of dance */}

      <div className="h-auto flex flex-col sm:my-12">
        <span className="text-xl sm:text-3xl font-bold text-center header-text my-4">
          Current Dancing Courses
        </span>
        <DanceContainer className="w-full">
          {danceTypes.map((dance) => {
            return (
              <DanceItem
                danceTitle={dance.danceTitle}
                instructor={dance.instructor}
                cardImg={dance.cardImg}
                pageLink={dance.pageLink}
                imgPosition={dance.imgPosition}
              ></DanceItem>
            );
          })}
        </DanceContainer>
        <p className="px-8 sm:px-32 text-base sm:text-xl py-2 content-text">
          If you haven’t overcome your fear of taking dance classes like these,
          but really want to dance and it's on your bucket list, check out our
          description for{" "}
          <a
            href="/danceExploration"
            target="_blank"
            rel="noopener noreferrer"
            className="content-link"
          >
            INTRODUCTION TO DANCE EXPLORATION
          </a>
        </p>
      </div>

      {/* begin dance testimonial section */}
      {/*<TestimonialSectionComponent*/}
      {/*  title="BELLY DANCE CLIENT TESTIMONIALS"*/}
      {/*  testimonialData={danceTestimonialData}*/}
      {/*  backgroundImage={bellyDance}*/}
      {/*/>*/}
      {/* end dance testimonial section */}
    </div>
  );
  // end dance page
};

export default DancePage;

const BellyDanceBtnContainer = styled.div`
  width: 100%;
  display: grid;
  justify-items: center;
`;

const BellyDanceBtn = styled.button`
  width: 60vw;
  /* transform: translateX(20vw); */
  height: 7vh;
  border-radius: 30px 30px 30px 30px;
  text-align: center;
  background: linear-gradient(30deg, slateblue, orange);
  font-weight: 700;
  font-size: 1.5rem;
  margin: 1.5rem;

  &:hover {
    color: white;
    background: #ff5722;
  }
  @media screen and (max-width: 600px) {
    width: 85vw;
  }
`;

const DanceContainer = styled.div`
  display: flex;
  flex-wrap: wrap !important;
  gap: 2rem;
  /* margin-left: 1rem;
  margin-right: 1rem; */
  justify-content: center;
  font-size: 1.25rem;
  max-width: 100%;
  margin: 1rem auto;

  @media (min-width: 768px) {
    column-gap: 5rem;
  }
`;

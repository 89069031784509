import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { merch2Data } from "../../../../data/merch2";
import NoImage from "../images/noImage.jpg";
import "./detailpage.css";

import ImageSlider from "./ImageSlider";
import Description from "./Description";
import AdditionalData from "./AdditionalData";

import fetchImages from "./FetchImages";

const Container = styled.div`
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  column-gap: 2%;
  align-items: start;
  font-family: "Poppins", sans-serif;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    row-gap: 2%;
    padding: 0rem 10% 2em 10%;
  }
`;

const ProdMainImg = styled.img`
  width: 100%;
  height: 610px;
  object-fit: cover;
  object-position: 50% 50%;
  overflow: hidden;
  border-radius: 0.25rem;
  cursor: pointer;
`;

const BigImgWrapper = styled.div`
  display: none;
  @media screen and (min-width: 768px) {
    display: block;
    flex: 6;
    align-items: center;
  }
`;

const LightBox = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: none;
`;

const ProductDetails = () => {
  const { productId } = useParams();
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState({
    id: productId,
    title: "Product Name",
    description: "Description",
    images: [],
    clientPrice: 0,
    publicPrice: 0,
  });
  const handdleLightbox = (e) => {
    const lightbox = document.getElementById("lightbox");
    lightbox.classList.add("lightbox-active");
    const img = document.createElement("img");
    img.src = e.target.src;
    while (lightbox.firstChild) {
      lightbox.removeChild(lightbox.firstChild);
    }
    lightbox.appendChild(img);
  };
  const hideLightbox = (e) => {
    const lightbox = document.getElementById("lightbox");
    if (e.target !== e.currentTarget) return;
    lightbox.classList.remove("lightbox-active");
  };

  useEffect(() => {
    const data = merch2Data.find(
      (product) => parseInt(product.id) === parseInt(productId)
    );
    setProduct(data);
    setLoading(false);
    fetchImages(merch2Data);
    //imported function to fetch images from corresponding folders by altering the JSON object 'merch2Data'

    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading ? (
        <p>Loading... </p>
      ) : (
        <>
          <p
            style={{
              margin: ".5em calc(10% + 10px)",
              color: "#999",
              fontSize: ".9em",
            }}
          >
            Home / Women Clothing / Top
          </p>
          <Container>
            <ImageSlider product={product} />
            <BigImgWrapper>
              {product.images === undefined || product.images.length < 1 ? (
                <ProdMainImg src={NoImage} />
              ) : (
                <ProdMainImg
                  src={product.images[0]}
                  id="featured"
                  onClick={handdleLightbox}
                />
              )}
            </BigImgWrapper>
            <Description product={product} />
            <LightBox id="lightbox" onClick={hideLightbox} />
          </Container>
          <AdditionalData product={product} />
        </>
      )}
    </>
  );
};

export default ProductDetails;

import React from "react";
import { useState } from "react";

import styled from "styled-components";
import logo from "./images/kk-logo.png";

// used to navigate through the website
import { Link } from "react-router-dom";

// hash function used to hash object and can be used as key
import hash from "object-hash";

// icon for shopping cart section
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";

//  hook that returns a reference to the dispatch function from the Redux store
import { useDispatch } from "react-redux";

import { openMenu } from "../pages/E-Commerce/redux-setup/menu-actions";

const ShoppingCartSection = styled.div`
  font-size: 1rem;
  width: 40px;
  height: 40px;
  padding-right: 2px;
  background: #324da1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f9f9f9;
  cursor: pointer;
  transition: background ease-out 0.2s;
  &:hover {
    background: #ff4500;
  }
`;

// navigation links
export const links = [
  {
    path: "/aboutUs",
    title: "About Us",
    sublinks: [
      { path: "/vision", title: "Company Vision" },
      { path: "/mission", title: "Company Mission" },
      { path: "/values", title: "Company Core Values" },
    ],
  },
  { path: "/kellyKinetixBlog", title: "Blog" },
  {
    path: "/programsAndServices",
    title: "Programs and Services",
    sublinks: [
      {
        title: "Dance",
        sublinks: [
          { path: "/dance", title: "Dance" },
          { path: "/bellyDance", title: "Belly Dance" },
          // { path: "/bollywoodDance", title: "Bollywood Dance" },
        ],
      },
      {
        title: "Fitness",
        sublinks: [
          { path: "/fitness", title: "Fitness" },
          { path: "/healthBenefits", title: "Health Benefits of Fitness" },
          {
            path: "/workingOutAndWorkingIn",
            title: "Working out and Working in",
          },
          { path: "/personalTraining", title: "Personal Training" },
          { path: "/groupFitness", title: "Group Fitness" },
          { path: "/customizedProgram", title: "Customized Program" },
        ],
      },
      { path: "/workshops", title: "Workshops & Events" },
      { path: "/classes", title: "Classes" },
      {
        title: "Internship Program",
        sublinks: [
          { path: "/internship", title: "Internship Program" },
          { path: "/internalMission", title: "Internal Mission" },
        ],
      },
    ],
  },
  { path: "/consultation", title: "Consultation" },
  { path: "/awards", title: "Awards" },
  { path: "/FAQs", title: "FAQ" },
  { path: "/shop", title: "Shop" },
];

const DesktopNav = () => {
  // used to update state of React app (redux)
  const dispatch = useDispatch();
  const [danceMenuOpen, setDanceSubMenuOpen] = useState(false);
  const [fitnessMenuOpen, setFitnessMenuOpen] = useState(false);
  const [intershipMenuOpen, setIntershipMenuOpen] = useState(false);

  const lockBodyScroll = () => {
    dispatch(openMenu());
    document.body.setAttribute("style", "overflow-y: hidden; height: 100%;");
  };

  return (
    <>
      <div
        className={`
          h-30 sm:h-48 xl:h-40 
          header-brand 
          flex 
          items-center
          sm:pl-6
        `}
      >
        <Link to="/">
          <img
            className={`h-32 sm:h-40 xl:h-32`}
            src={logo}
            alt="main page logo"
          />
        </Link>
      </div>
      <div className="hidden sm:flex sm:h-48 xl:h-40 header-nav">
        <ul className="list-none flex header-text items-center h-40 header-column-gap text-center">
          {links.map((link) => (
            <li key={hash(link)} className="relative  nav-item">
              <Link
                to={link.path}
                className="uppercase text-sm font-bold text-indigo-900 hover:text-gray-600 transition"
              >
                {link.title}
                {link.sublinks && <i className="arrow-down"></i>}
              </Link>
              {link.sublinks && (
                <ul className="dropdown-menu mt-0 absolute z-10">
                  {link.sublinks.map((sublink) => (
                    <li key={hash(sublink)} className="dropdown-item">
                      <Link
                        to={sublink.path}
                        className="uppercase text-sm font-bold text-indigo-900 hover:text-gray-600 transition dropdown-item"
                        onClick={(e) => {
                          if (sublink.sublinks) {
                            e.preventDefault();
                            if (sublink.title === "Dance")
                              setDanceSubMenuOpen(!danceMenuOpen);
                            if (sublink.title === "Fitness")
                              setFitnessMenuOpen(!fitnessMenuOpen);
                            if (sublink.title === "Internship Program")
                              setIntershipMenuOpen(!intershipMenuOpen);
                          }
                        }}
                      >
                        {sublink.title}
                        {sublink.sublinks && <i className="arrow-down"></i>}
                      </Link>

                      {sublink.title === "Dance" &&
                        danceMenuOpen &&
                        sublink.sublinks &&
                        sublink.sublinks.map((subsublink) => (
                          <li key={hash(subsublink)}>
                            <Link
                              to={subsublink.path}
                              className="uppercase text-sm font-bold  text-gray-600 hover:text-gray-400 transition dropdown-item"
                            >
                              {subsublink.title}
                            </Link>
                          </li>
                        ))}
                      {sublink.title === "Fitness" &&
                        fitnessMenuOpen &&
                        sublink.sublinks.map((subsublink) => (
                          <li key={hash(subsublink)}>
                            <Link
                              to={subsublink.path}
                              className="uppercase text-sm font-bold text-gray-600 hover:text-gray-400 transition dropdown-item"
                            >
                              {subsublink.title}
                            </Link>
                          </li>
                        ))}
                      {sublink.title === "Internship Program" &&
                        intershipMenuOpen &&
                        sublink.sublinks.map((subsublink) => (
                          <li key={hash(subsublink)}>
                            <Link
                              to={subsublink.path}
                              className="uppercase text-sm font-bold text-gray-600 hover:text-gray-400 transition dropdown-item"
                            >
                              {subsublink.title}
                            </Link>
                          </li>
                        ))}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
          <ShoppingCartSection onClick={lockBodyScroll}>
            <FontAwesomeIcon icon={faShoppingCart} />
          </ShoppingCartSection>
        </ul>
      </div>
    </>
  );
};

export default DesktopNav;

import React from "react";
import styled from "styled-components";

const QtyContainer = styled.div`
  display: flex;
  align-items: center;
`;

const NumButton = styled.button`
  color: white;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background-color: #ced4da;
  border-color: #ced4da;
  font-size: 0.6rem;
  padding: 2px 0;
`;

const QtyCounter = styled.input`
  margin: 0 0.5rem;
  width: 1.8rem;
  text-align: center;
`;

const QtyPicker = ({ qty, setQty }) => {
  const changeQty = (operation) => {
    switch (operation) {
      case "-":
        if (qty > 1) setQty(qty - 1);
        break;
      case "+":
        setQty(qty + 1);
        break;
      default:
        return null;
    }
  };
  return (
    <QtyContainer>
      <NumButton onClick={() => changeQty("-")}>-</NumButton>
      <QtyCounter id="counter" type="text" value={qty} onChange={changeQty} />
      <NumButton onClick={() => changeQty("+")}>+</NumButton>
    </QtyContainer>
  );
};

export default QtyPicker;
